import Vue from 'vue'
import App from './App.vue'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import vuetify from './plugins/vuetify'
import Vuetify from 'vuetify/lib'
import store from './store'
import VueLogger from 'vuejs-logger';
import VueRouter from "vue-router";
import "@/helpers/Filters";
import MainPage from "./components/MainPage";
import MotoMainPage from "./components/MotoMainPage";
import EngineSelectionPage from "./components/EngineSelectionPage";
import InfoPage from "./components/InfoPage";
import EngineSelectionPageElectric from "./components/EngineSelectionPageElectric";
import MotoOfferDetails from "./components/MotoOfferDetails";
import EngineSelectionPageCost from "./components/EngineSelectionPageCost";
import VueCompositionApi from '@vue/composition-api';

Vue.use(VueRouter)

const routes = [
    { path: '/info', name: 'InfoPage', component: InfoPage },
    { path: '/:brand/:languagemarket/', redirect: '/:brand/:languagemarket/main' },
    { path: '/:brand/:languagemarket/:province/main', name: 'MainPage', props: true, component: MainPage },
    { path: '/bmwBike/:languagemarket/main', name: 'MotoMainPage', props: true, component: MotoMainPage },
    { path: '/:brand/:languagemarket/main', name: 'Main', props: true, component: MainPage },
    { path: '/engine-selection-esp', component: EngineSelectionPage },
    { path: '/engine-selection-espe', component: EngineSelectionPageElectric },
    { path: '/bmwBike/:languagemarket/offer-details', name: 'MotoOfferDetails', props: true, component: MotoOfferDetails },
    { path: '/:brand/:languagemarket/:province/engine-selection', name: 'EngineSelectionPageCost', props: true, component: EngineSelectionPageCost }
]

const router = new VueRouter({
    mode:'history',
    routes
})

const isProduction = process.env.NODE_ENV === 'production';
Vue.config.productionTip = false

const options = {
    isEnabled: true,
    logLevel : isProduction ? 'error' : 'debug',
    stringifyArguments : false,
    showLogLevel : true,
    showMethodName : true,
    separator: '|',
    showConsoleColors: true
};
Vue.use(VueLogger, options);
Vue.use(VueCompositionApi);

Vue.config.errorHandler = function(err, vm, info) {
    Vue.$log.error(err)
}

Vue.config.warnHandler = function(msg, vm, trace) {
    Vue.$log.warn(msg)
    Vue.$log.warn(trace)
}

Vue.use(Vuetify)

export default new Vuetify({
    icons: {
        iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    },
})

new Vue({
    el: '#app',
    vuetify,
    Vuetify,
    store,
    router,
    render: h => h(App)
});
