<template>
    <div class="gridrow" v-if="labels.mainPage.vehiclesList">
        <v-layout row class="vehicle-tile" :class="$mq">
            <v-flex grow v-for="(model, index) in modelStats" :key="model.modelsFoundPerSeries" xl2 lg3 md4 sm4 xs12 class="vehicle-element" :class="$mq">
                <router-link :to="{ name: 'MotoOfferDetails', params: {series: series, modelRange: model.modelRange, vehicleId: index, rangeDescription: model.rangeDescription, modelImageMobile: modelImageMobile(model), modelImageDesktop: modelImageDesktop(model), trm: $store.getters.filterSettings.trm, dep: $store.getters.filterSettings.dep, mil: $store.getters.filterSettings.mil, capacity: model.capacity, engineKWMin: model.powerEngine.engineKWMin } }">
                <div class="tile">
                    <div class="special-edition" v-if="specialEdition(model.rangeDescription)">
                        {{ labels.mainPage.vehiclesList.specialEditionText }}
                    </div>
                    <div class="headerimg">
                        <img v-bind:src="modelImageMobile(model)" alt="model image" @error="imageUrl='./imagenotfound_white.png'"/>
                    </div>
                    <div class="modeldetails">
                        <h1>{{ model.rangeDescription }}</h1>
                        <h3>Hubraum:</h3>
                        <p>{{ model.capacity | formatValue('integer', parameters.language, parameters.market) }} ccm</p>
                        <h3>Nennleistung:</h3>
                        <p>{{ model.powerEngine.engineKWMin}} kW ({{ model.powerEngine.engineKWMin | calculatePS}} PS)</p>
                    </div>
                    <div class="jumpto">
                        <h2>{{ labels.mainPage.vehiclesList.detailsAndConfigurator }}</h2>
                        <div class="layoutbox"/>
                    </div>
                </div>
                </router-link>
            </v-flex>
        </v-layout>

    </div>

</template>

<script>

    export default {
        name: 'MotoGridItem',
        components: {
        },
        props: {
            modelStats: {},
            series: '',
        },
        data() {
            return {}
        },
        computed: {
            labels() {
                return this.$store.state.labels
            },
            parameters() {
                return this.$store.state.parameters
            },
        },
        created() {
        },
        methods: {
            modelImageDesktop(model) {
                if (model.vehicleImages) {
                    if (model.vehicleImages.desktop) {
                        return model.vehicleImages.desktop
                    }
                    else {
                        return  "/imagenotfound.jpg"
                    }
                }
                else {
                    return "/imagenotfound.jpg"
                }
            },
            modelImageMobile(model) {
                if (model.vehicleImages) {
                    if (model.vehicleImages.mobile) {
                        return model.vehicleImages.mobile
                    }
                    else {
                        return  "/imagenotfound.jpg"
                    }
                }
                else {
                    return "/imagenotfound.jpg"
                }
            },
            specialEdition(rangeDescription) {
                if (rangeDescription.includes(this.labels.mainPage.vehiclesList.specialEditionCondition)) {
                    return true
                }
                else return false
            },
        },
        filters: {
            calculatePS: function (value) {
                return parseInt(value * 1.35962)
            },
        }
    }
</script>

<style scoped>
    .row {
        margin-left: 0px;
        margin-right: 0px;
    }

    .flex.vehicle-element.xl {
        min-width: 225px;
        max-width: 18.9%;
        flex-basis: 16%;
    }

    .flex.vehicle-element.lg {
        min-width: 225px;
        max-width: 23.5%;
        flex-basis: 22%;
    }

    .flex.vehicle-element.md {
        min-width: 215px;
        max-width: 31.36%;
        flex-basis: 30%;
    }

    .vehicle-element.sm {
        min-width: 234px;
        max-width: 47%;
        flex-basis: 40%;
    }

    .vehicle-element.xs {
        min-width: 234px;
        max-width: 100%;
        flex-basis: 100%;
    }

    .vehicle-tile > * {
        margin-right: 16px;
    }

    .tile {
        display: block;
        background-color: #ffffff;
        margin-left: 0px !important;
        padding-bottom: 30px;
    }

    #mobile .tile {
        margin: 0px auto 20px !important;
    }

    .headerimg {
        margin-top: 20px;
    }

    img {
        object-fit: contain;
        width: 100%;
        margin: 0 auto;
    }

    .tile:hover img {
        -webkit-transform: scale(1.05) translateZ(0);
        -moz-transform: scale(1.05) translateZ(0);
        -ms-transform: scale(1.05) translateZ(0);
        -o-transform: scale(1.05) translateZ(0);
        transform: scale(1.05) translateZ(0);

        -webkit-transition: transform .3s ease-out;
        -moz-transition: transform .3s ease-out;
        -ms-transition: transform .3s ease-out;
    }

    .modeldetails {
        padding: 0px 20px;
        min-height: 167px;
    }

    a {
        text-decoration: none;
    }

    h1 {
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        color: #16171a;
        margin-top: 20px;
    }

    h2 {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #16171a;
    }

    h3 {
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.36;
        letter-spacing: normal;
        color: #16171a;
        margin-bottom: 3px;
    }

    p {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.36;
        letter-spacing: normal;
        color: #16171a;
        margin-bottom: 3px;
    }

    .jumpto {
        padding: 0px 20px;
        margin-top: 20px;
    }

    .layoutbox {
        /*width: 254px;*/
        height: 5px;
        background-color: #919191;
    }

    .tile:hover .layoutbox{
        background-color: #0062ff;
    }

    .special-edition {
        height: 21px;
        background-color: #000000;
        font-size: 10px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        padding: 3px 10px 4px;
        float: right;
        margin-bottom: -21px;
    }

    .gridrow {
        font-family: BMWMotorrad;
    }

</style>
