<template>
    <div data-app="true" v-if="labels.engineSelectionPage">
        <v-dialog v-model="dialog" eager transition="slide-x-reverse-transition" id="engineSelectionPage">
            <template v-slot:activator="{ on }">
                <v-btn dark tile depressed color="#1C69D4" v-on="on">
                    {{ labels.mainPage.vehiclesList.chooseEngine }}
                </v-btn>
            </template>

            <v-card tile id="engineSelection">
                <div id="dialogContent">
                    <mq-layout mq="sm+">
                        <v-btn id="closeDefault" color="primary" text @click="dialog = false">
                            X
                        </v-btn>
                    </mq-layout>
                    <mq-layout mq="xs">
                        <v-btn id="closeMobile" color="primary" text @click="dialog = false">
                            X
                        </v-btn>
                    </mq-layout>
                    <v-card-title v-if="rangeDescription">
                        <h2 class="rangeDescription">{{ rangeDescription.toUpperCase() }}.</h2>
                    </v-card-title>
                    <v-card-text>
                        <img v-bind:src="modelImage" class="modelImage" alt="model image" @error="imageUrl='./imagenotfound.jpg'"/>
                        <h3>{{ labels.engineSelectionPage.engineSelectionSubTitle }}</h3>
                        <div id="vehicleSection">
                            <div class="transmissions">
                                <h2 class="inline">{{ enginesFound }} </h2>
                                <span v-show="enginesFound == 0">{{ labels.engineSelectionPage.enginesFoundPlural }}</span>
                                <span v-show="enginesFound == 1">{{ labels.engineSelectionPage.enginesFoundSingular }}</span>
                                <span v-show="enginesFound > 1">{{ labels.engineSelectionPage.enginesFoundPlural }}</span>
                            </div>
                            <div id="sectionElectric"
                                 v-if="modelRangeOffersElectric.length > 0 && enginesFound > 0">
                                <h3 class="offerSection"> {{ labels.engineSelectionPage.fuelTypes.electric }} </h3>
                                <EngineSelectionItemElectric :offer=offer v-for="(offer, index) in modelRangeOffersElectric"
                                                             :key="index + '_' + offer.vehicleInformation.vehicleId" @closeDialog="closeDialog"></EngineSelectionItemElectric>
                            </div>
                            <div id="sectionHybrid"
                                 v-if="modelRangeOffersHybrid.length > 0 && enginesFound > 0">
                                <h3 class="offerSection"> {{ labels.engineSelectionPage.fuelTypes.hybrid }} </h3>
                                <EngineSelectionItemHybrid :offer=offer v-for="(offer, index) in modelRangeOffersHybrid"
                                                           :key="index + '_' + offer.vehicleInformation.vehicleId" @closeDialog="closeDialog"></EngineSelectionItemHybrid>
                            </div>
                        </div>
                    </v-card-text>
                </div>
            </v-card>

        </v-dialog>
    </div>

</template>

<script>
    import EngineSelectionItemElectric from "./EngineSelectionItemElectric";
    import EngineSelectionItemHybrid from "./EngineSelectionItemHybrid";

    export default {
        name: 'EngineSelectionPageElectric',
        components: {
            EngineSelectionItemElectric,
            EngineSelectionItemHybrid
        },
        props: {
            modelSeries: '',
            modelRange: '',
            rangeDescription: '',
            modelImage: '',
        },
        data() {
            return {
                dialog: false,
            }
        },
        computed: {
            labels() {
                return this.$store.state.labels
            },
            modelRangeOffers() {
                let filteredOffers = this.$store.state.modelRangeOffers.filter((offer) => {
                    return offer.vehicleInformation.modelRange.includes(this.modelRange);
                })
                return filteredOffers;
            },
            modelRangeOffersElectric() {
                let filteredOffers = this.modelRangeOffers.filter((offer) => {
                    return offer.vehicleInformation.hybridCode.includes('BEVE');
                })
                return filteredOffers;
            },
            modelRangeVehiclesElectric() {
                const modelRangeVehicles = [];
                const vehicles = new Map();
                for (const offer of this.modelRangeOffersElectric) {
                    if (!vehicles.has(offer.vehicleInformation.vehicleId)) {
                        vehicles.set(offer.vehicleInformation.vehicleId, true);
                        modelRangeVehicles.push({
                            id: offer.vehicleInformation.vehicleId,
                            data: offer
                        });
                    }
                }
                return modelRangeVehicles;
            },
            modelRangeOffersHybrid() {
                let filteredOffers = this.modelRangeOffers.filter((offer) => {
                    return offer.vehicleInformation.hybridCode.includes('PHEV');
                })
                return filteredOffers;
            },
            modelRangeVehiclesHybrid() {
                const modelRangeVehicles = [];
                const vehicles = new Map();
                for (const offer of this.modelRangeOffersHybrid) {
                    if (!vehicles.has(offer.vehicleInformation.vehicleId)) {
                        vehicles.set(offer.vehicleInformation.vehicleId, true);
                        modelRangeVehicles.push({
                            id: offer.vehicleInformation.vehicleId,
                            data: offer
                        });
                    }
                }
                return modelRangeVehicles;
            },
            enginesFound() {
                return this.modelRangeVehiclesElectric.length + this.modelRangeVehiclesHybrid.length
            },

        },
        mounted() {

        },
        methods: {
            infoIconBtnClick() {
                console.log('Icon clicked: Info Icon')
            },
            closeDialog() {
                this.dialog = false
            },
            getModelRangeOffers() {
                let baseUrl = this.$store.getters.parameters.baseUrl
                let market = this.$store.getters.parameters.market
                let region = this.$store.getters.parameters.region
                let brand = this.$store.getters.parameters.brand
                let channel = this.$store.getters.parameters.channel
                let language = this.$store.getters.parameters.language
                let modelRange = this.modelRange
                let modelSeries = this.modelSeries
                let baseProduct = this.$store.getters.filterSettings.baseProduct
                let selectedProduct = this.$store.getters.filterSettings.selectedProduct
                this.$store.commit('setSelectedProduct', selectedProduct)
                let trm = this.$store.getters.filterSettings.trm
                let dep = this.$store.getters.filterSettings.dep
                let mil = this.$store.getters.filterSettings.mil
                let min = this.$store.getters.filterSettings.min
                let max = this.$store.getters.filterSettings.max
                let modelFilterUrl = ''
                if (region != 0) {
                    modelFilterUrl = baseUrl + '/filter/dc/market/' + market.toLowerCase() + ':' + region   + '/brand/' + brand + '/channel/' + channel + '/language/' + language + '/trm/' + trm + '/mil/' + mil + '/dep/' + dep + '/min/' + min + '?modelseries=' + modelSeries + '&modelrange=' + modelRange + '&productid=' + selectedProduct
                }
                else if (market == 'de') {
                    modelFilterUrl = baseUrl + '/filter/dc/market/' + market.toLowerCase() + '/brand/' + brand + '/channel/' + channel + '/language/' + language + '/trm/' + trm + '/mil/' + mil + '/dep/' + dep + '/min/' + min + '/max/' + max + '?baseproduct=' + baseProduct + '&modelseries=' + modelSeries + '&modelrange=' + modelRange
                }
                else {
                    modelFilterUrl = baseUrl + '/filter/dc/market/' + market.toLowerCase() + '/brand/' + brand + '/channel/' + channel + '/language/' + language + '/trm/' + trm + '/mil/' + mil + '/dep/' + dep + '/min/' + min + '/max/' + max + '?modelseries=' + modelSeries + '&modelrange=' + modelRange
                }
                this.$store.dispatch('fetchModelRangeOffers', modelFilterUrl)
            }
        },
        watch: {
            dialog: function () {
                if (this.dialog == true) {
                    document.getElementById("mainHTML").style.overflowY = "hidden"
                    this.getModelRangeOffers()
                }
                if (this.dialog == false) {
                    document.getElementById("mainHTML").style.overflowY = ""
                    document.getElementById("engineSelectionPage").scrollTop = 0
                }
            },
        }
    }
</script>

<style scoped>
    .v-card {
        position: absolute;
        width: 768px;
        height: 100%;
        overflow: scroll;
        top: 0;
        right: 0;
    }

    #engineSelection {
        background-color: #F9F9F9;
    }

    #dialogContent {
        width: 97%;
        margin-left: 1.5%;
    }

    #closeDefault {
        position: fixed;
        min-width: 0px;
        height: 50px;
        width: 50px;
        margin: 0;
        padding: 0;
        right: 45px;
        top: 12px;
        box-shadow: 0px 0px 5px lightgrey;
        border-radius: 1px;
        font-size: 18px;
        color: grey;
        background-color: white;
        z-index: 100;
    }

    #closeMobile {
        position: fixed;
        min-width: 0px;
        height: 40px;
        width: 40px;
        margin: 0;
        padding: 0;
        right: 20px;
        top: 20px;
        box-shadow: 0px 0px 5px lightgrey;
        border-radius: 1px;
        font-size: 16px;
        color: grey;
        background-color: white;
        z-index: 100;
    }

    #mobile .v-card {
        width: 100%;
    }

    img {
        height: 160px;
    }

    h2, h3, span, v-label {
        color: #262626;
    }

    h3 {
        white-space: normal;
        margin-bottom: 40px;
        margin-top: 10px;
    }

    .offerSection {
        margin-bottom: 15px;
        font-weight: bold;
    }

    p, span {
        font-size: 12px;
        letter-spacing: 0.16px;
        margin-bottom: 3px;
    }

    button {
        font-size: 14px;
        letter-spacing: 0.20px;
        margin-bottom: 3px;
    }

    #default button {
        margin-bottom: 10px;
        margin-top: 10px;
    }

    .v-card__actions {
        height: 50px;
    }

    .v-card__actions button {
        margin-bottom: 0px;
    }

    .modelImage {
        width: 100%;
        height: auto;
    }

    .selectionBox {
        margin-right: 15px;
        word-wrap: break-spaces;
        width: 190px;
    }

    .selectionRow {
        margin-left: 0px;
        margin-top: -10px;
    }

    .headline {
        font-weight: bold;
        color: #8E8E8E;
        margin-top: 0px;
        margin-bottom: -5px;
    }

    .transmissions {
        margin-bottom: 20px;
    }

    .transmissions span {
        font-size: 15px;
        letter-spacing: 0.22px;
    }

    #filterSection {
        background-color: white;
        box-shadow: 0 0 5px lightgrey;
        margin-bottom: 25px;
        padding: 20px 20px;
    }

    .rangeDescription {
        white-space: normal;
        word-break: break-word;
        margin-top: 3%;
    }

</style>
