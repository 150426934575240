<template>
    <div data-app="true" v-if="labels.mainPage">
        <v-dialog v-model="dialog" eager transition="slide-x-reverse-transition">
            <template v-slot:activator="{ on }">
                <div class="info-icon" v-on="on">
                    <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" focusable="false"
                         style="pointer-events: none; display: block; width: 100%; height: 100%;">
                        <g fill="none" fill-rule="evenodd" viewBox="0 0 24 24">
                            <circle cx="12" cy="12" r="11" stroke="#BBB" stroke-width="2"></circle>
                            <path fill="#4D4D4D"
                                  d="M11 16.09h1.818v-5.555H11v5.556zm0-7.272h1.818V7H11v1.818z"></path>
                        </g>
                    </svg>
                </div>
            </template>

            <v-card tile class="efficiency">
                <v-btn id="close" color="primary" text @click="dialog = false">
                    X
                </v-btn>
                <v-card-title v-if="efficiencyData.title">
                    <h2 class="title">{{ efficiencyData.title }}</h2>
                </v-card-title>

                <v-card-text>
                    <div v-if="labels.mainPage">
                        <p v-if="origin == 'offer'">{{efficiencyData.line}}</p>
                        <h3 class="section">{{labels.efficiencyInformationPage.efficiencyNEFZ}}</h3>
                        <mq-layout mq="sm+">
                            <table>
                                <tr>
                                    <td class="efficiency-label-desktop"
                                        v-if="!efficiencyData.hybridCodes.includes('BEVE')">
                                        <p v-if="efficiencyData.hybridCodes.includes('NOHY') || efficiencyData.hybridCodes.includes('HYBR')">
                                            {{labels.efficiencyInformationPage.engineDetails.consumption}}</p>
                                        <p v-if="efficiencyData.hybridCodes.includes('PHEV') && !efficiencyData.hybridCodes.includes('NOHY')">{{labels.efficiencyInformationPage.hybridDetails.fuelConsumption}}</p>
                                        <span class="efficiency-data">{{ efficiencyData.consumptionNEFZ.fuelConsumptionNEFZMin | formatValue('Float')}}
                                        </span>
                                        <span class="efficiency-data"
                                              v-if="efficiencyData.consumptionNEFZ.fuelConsumptionNEFZMin != efficiencyData.consumptionNEFZ.fuelConsumptionNEFZMax">
                                            <span class="seperator"> - </span>
                                        </span>
                                        <span class="efficiency-data"
                                              v-if="efficiencyData.consumptionNEFZ.fuelConsumptionNEFZMin != efficiencyData.consumptionNEFZ.fuelConsumptionNEFZMax">
                                            {{efficiencyData.consumptionNEFZ.fuelConsumptionNEFZMax | formatValue('Float')}}
                                        </span>
                                        <span class="efficiency-data"> {{labels.engineDetailFormat.fuelConsumptionAmount}} <sup>[2]</sup></span>
                                    </td>
                                    <td class="efficiency-label-desktop">
                                        <p v-if="efficiencyData.hybridCodes.includes('NOHY') || efficiencyData.hybridCodes.includes('HYBR') || efficiencyData.hybridCodes.includes('BEVE')">
                                            {{labels.efficiencyInformationPage.engineDetails.emissions}} ({{labels.efficiencyInformationPage.engineDetails.combined}})</p>
                                        <p v-if="efficiencyData.hybridCodes.includes('PHEV') && !efficiencyData.hybridCodes.includes('NOHY')">{{labels.efficiencyInformationPage.hybridDetails.emissions}} (
                                            {{labels.efficiencyInformationPage.hybridDetails.weightedCombined}})</p>
                                        <span class="efficiency-data" v-if="!efficiencyData.hybridCodes.includes('BEVE')">
                                            {{ efficiencyData.emissionNEFZ.emissionNEFZMin | formatValue('Integer')}}
                                        </span>
                                        <span class="efficiency-data" v-if="efficiencyData.hybridCodes.includes('BEVE')">
                                            0
                                        </span>
                                        <span class="efficiency-data"
                                              v-if="efficiencyData.emissionNEFZ.emissionNEFZMin != efficiencyData.emissionNEFZ.emissionNEFZMax">
                                            <span class="seperator"> - </span> {{efficiencyData.emissionNEFZ.emissionNEFZMax | formatValue('Integer')}}
                                        </span>
                                        <span class="efficiency-data">
                                            {{labels.engineDetailFormat.emissionsAmount}} </span>
                                        <span class="efficiency-data">
                                            <span class="minEnergyLabel" :class="efficiencyData.emissionNEFZ.minEnergyLabel">{{efficiencyData.emissionNEFZ.minEnergyLabel}}</span>
                                        </span>
                                        <span class="efficiency-data"
                                              v-if="efficiencyData.emissionNEFZ.minEnergyLabel != efficiencyData.emissionNEFZ.maxEnergyLabel">
                                            <span class="seperator"> - </span>  <span class="maxEnergyLabel" :class="efficiencyData.emissionNEFZ.maxEnergyLabel">{{efficiencyData.emissionNEFZ.maxEnergyLabel}}</span> <sup>[2]</sup>
                                        </span>
                                    </td>
                                </tr>
                                <tr v-if="efficiencyData.hybridCodes.includes('PHEV') || efficiencyData.hybridCodes.includes('BEVE')">
                                    <td class="efficiency-label-desktop">
                                        <p class="gap">{{labels.efficiencyInformationPage.hybridDetails.electricConsumption}}</p>
                                        <span class="efficiency-data">{{ efficiencyData.consumptionNEFZ.electricConsumptionNEFZMin | formatValue('Float')}}
                                        </span>
                                        <span class="efficiency-data"
                                              v-if="efficiencyData.consumptionNEFZ.electricConsumptionNEFZMin != efficiencyData.consumptionNEFZ.electricConsumptionNEFZMax">
                                            <span class="seperator"> - </span>
                                        </span>
                                        <span class="efficiency-data"
                                              v-if="efficiencyData.consumptionNEFZ.electricConsumptionNEFZMin != efficiencyData.consumptionNEFZ.electricConsumptionNEFZMax">
                                            {{efficiencyData.consumptionNEFZ.electricConsumptionNEFZMax | formatValue('Float')}}
                                        </span>
                                        <span class="efficiency-data"> {{labels.engineDetailFormat.electricConsumptionAmount}} <sup>[2]</sup></span>
                                    </td>
                                    <td class="efficiency-label-desktop">
                                        <p class="gap">{{labels.efficiencyInformationPage.hybridDetails.electricRange}}</p>
                                        <span class="efficiency-data">{{ efficiencyData.rangeNEFZ.electricRangeNEFZMin}}
                                        </span>
                                        <span class="efficiency-data"
                                              v-if="efficiencyData.rangeNEFZ.electricRangeNEFZMin != efficiencyData.rangeNEFZ.electricRangeNEFZMax">
                                            <span class="seperator"> - </span>
                                        </span>
                                        <span class="efficiency-data"
                                              v-if="efficiencyData.rangeNEFZ.electricRangeNEFZMin != efficiencyData.rangeNEFZ.electricRangeNEFZMax">
                                            {{efficiencyData.rangeNEFZ.electricRangeNEFZMax}}
                                        </span>
                                        <span class="efficiency-data"> {{labels.engineDetailFormat.range}}</span>
                                    </td>
                                </tr>
                            </table>
                        </mq-layout>
                        <mq-layout mq="xs">
                            <table>
                                <tr v-if="!efficiencyData.hybridCodes.includes('BEVE')">
                                    <td class="efficiency-label-mobile">
                                        <p v-if="efficiencyData.hybridCodes.includes('NOHY') || efficiencyData.hybridCodes.includes('HYBR')">
                                            {{labels.efficiencyInformationPage.engineDetails.consumption}}</p>
                                        <p v-if="efficiencyData.hybridCodes.includes('PHEV') && !efficiencyData.hybridCodes.includes('NOHY')">{{labels.efficiencyInformationPage.hybridDetails.fuelConsumption}}</p>
                                    </td>
                                    <td>
                                        <span class="efficiency-data">{{ efficiencyData.consumptionNEFZ.fuelConsumptionNEFZMin | formatValue('Float')}}
                                        </span>
                                        <span class="efficiency-data"
                                              v-if="efficiencyData.consumptionNEFZ.fuelConsumptionNEFZMin != efficiencyData.consumptionNEFZ.fuelConsumptionNEFZMax">
                                            <span class="seperator"> - </span>
                                        </span>
                                        <span class="efficiency-data"
                                              v-if="efficiencyData.consumptionNEFZ.fuelConsumptionNEFZMin != efficiencyData.consumptionNEFZ.fuelConsumptionNEFZMax">
                                            {{efficiencyData.consumptionNEFZ.fuelConsumptionNEFZMax | formatValue('Float')}}
                                        </span>
                                        <span class="efficiency-data"> {{labels.engineDetailFormat.fuelConsumptionAmount}} <sup>[2]</sup></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="efficiency-label-mobile">
                                        <p v-if="efficiencyData.hybridCodes.includes('NOHY') || efficiencyData.hybridCodes.includes('HYBR') || efficiencyData.hybridCodes.includes('BEVE')">
                                            {{labels.efficiencyInformationPage.engineDetails.emissions}} ({{labels.efficiencyInformationPage.engineDetails.combined}})</p>
                                        <p v-if="efficiencyData.hybridCodes.includes('PHEV') && !efficiencyData.hybridCodes.includes('NOHY')">{{labels.efficiencyInformationPage.hybridDetails.emissions}} ({{labels.efficiencyInformationPage.hybridDetails.weightedCombined}})</p>
                                    </td>
                                    <td>
                                        <span class="efficiency-data" v-if="!efficiencyData.hybridCodes.includes('BEVE')">
                                            {{ efficiencyData.emissionNEFZ.emissionNEFZMin | formatValue('Integer')}}
                                        </span>
                                        <span class="efficiency-data" v-if="efficiencyData.hybridCodes.includes('BEVE')">
                                            0
                                        </span>
                                        <span class="efficiency-data"
                                              v-if="efficiencyData.emissionNEFZ.emissionNEFZMin != efficiencyData.emissionNEFZ.emissionNEFZMax">
                                            <span class="seperator"> - </span> {{efficiencyData.emissionNEFZ.emissionNEFZMax | formatValue('Integer')}}
                                        </span>
                                        <span class="efficiency-data">
                                            {{labels.engineDetailFormat.emissionsAmount}} </span>
                                        <span class="efficiency-data">
                                            <span class="minEnergyLabel" :class="efficiencyData.emissionNEFZ.minEnergyLabel">{{efficiencyData.emissionNEFZ.minEnergyLabel}}</span>
                                        </span>
                                        <span class="efficiency-data"
                                              v-if="efficiencyData.emissionNEFZ.minEnergyLabel != efficiencyData.emissionNEFZ.maxEnergyLabel">
                                            <span class="seperator"> - </span>  <span class="maxEnergyLabel" :class="efficiencyData.emissionNEFZ.maxEnergyLabel">{{efficiencyData.emissionNEFZ.maxEnergyLabel}}</span> <sup>[2]</sup>
                                        </span>
                                    </td>
                                </tr>
                                <tr v-if="efficiencyData.hybridCodes.includes('PHEV') || efficiencyData.hybridCodes.includes('BEVE')">
                                    <td class="efficiency-label-mobile">
                                        <p>{{labels.efficiencyInformationPage.hybridDetails.electricConsumption}}</p>
                                    </td>
                                    <td>
                                        <span class="efficiency-data">{{ efficiencyData.consumptionNEFZ.electricConsumptionNEFZMin | formatValue('Float')}}
                                        </span>
                                        <span class="efficiency-data"
                                              v-if="efficiencyData.consumptionNEFZ.electricConsumptionNEFZMin != efficiencyData.consumptionNEFZ.electricConsumptionNEFZMax">
                                            <span class="seperator"> - </span>
                                        </span>
                                        <span class="efficiency-data"
                                              v-if="efficiencyData.consumptionNEFZ.electricConsumptionNEFZMin != efficiencyData.consumptionNEFZ.electricConsumptionNEFZMax">
                                            {{efficiencyData.consumptionNEFZ.electricConsumptionNEFZMax | formatValue('Float')}}
                                        </span>
                                        <span class="efficiency-data"> {{labels.engineDetailFormat.electricConsumptionAmount}} <sup>[2]</sup></span>
                                    </td>
                                </tr>
                                <tr v-if="efficiencyData.hybridCodes.includes('PHEV') || efficiencyData.hybridCodes.includes('BEVE')">
                                    <td class="efficiency-label-mobile">
                                        <p>{{labels.efficiencyInformationPage.hybridDetails.electricRange}}</p>
                                    </td>
                                    <td>
                                        <span class="efficiency-data">{{ efficiencyData.rangeNEFZ.electricRangeNEFZMin}}
                                        </span>
                                        <span class="efficiency-data"
                                              v-if="efficiencyData.rangeNEFZ.electricRangeNEFZMin != efficiencyData.rangeNEFZ.electricRangeNEFZMax">
                                            <span class="seperator"> - </span>
                                        </span>
                                        <span class="efficiency-data"
                                              v-if="efficiencyData.rangeNEFZ.electricRangeNEFZMin != efficiencyData.rangeNEFZ.electricRangeNEFZMax">
                                            {{efficiencyData.rangeNEFZ.electricRangeNEFZMax}}
                                        </span>
                                        <span class="efficiency-data"> {{labels.engineDetailFormat.range}}</span>
                                    </td>
                                </tr>
                            </table>
                        </mq-layout>

                        <h3 class="section">{{labels.efficiencyInformationPage.efficiencyLabel}}</h3>
                        <div class="efficiency-grid-container">
                            <!--<div class="efficiency-grid">

                                <div class="efficiency-class-holder">
                                    <svg height="100%" viewBox="0 0 125 50" class="efficiency-class" preserveAspectRatio="xMaxYMid slice" style="&#45;&#45;class-index: 0; fill: #009036">
                                        <path d="M -10000 0 h 10100 l 25 25 l -25 25 h -10100 Z" stroke="none"></path>
                                    </svg>
                                    <svg class="efficiency-class-text" style="&#45;&#45;class-index: 0;">
                                        <text class="text" x="100%" y="50%">A<tspan class="text-sup" dy="-25%">+</tspan></text>
                                    </svg>
                                </div>

                                <div class="efficiency-class-holder">
                                    <svg height="100%" viewBox="0 0 125 50" class="efficiency-class" preserveAspectRatio="xMaxYMid slice" style="&#45;&#45;class-index: 1; fill: #009642">
                                        <path d="M -10000 0 h 10100 l 25 25 l -25 25 h -10100 Z" stroke="none"></path>
                                    </svg>
                                    <svg class="efficiency-class-text" style="&#45;&#45;class-index: 1;">
                                        <text class="text" x="100%" y="50%">A</text>
                                    </svg>
                                </div>

                                <div class="efficiency-class-holder">
                                    <svg height="100%" viewBox="0 0 125 50" class="efficiency-class" preserveAspectRatio="xMaxYMid slice" style="&#45;&#45;class-index: 2; fill: #52ae34">
                                        <path d="M -10000 0 h 10100 l 25 25 l -25 25 h -10100 Z" stroke="none"></path>
                                    </svg>
                                    <svg class="efficiency-class-text" style="&#45;&#45;class-index: 2;">
                                        <text class="text" x="100%" y="50%">B</text>
                                    </svg>
                                </div>

                                <div class="efficiency-class-holder">
                                    <svg height="100%" viewBox="0 0 125 50" class="efficiency-class" preserveAspectRatio="xMaxYMid slice" style="&#45;&#45;class-index: 3; fill: #c6d300">
                                        <path d="M -10000 0 h 10100 l 25 25 l -25 25 h -10100 Z" stroke="none"></path>
                                    </svg>
                                    <svg class="efficiency-class-text" style="&#45;&#45;class-index: 3;">
                                        <text class="text" x="100%" y="50%">C</text>
                                    </svg>
                                </div>

                                <div class="efficiency-class-holder">
                                    <svg height="100%" viewBox="0 0 125 50" class="efficiency-class" preserveAspectRatio="xMaxYMid slice" style="&#45;&#45;class-index: 4; fill: #ffed00">
                                        <path d="M -10000 0 h 10100 l 25 25 l -25 25 h -10100 Z" stroke="none"></path>
                                    </svg>
                                    <svg class="efficiency-class-text" style="&#45;&#45;class-index: 4;">
                                        <text class="text" x="100%" y="50%">D</text>
                                    </svg>
                                </div>

                                <div class="efficiency-class-holder">
                                    <svg height="100%" viewBox="0 0 125 50" class="efficiency-class" preserveAspectRatio="xMaxYMid slice" style="&#45;&#45;class-index: 5; fill: #edae00">
                                        <path d="M -10000 0 h 10100 l 25 25 l -25 25 h -10100 Z" stroke="none"></path>
                                    </svg>
                                    <svg class="efficiency-class-text" style="&#45;&#45;class-index: 5;">
                                        <text class="text" x="100%" y="50%">E</text>
                                    </svg>
                                </div>

                                <div class="efficiency-class-holder">
                                    <svg height="100%" viewBox="0 0 125 50" class="efficiency-class" preserveAspectRatio="xMaxYMid slice" style="&#45;&#45;class-index: 6; fill: #ea670b">
                                        <path d="M -10000 0 h 10100 l 25 25 l -25 25 h -10100 Z" stroke="none"></path>
                                    </svg>
                                    <svg class="efficiency-class-text" style="&#45;&#45;class-index: 6;">
                                        <text class="text" x="100%" y="50%">F</text>
                                    </svg>
                                </div>

                                <div class="efficiency-class-holder">
                                    <svg height="100%" viewBox="0 0 125 50" class="efficiency-class" preserveAspectRatio="xMaxYMid slice" style="&#45;&#45;class-index: 7; fill: #e20c15">
                                        <path d="M -10000 0 h 10100 l 25 25 l -25 25 h -10100 Z" stroke="none"></path>
                                    </svg>
                                    <svg class="efficiency-class-text" style="&#45;&#45;class-index: 7;">
                                        <text class="text" x="100%" y="50%">G</text>
                                    </svg>
                                </div>

                                <div id="selectedEfficiency" style="grid-row: 4 / auto;">
                                    <svg viewBox="0 0 44 20" preserveAspectRatio="xMinYMid slice" class="selected-efficiency-class">
                                        <polygon points="1,10 10,19 43,19 43,1 10,1" stroke="#262626" stroke-width="2"></polygon>
                                    </svg>

                                    <svg class="selected-efficiency-class-text">
                                        <text class="text" x="50%" y="50%">C</text>
                                    </svg>
                                </div>
                            </div>-->
                        </div>
                        <p class="section">
                            <sup>[2]</sup> Die offiziellen Angaben zu Kraftstoffverbrauch, CO2-Emissionen und
                            Stromverbrauch wurden nach dem vorgeschriebenen Messverfahren VO (EU) 715/2007 in der
                            jeweils geltenden Fassung ermittelt. Die Angaben berücksichtigen Unterschiede in der
                            gewählten Rad- und Reifengröße und der optionalen Sonderausstattung. Diese Werte entsprechen
                            Ihrer aktuellen Konfiguration. Bei Änderungen der Ausstattung können sich diese Werte
                            ändern. Die Angaben sind bereits auf Basis des neuen WLTP-Testzyklus ermittelt und zur
                            Vergleichbarkeit auf NEFZ zurückgerechnet. Bei diesen Fahrzeugen können für die Bemessung
                            von Steuern und anderen fahrzeugbezogenen Abgaben, die (auch) auf den CO2-Ausstoß abstellen,
                            andere als die hier angegebenen Werte gelten. Ab dem 01. September 2018 erfolgt die
                            Bemessung der KFZ-Steuer auf Basis der ermittelten WLTP-Werte. Weitere Information zum
                            Umweltbonus und E-Kennzeichen erhalten Sie bei Ihrem BMW Partner. Die CO2-Effizienzangaben
                            ergeben sich aus der Richtlinie 1999/94/EG und der Pkw-EnVKV in ihrer aktuellen Fassung und
                            verwenden die Verbrauchs- und CO2-Werte des NEFZ zur Einstufung. Die Angaben beziehen sich
                            nicht auf ein einzelnes Fahrzeug und sind nicht Bestandteil des Angebotes, sondern dienen
                            allein Vergleichszwecken zwischen den verschiedenen Fahrzeugtypen. Weitere Informationen zum
                            offiziellen Kraftstoffverbrauch und den offiziellen spezifischen CO2-Emissionen neuer
                            Personenkraftwagen können dem 'Leitfaden über den Kraftstoffverbrauch, die CO2-Emissionen
                            und den Stromverbrauch neuer Personenkraftwagen' entnommen werden, der an allen
                            Verkaufsstellen, bei der Deutschen Automobil Treuhand GmbH (DAT), Hellmuth-Hirth-Str. 1,
                            73760 Ostfildern-Scharnhausen, und unter <a href="https://www.dat.de/co2" target="_blank">https://www.dat.de/co2</a>
                            unentgeltlich erhältlich ist.
                        </p>
                        <h2 class="section">{{labels.efficiencyInformationPage.efficiencyWLTP}}</h2>
                        <p class="emphasized, section">
                            {{labels.efficiencyInformationPage.explanationWLTP}}
                        </p>
                        <h3 class="section">
                            {{labels.efficiencyInformationPage.validityWLTP}}
                        </h3>
                        <p>
                            Seit dem 1. September 2017 werden bestimmte Neuwagen nach dem weltweit harmonisierten
                            Prüfverfahren für Personenwagen und leichte Nutzfahrzeuge (Worldwide Harmonized Light Duty
                            Vehicles Test Procedure, WLTP), einem realistischeren Prüfverfahren zur Messung des
                            Kraftstoffverbrauchs und der CO2-Emissionen, typgenehmigt. Ab dem 1. September 2018 wird der
                            WLTP den neuen europäischen Fahrzyklus (NEFZ) ersetzen. Wegen der realistischeren
                            Prüfbedingungen sind die nach dem WLTP gemessenen Kraftstoffverbrauchs-, Stromverbrauchs-
                            und CO2-Emissionswerte in vielen Fällen höher und die Reichweiten in vielen Fällen niedriger
                            als die nach dem NEFZ gemessenen. Aktuell sind noch die NEFZ-Werte verpflichtend zu
                            kommunizieren. Soweit es sich um Neuwagen handelt, die nach WLTP typgenehmigt sind, werden
                            die o.g. NEFZ-Werte von den u.g. WLTP-Werten abgeleitet. Diese Werte entsprechen Ihrer
                            aktuellen Konfiguration, wenn Sie Ausstattungen ändern können sich auch diese Werte ändern.
                            Ab dem 01. September 2018 erfolgt die Bemessung der KFZ-Steuer auf Basis der ermittelten
                            WLTP-Werte. Weitere Informationen zum Umweltbonus und E-Kennzeichen erhalten Sie bei Ihrem
                            BMW Partner.
                        </p>
                        <mq-layout mq="sm+">
                            <h2 v-if="!efficiencyData.hybridCodes.includes('BEVE')" class="section">{{labels.efficiencyInformationPage.efficiencyWLTP}}</h2>
                            <table v-if="!efficiencyData.hybridCodes.includes('BEVE')">
                                <tr>
                                    <td class="efficiency-label-desktop">
                                        <p v-if="efficiencyData.hybridCodes.includes('NOHY') || efficiencyData.hybridCodes.includes('HYBR')">
                                            {{labels.efficiencyInformationPage.engineDetails.emissions}} ({{labels.efficiencyInformationPage.engineDetails.combined}})</p>
                                        <p v-if="efficiencyData.hybridCodes.includes('PHEV') && !efficiencyData.hybridCodes.includes('NOHY')">{{labels.efficiencyInformationPage.hybridDetails.emissions}} ({{labels.efficiencyInformationPage.hybridDetails.weightedCombined}})</p>
                                        <span class="efficiency-data">
                                            {{ efficiencyData.emissionWLTP.emissionWLTPMin | formatValue('Integer')}}
                                        </span>
                                        <span class="efficiency-data"
                                              v-if="efficiencyData.emissionWLTP.emissionWLTPMin != efficiencyData.emissionWLTP.emissionWLTPMax">
                                            <span class="seperator"> - </span> {{efficiencyData.emissionWLTP.emissionWLTPMax | formatValue('Integer')}}
                                        </span>
                                        <span class="efficiency-data">
                                            {{labels.engineDetailFormat.emissionsAmount}} <sup>[2]</sup> </span>
                                    </td>
                                </tr>
                            </table>
                            <h2 v-if="!efficiencyData.hybridCodes.includes('BEVE')" class="section">
                                {{labels.efficiencyInformationPage.hybridDetails.fuelConsumption}}</h2>
                            <table v-if="!efficiencyData.hybridCodes.includes('BEVE')">
                                <tr v-if="!efficiencyData.hybridCodes.includes('BEVE')">
                                    <td class="efficiency-label-desktop">
                                        <p v-if="efficiencyData.hybridCodes.includes('NOHY') || efficiencyData.hybridCodes.includes('HYBR')">
                                            {{labels.efficiencyInformationPage.engineDetails.consumption}}</p>
                                        <p v-if="efficiencyData.hybridCodes.includes('PHEV') && !efficiencyData.hybridCodes.includes('NOHY')">{{labels.efficiencyInformationPage.hybridDetails.fuelConsumption}}</p>
                                        <span class="efficiency-data">{{ efficiencyData.consumptionWLTP.fuelConsumptionWLTPMin | formatValue('Float')}}
                                        </span>
                                        <span class="efficiency-data"
                                              v-if="efficiencyData.consumptionWLTP.fuelConsumptionWLTPMin != efficiencyData.consumptionWLTP.fuelConsumptionWLTPMax">
                                            <span class="seperator"> - </span>
                                        </span>
                                        <span class="efficiency-data"
                                              v-if="efficiencyData.consumptionWLTP.fuelConsumptionWLTPMin != efficiencyData.consumptionWLTP.fuelConsumptionWLTPMax">
                                            {{efficiencyData.consumptionWLTP.fuelConsumptionWLTPMax | formatValue('Float')}}
                                        </span>
                                        <span class="efficiency-data"> {{labels.engineDetailFormat.fuelConsumptionAmount}} <sup>[2]</sup></span>
                                    </td>
                                </tr>
                            </table>
                            <h2 v-if="efficiencyData.hybridCodes.includes('PHEV') || efficiencyData.hybridCodes.includes('BEVE')"
                                class="section">{{labels.efficiencyInformationPage.hybridDetails.electricConsumption}}</h2>
                            <table v-if="efficiencyData.hybridCodes.includes('PHEV')">
                                <tr>
                                    <td class="efficiency-label-desktop">
                                        <p>{{labels.efficiencyInformationPage.hybridDetails.electricConsumption}} ({{labels.efficiencyInformationPage.engineDetails.combined}})</p>
                                        <span class="efficiency-data">{{ efficiencyData.consumptionWLTP.hybrid.electricConsumptionAcWeightedWLTPMin | formatValue('Float')}}
                                        </span>
                                        <span class="efficiency-data"
                                              v-if="efficiencyData.consumptionWLTP.hybrid.electricConsumptionAcWeightedWLTPMin != efficiencyData.consumptionWLTP.hybrid.electricConsumptionAcWeightedWLTPMax">
                                            <span class="seperator"> - </span>
                                        </span>
                                        <span class="efficiency-data"
                                              v-if="efficiencyData.consumptionWLTP.hybrid.electricConsumptionAcWeightedWLTPMin != efficiencyData.consumptionWLTP.hybrid.electricConsumptionAcWeightedWLTPMax">
                                            {{efficiencyData.consumptionWLTP.hybrid.electricConsumptionAcWeightedWLTPMax | formatValue('Float')}}
                                        </span>
                                        <span class="efficiency-data"> {{labels.engineDetailFormat.electricConsumptionAmount}} <sup>[2]</sup></span>
                                    </td>
                                </tr>
                            </table>
                            <table v-if="efficiencyData.hybridCodes.includes('BEVE')">
                                <tr>
                                    <td class="efficiency-label-desktop">
                                        <p>{{labels.efficiencyInformationPage.hybridDetails.electricConsumption}} ({{labels.efficiencyInformationPage.engineDetails.combined}})</p>
                                        <span class="efficiency-data">{{ efficiencyData.consumptionWLTP.electric.electricConsumptionWLTPMin | formatValue('Float')}}
                                        </span>
                                        <span class="efficiency-data"
                                              v-if="efficiencyData.consumptionWLTP.electric.electricConsumptionWLTPMin != efficiencyData.consumptionWLTP.electric.electricConsumptionWLTPMax">
                                            <span class="seperator"> - </span>
                                        </span>
                                        <span class="efficiency-data"
                                              v-if="efficiencyData.consumptionWLTP.electric.electricConsumptionWLTPMin != efficiencyData.consumptionWLTP.electric.electricConsumptionWLTPMax">
                                            {{efficiencyData.consumptionWLTP.electric.electricConsumptionWLTPMax | formatValue('Float')}}
                                        </span>
                                        <span class="efficiency-data"> {{labels.engineDetailFormat.electricConsumptionAmount}} <sup>[2]</sup></span>
                                    </td>
                                </tr>
                            </table>
                            <h2 v-if="efficiencyData.hybridCodes.includes('PHEV') || efficiencyData.hybridCodes.includes('BEVE')"
                                class="section">{{labels.efficiencyInformationPage.hybridDetails.electricRange}}</h2>
                            <table v-if="efficiencyData.hybridCodes.includes('PHEV')">
                                <tr>
                                    <td class="efficiency-label-desktop">
                                        <p>{{labels.efficiencyInformationPage.hybridDetails.electricRange}} ({{labels.efficiencyInformationPage.engineDetails.combined}})</p>
                                        <span class="efficiency-data">{{ efficiencyData.rangeWLTP.hybrid.equivalentAllElectricRangeWLTPMin}}
                                        </span>
                                        <span class="efficiency-data"
                                              v-if="efficiencyData.rangeWLTP.hybrid.equivalentAllElectricRangeWLTPMin != efficiencyData.rangeWLTP.hybrid.equivalentAllElectricRangeWLTPMax">
                                            <span class="seperator"> - </span>
                                        </span>
                                        <span class="efficiency-data"
                                              v-if="efficiencyData.rangeWLTP.hybrid.equivalentAllElectricRangeWLTPMin != efficiencyData.rangeWLTP.hybrid.equivalentAllElectricRangeWLTPMax">
                                            {{efficiencyData.rangeWLTP.hybrid.equivalentAllElectricRangeWLTPMax}}
                                        </span>
                                        <span class="efficiency-data"> {{labels.engineDetailFormat.range}}</span>
                                    </td>
                                    <td class="efficiency-label-desktop">
                                        <p>{{labels.efficiencyInformationPage.electricDetails.electricRangeCity}}</p>
                                        <span class="efficiency-data">{{ efficiencyData.rangeWLTP.hybrid.equivalentAllElectricRangeCityWLTPMin}}
                                        </span>
                                        <span class="efficiency-data"
                                              v-if="efficiencyData.rangeWLTP.hybrid.equivalentAllElectricRangeCityWLTPMin != efficiencyData.rangeWLTP.hybrid.equivalentAllElectricRangeCityWLTPMax">
                                            <span class="seperator"> - </span>
                                        </span>
                                        <span class="efficiency-data"
                                              v-if="efficiencyData.rangeWLTP.hybrid.equivalentAllElectricRangeCityWLTPMin != efficiencyData.rangeWLTP.hybrid.equivalentAllElectricRangeCityWLTPMax">
                                            {{efficiencyData.rangeWLTP.hybrid.equivalentAllElectricRangeCityWLTPMax}}
                                        </span>
                                        <span class="efficiency-data"> {{labels.engineDetailFormat.range}}</span>
                                    </td>
                                </tr>
                            </table>
                            <table v-if="efficiencyData.hybridCodes.includes('BEVE')">
                                <tr>
                                    <td class="efficiency-label-desktop">
                                        <p>{{labels.efficiencyInformationPage.hybridDetails.electricRange}} ({{labels.efficiencyInformationPage.engineDetails.combined}})</p>
                                        <span class="efficiency-data">{{ efficiencyData.rangeWLTP.electric.pureElectricRangeWLTPMin}}
                                        </span>
                                        <span class="efficiency-data"
                                              v-if="efficiencyData.rangeWLTP.electric.pureElectricRangeWLTPMin != efficiencyData.rangeWLTP.electric.pureElectricRangeWLTPMax">
                                            <span class="seperator"> - </span>
                                        </span>
                                        <span class="efficiency-data"
                                              v-if="efficiencyData.rangeWLTP.electric.pureElectricRangeWLTPMin != efficiencyData.rangeWLTP.electric.pureElectricRangeWLTPMax">
                                            {{efficiencyData.rangeWLTP.electric.pureElectricRangeWLTPMax}}
                                        </span>
                                        <span class="efficiency-data"> {{labels.engineDetailFormat.range}}</span>
                                    </td>
                                </tr>
                            </table>
                        </mq-layout>
                        <mq-layout mq="xs">
                            <h2 v-if="!efficiencyData.hybridCodes.includes('BEVE')" class="section">{{labels.efficiencyInformationPage.efficiencyWLTP}}</h2>
                            <table v-if="!efficiencyData.hybridCodes.includes('BEVE')">
                                <tr>
                                    <td class="efficiency-label-mobile">
                                        <p v-if="efficiencyData.hybridCodes.includes('NOHY') || efficiencyData.hybridCodes.includes('HYBR')">
                                            {{labels.efficiencyInformationPage.engineDetails.emissions}} ({{labels.efficiencyInformationPage.engineDetails.combined}})</p>
                                        <p v-if="efficiencyData.hybridCodes.includes('PHEV') && !efficiencyData.hybridCodes.includes('NOHY')">{{labels.efficiencyInformationPage.hybridDetails.emissions}} ({{labels.efficiencyInformationPage.hybridDetails.weightedCombined}})</p>
                                    </td>
                                    <td>
                                        <span class="efficiency-data">
                                            {{ efficiencyData.emissionWLTP.emissionWLTPMin | formatValue('Integer')}}
                                        </span>
                                        <span class="efficiency-data"
                                              v-if="efficiencyData.emissionWLTP.emissionWLTPMin != efficiencyData.emissionWLTP.emissionWLTPMax">
                                            <span class="seperator"> - </span> {{efficiencyData.emissionWLTP.emissionWLTPMax | formatValue('Integer')}}
                                        </span>
                                        <span class="efficiency-data">
                                            {{labels.engineDetailFormat.emissionsAmount}} <sup>[2]</sup> </span>
                                    </td>
                                </tr>
                            </table>
                            <h2 v-if="!efficiencyData.hybridCodes.includes('BEVE')" class="section">
                                {{labels.efficiencyInformationPage.hybridDetails.fuelConsumption}}</h2>
                            <table v-if="!efficiencyData.hybridCodes.includes('BEVE')">
                                <tr v-if="!efficiencyData.hybridCodes.includes('BEVE')">
                                    <td class="efficiency-label-mobile">
                                        <p v-if="efficiencyData.hybridCodes.includes('NOHY') || efficiencyData.hybridCodes.includes('HYBR')">
                                            {{labels.efficiencyInformationPage.engineDetails.consumption}}</p>
                                        <p v-if="efficiencyData.hybridCodes.includes('PHEV') && !efficiencyData.hybridCodes.includes('NOHY')">{{labels.efficiencyInformationPage.hybridDetails.fuelConsumption}}</p>
                                    </td>
                                    <td>
                                        <span class="efficiency-data">{{ efficiencyData.consumptionWLTP.fuelConsumptionWLTPMin | formatValue('Float')}}
                                        </span>
                                        <span class="efficiency-data"
                                              v-if="efficiencyData.consumptionWLTP.fuelConsumptionWLTPMin != efficiencyData.consumptionWLTP.fuelConsumptionWLTPMax">
                                            <span class="seperator"> - </span>
                                        </span>
                                        <span class="efficiency-data"
                                              v-if="efficiencyData.consumptionWLTP.fuelConsumptionWLTPMin != efficiencyData.consumptionWLTP.fuelConsumptionWLTPMax">
                                            {{efficiencyData.consumptionWLTP.fuelConsumptionWLTPMax | formatValue('Float')}}
                                        </span>
                                        <span class="efficiency-data"> {{labels.engineDetailFormat.fuelConsumptionAmount}} <sup>[2]</sup></span>
                                    </td>
                                </tr>
                            </table>
                            <h2 v-if="efficiencyData.hybridCodes.includes('PHEV') || efficiencyData.hybridCodes.includes('BEVE')"
                                class="section">{{labels.efficiencyInformationPage.hybridDetails.electricConsumption}}</h2>
                            <table v-if="efficiencyData.hybridCodes.includes('PHEV')">
                                <tr>
                                    <td class="efficiency-label-mobile">
                                        <p>{{labels.efficiencyInformationPage.hybridDetails.electricConsumption}} ({{labels.efficiencyInformationPage.engineDetails.combined}})</p>
                                    </td>
                                    <td>
                                        <span class="efficiency-data">{{ efficiencyData.consumptionWLTP.hybrid.electricConsumptionAcWeightedWLTPMin | formatValue('Float')}}
                                        </span>
                                        <span class="efficiency-data"
                                              v-if="efficiencyData.consumptionWLTP.hybrid.electricConsumptionAcWeightedWLTPMin != efficiencyData.consumptionWLTP.hybrid.electricConsumptionAcWeightedWLTPMax">
                                            <span class="seperator"> - </span>
                                        </span>
                                        <span class="efficiency-data"
                                              v-if="efficiencyData.consumptionWLTP.hybrid.electricConsumptionAcWeightedWLTPMin != efficiencyData.consumptionWLTP.hybrid.electricConsumptionAcWeightedWLTPMax">
                                            {{efficiencyData.consumptionWLTP.hybrid.electricConsumptionAcWeightedWLTPMax | formatValue('Float')}}
                                        </span>
                                        <span class="efficiency-data"> {{labels.engineDetailFormat.electricConsumptionAmount}} <sup>[2]</sup></span>
                                    </td>
                                </tr>
                            </table>
                            <table v-if="efficiencyData.hybridCodes.includes('BEVE')">
                                <tr>
                                    <td class="efficiency-label-mobile">
                                        <p>{{labels.efficiencyInformationPage.hybridDetails.electricConsumption}} ({{labels.efficiencyInformationPage.engineDetails.combined}})</p>
                                    </td>
                                    <td>
                                        <span class="efficiency-data">{{ efficiencyData.consumptionWLTP.electric.electricConsumptionWLTPMin | formatValue('Float')}}
                                        </span>
                                        <span class="efficiency-data"
                                              v-if="efficiencyData.consumptionWLTP.electric.electricConsumptionWLTPMin != efficiencyData.consumptionWLTP.electric.electricConsumptionWLTPMax">
                                            <span class="seperator"> - </span>
                                        </span>
                                        <span class="efficiency-data"
                                              v-if="efficiencyData.consumptionWLTP.electric.electricConsumptionWLTPMin != efficiencyData.consumptionWLTP.electric.electricConsumptionWLTPMax">
                                            {{efficiencyData.consumptionWLTP.electric.electricConsumptionWLTPMin | formatValue('Float')}}
                                        </span>
                                        <span class="efficiency-data"> {{labels.engineDetailFormat.electricConsumptionAmount}} <sup>[2]</sup></span>
                                    </td>
                                </tr>
                            </table>
                            <h2 v-if="efficiencyData.hybridCodes.includes('PHEV') || efficiencyData.hybridCodes.includes('BEVE')"
                                class="section">{{labels.efficiencyInformationPage.hybridDetails.electricRange}}</h2>
                            <table v-if="efficiencyData.hybridCodes.includes('PHEV')">
                                <tr>
                                    <td class="efficiency-label-mobile">
                                        <p>{{labels.efficiencyInformationPage.hybridDetails.electricRange}} ({{labels.efficiencyInformationPage.engineDetails.combined}})</p>
                                    </td>
                                    <td>
                                        <span class="efficiency-data">{{ efficiencyData.rangeWLTP.hybrid.equivalentAllElectricRangeWLTPMin}}
                                        </span>
                                        <span class="efficiency-data"
                                              v-if="efficiencyData.rangeWLTP.hybrid.equivalentAllElectricRangeWLTPMin != efficiencyData.rangeWLTP.hybrid.equivalentAllElectricRangeWLTPMax">
                                            <span class="seperator"> - </span>
                                        </span>
                                        <span class="efficiency-data"
                                              v-if="efficiencyData.rangeWLTP.hybrid.equivalentAllElectricRangeWLTPMin != efficiencyData.rangeWLTP.hybrid.equivalentAllElectricRangeWLTPMax">
                                            {{efficiencyData.rangeWLTP.hybrid.equivalentAllElectricRangeWLTPMax}}
                                        </span>
                                        <span class="efficiency-data"> {{labels.engineDetailFormat.range}}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="efficiency-label-mobile">
                                        <p>{{labels.efficiencyInformationPage.electricDetails.electricRangeCity}}</p>
                                    </td>
                                    <td>
                                        <span class="efficiency-data">{{ efficiencyData.rangeWLTP.hybrid.equivalentAllElectricRangeCityWLTPMin}}
                                        </span>
                                        <span class="efficiency-data"
                                              v-if="efficiencyData.rangeWLTP.hybrid.equivalentAllElectricRangeCityWLTPMin != efficiencyData.rangeWLTP.hybrid.equivalentAllElectricRangeCityWLTPMax">
                                            <span class="seperator"> - </span>
                                        </span>
                                        <span class="efficiency-data"
                                              v-if="efficiencyData.rangeWLTP.hybrid.equivalentAllElectricRangeCityWLTPMin != efficiencyData.rangeWLTP.hybrid.equivalentAllElectricRangeCityWLTPMax">
                                            {{efficiencyData.rangeWLTP.hybrid.equivalentAllElectricRangeCityWLTPMax}}
                                        </span>
                                        <span class="efficiency-data"> {{labels.engineDetailFormat.range}}</span>
                                    </td>
                                </tr>
                            </table>
                            <table v-if="efficiencyData.hybridCodes.includes('BEVE')">
                                <tr>
                                    <td class="efficiency-label-mobile">
                                        <p>{{labels.efficiencyInformationPage.hybridDetails.electricRange}} ({{labels.efficiencyInformationPage.engineDetails.combined}})</p>
                                    </td>
                                    <td>
                                        <span class="efficiency-data">{{ efficiencyData.rangeWLTP.electric.pureElectricRangeWLTPMin}}
                                        </span>
                                        <span class="efficiency-data"
                                              v-if="efficiencyData.rangeWLTP.electric.pureElectricRangeWLTPMin != efficiencyData.rangeWLTP.electric.pureElectricRangeWLTPMax">
                                            <span class="seperator"> - </span>
                                        </span>
                                        <span class="efficiency-data"
                                              v-if="efficiencyData.rangeWLTP.electric.pureElectricRangeWLTPMin != efficiencyData.rangeWLTP.electric.pureElectricRangeWLTPMax">
                                            {{efficiencyData.rangeWLTP.electric.pureElectricRangeWLTPMax}}
                                        </span>
                                        <span class="efficiency-data"> {{labels.engineDetailFormat.range}}</span>
                                    </td>
                                </tr>
                            </table>
                        </mq-layout>
                        <p class="section">
                            Seit dem 1. September 2017 werden bestimmte Neuwagen nach dem weltweit harmonisierten
                            Prüfverfahren für Personenwagen und leichte Nutzfahrzeuge (Worldwide Harmonized Light Duty
                            Vehicles Test Procedure, WLTP), einem realistischeren Prüfverfahren zur Messung des
                            Kraftstoffverbrauchs und der CO2-Emissionen, typgenehmigt. Ab dem 1. September 2018 wird der
                            WLTP den neuen europäischen Fahrzyklus (NEFZ) ersetzen. Wegen der realistischeren
                            Prüfbedingungen sind die nach dem WLTP gemessenen Kraftstoffverbrauchs-, Stromverbrauchs-
                            und CO2-Emissionswerte in vielen Fällen höher und die Reichweiten in vielen Fällen niedriger
                            als die nach dem NEFZ gemessenen. Aktuell sind noch die NEFZ-Werte verpflichtend zu
                            kommunizieren. Soweit es sich um Neuwagen handelt, die nach WLTP typgenehmigt sind, werden
                            die o.g. NEFZ-Werte von den u.g. WLTP-Werten abgeleitet. Diese Werte entsprechen Ihrer
                            aktuellen Konfiguration, wenn Sie Ausstattungen ändern können sich auch diese Werte ändern.
                            Ab dem 01. September 2018 erfolgt die Bemessung der KFZ-Steuer auf Basis der ermittelten
                            WLTP-Werte. Weitere Informationen zum Umweltbonus und E-Kennzeichen erhalten Sie bei Ihrem
                            BMW Partner.
                        </p>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>

</template>

<script>
    export default {
        name: 'EfficiencyInformationPage',
        components: {},
        props: {
            efficiencyData: {},
            origin: ''
        },
        data() {
            return {
                dialog: false,
            }
        },
        computed: {
            labels() {
                return this.$store.state.labels
            },
        },
        created() {
        },
        methods: {},
        filters: {
            formatValue: function (value, type) {
                if (type == 'Integer') {
                    return parseInt(value)
                }
                if (type == 'Float') {
                    return parseFloat(value).toFixed(1).replace('.', ',')
                }
                if (type == 'Percentage') {
                    value = parseFloat(value * 100).toFixed(2)
                    value = value.replace('.', ',')
                    return value + ' %'
                }
                if (type == 'Currency') {
                    value = parseFloat(value).toFixed(2)
                    value = value.replace('.', ',')
                    let splitValue = value.split(',')
                    if (splitValue[0].length > 3) {
                        let thousands = splitValue[0].slice(0, -3)
                        let hundreds = splitValue[0].slice(-3)
                        return thousands + '.' + hundreds + ',' + splitValue[1] + ' ' + labels.financeParameterFormat.currencySymbol
                    }
                    return value + ' €'
                }
            },
        },
        watch: {
            dialog: function () {
                if (this.dialog == true) {
                    document.getElementById("mainHTML").style.overflowY = "hidden"
                }
                if (this.dialog == false) {
                    document.getElementById("mainHTML").style.overflowY = ""
                }
            },
        }
    }
</script>

<style scoped>
    #close {
        position: fixed;
        min-width: 0px;
        height: 45px;
        width: 45px;
        margin: 0;
        padding: 0;
        left: 90%;
        top: 1%;
        box-shadow: 0px 0px 5px lightgrey;
        border-radius: 1px;
        font-size: 18px;
        color: grey;
        background-color: white;
        z-index: 200;
    }

    #mobile #close {
        top: 2%;
        left: 85%;
    }

    .title {
        margin-top: 20px;
    }

    .efficiency {
        position: absolute;
        width: 60%;
        height: 80%;
        overflow: scroll;
        top: 10%;
        right: 20%;
    }

    #mobile .efficiency {
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
    }

    h2 .title {
        margin-bottom: 30px;
        margin-left: 20px;
        margin-top: 20px !important;
    }

    h2 {
        line-height: 120%;
    }

    p, span {
        font-size: 12px;
        letter-spacing: 0.16px;
        margin-bottom: 3px;
    }

    button {
        font-size: 14px;
        letter-spacing: 0.20px;
        margin-bottom: 3px;
    }

    #default button {
        margin-bottom: 10px;
        margin-top: 10px;
    }

    .efficiency-data {
        font-size: 15px;
    }

    .info-icon {
        height: 12px;
        width: 12px;
        min-width: 12px;
        justify-content: center;
        stroke: none;
        vertical-align: middle;
        margin-right: 15px;
        display: flex;
        align-items: center;
    }

    .v-card__text {
        padding-bottom: 15px !important;
    }

    .efficiency-grid {
        display: grid;
        grid-template-columns: 166.5px 63.5px;
        grid-template-rows: 30px 30px 30px 30px 30px 30px 30px 30px;
        height: 275px;
        hyphens: manual;
        pointer-events: none;
        width: 230px;
    }

    .efficiency-grid-container {
        display: inline-block;
        max-width: 230px;
        width: 230px;
    }

    .efficiency-label-desktop {
        min-width: 300px;
    }

    .efficiency-label-mobile {
        width: 150px;
    }

    .section {
        margin-top: 20px;
    }

    .gap {
        margin-top: 5px;
    }

    .minEnergyLabel {
        color: white;
        background-color: darkgrey;
        font-weight: bold;
        font-size: 10px;
        letter-spacing: 0.16px;
        padding: 1px 10px;
        height: 14px;
        display: inline;
    }

    .maxEnergyLabel {
        color: white;
        background-color: darkgrey;
        font-weight: bold;
        font-size: 10px;
        letter-spacing: 0.16px;
        padding: 1px 10px;
        height: 14px;
        justify-content: flex-start;
        display: inline;
        margin-right: 5px;
    }

    /* emission colors */

    .Aplus {
        background-color: #009036;
    }

    .A {
        background-color: #009642;
    }

    .B {
        background-color: #52ae34;
    }

    .C {
        background-color: #c6d300;
    }

    .D {
        background-color: #ffed00;
    }

    .E {
        background-color: #edae00;
    }

    .F {
        background-color: #ea670b;
    }

    .G {
        background-color: #e20c15;
    }

</style>
