<template>
    <div data-app="true" id="motoMainPage">
        <div class="headerimage"/>
        <div v-if="!settings || !labels || !bucketStats" class="fallback">
            <div class="header">
                <div class="headline heading"
                     :class="$mq">
                    <h1 class="heading" :class="$mq">
                        DEIN BUDGET, DEIN BIKE</h1>
                    <h2 class="heading" :class="$mq">
                        SAG UNS WAS DU HAST, WIR SAGEN DIR WAS DU BEKOMMST</h2>
                </div>
                <div class="subheadline">
                    <p>Mach den ersten Schritt in Richtung eigenes Motorrad – mit Bike4Budget. Wähle Dein monatliches
                        Budget, die Laufzeit, Anzahlung oder Laufleistung und erhalte anschließend alle passenden
                        Fahrzeuge und Motorisierungen. Der Onlinerechner erleichtert Dir die Planung und lässt Dich
                        verschiedene Finanzierungen vergleichen. Ganz egal, ob Du finanzieren oder leasen möchtest: Wir
                        präsentieren Dir das optimale Finanzierungsangebot je Fahrzeug.</p>
                </div>
            </div>
            <div class="error">
                <div class="errorwrapper">
                    <v-layout row>
                        <div class="attentionimage">
                            <img src="/attention.svg" alt="attention image"/>
                        </div>
                        <v-flex xl10 lg10 md10 sm10 xs12 class="attentiontext">
                            <p class="font-weight-bold">Gerade gibt es leider technische Probleme bei Bike4Budget</p>
                            <br>
                            <p>Du willst Dir den Traum vom eigenen Bike erfüllen? Dann bist Du hier normalerweise
                                richtig. Gerade haben wir leider mit technischen Problemen zu kämpfen.
                                Nutze doch die Zeit und stöbere auf unserer Startseite durch die neuesten Modelle und
                                Angebote. Der Bike4Budget-Rechner sollte in Kürze wieder erreichbar sein.
                            </p>
                            <p>Wir würden uns freuen, Dich dann wieder auf unserer Website zu sehen.
                            </p>
                        </v-flex>
                    </v-layout>
                </div>
            </div>
        </div>
        <div v-if="settings && labels && bucketStats" class="main">
            <div v-if="labels.mainPage" class="header">
                <div class="headline heading"
                     :class="$mq">
                    <h1 class="heading" :class="$mq">
                        {{ labels.mainPage.mainPageTitle }}</h1>
                    <h2 class="heading" :class="$mq">
                        {{ labels.mainPage.mainPageSubTitle }}</h2>
                </div>
                <div class="subheadline">
                    <p>{{ labels.mainPage.mainPageSubText }}</p>
                </div>
            </div>
            <div v-if="labels.mainPage" class="productconfig">
                <v-layout row>
                    <v-flex xl4 lg4 md12 sm12 xs12 class="budgetCol">
                        <v-layout row>
                            <v-flex class="budget">
                                <h5>{{ labels.mainPage.budgetSelection }}</h5>
                            </v-flex>
                        </v-layout>
                        <v-layout row class="budgetRow">
                            <v-flex class="budget" :class="{'sliderMinChanged': sliderMinChanged, 'sliderMaxChanged': sliderMaxChanged}">
                                <Slider v-model="slider.range" :min="slider.min" :max="slider.max" :step="slider.step"
                                        :format="slider.format" @change="sliderEvent"/>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                    <v-flex xl7 lg8 md12 sm12 xs12 class="segmentCol">
                        <v-layout row>
                            <v-flex xl12 lg12 md12 sm12 xs12>
                                <h5>{{ labels.mainPage.segments }}</h5>
                            </v-flex>
                        </v-layout>
                        <v-layout row class="segmentRow">
                            <v-flex xl1 lg1 md1 sm12 xs12 class="segments">
                                <v-checkbox
                                        v-model="segmentSelection"
                                        :label="labels.mainPage.segmentDescription.SP"
                                        value="SP"
                                        class="segment-description"
                                        :ripple="false"
                                        off-icon="check_box_outline_blank"
                                        on-icon="check_box"
                                ></v-checkbox>
                            </v-flex>
                            <v-flex xl1 lg1 md1 sm12 xs12 class="segments">
                                <v-checkbox
                                        v-model="segmentSelection"
                                        :label="labels.mainPage.segmentDescription.LT"
                                        value="LT"
                                        class="segment-description"
                                        :ripple="false"
                                        off-icon="check_box_outline_blank"
                                        on-icon="check_box"
                                ></v-checkbox>
                            </v-flex>
                            <v-flex xl1 lg1 md1 sm12 xs12 class="segments">
                                <v-checkbox
                                        v-model="segmentSelection"
                                        :label="labels.mainPage.segmentDescription.NA"
                                        value="NA"
                                        class="segment-description"
                                        :ripple="false"
                                        off-icon="check_box_outline_blank"
                                        on-icon="check_box"
                                ></v-checkbox>
                            </v-flex>
                            <v-flex xl1 lg1 md1 sm12 xs12 class="segments">
                                <v-checkbox
                                        v-model="segmentSelection"
                                        :label="labels.mainPage.segmentDescription.HE"
                                        value="HE"
                                        class="segment-description"
                                        :ripple="false"
                                        off-icon="check_box_outline_blank"
                                        on-icon="check_box"
                                ></v-checkbox>
                            </v-flex>
                            <v-flex xl1 lg1 md1 sm12 xs12 class="segments">
                                <v-checkbox
                                        v-model="segmentSelection"
                                        :label="labels.mainPage.segmentDescription.AD"
                                        value="AD"
                                        class="segment-description"
                                        :ripple="false"
                                        off-icon="check_box_outline_blank"
                                        on-icon="check_box"
                                ></v-checkbox>
                            </v-flex>
                            <v-flex xl1 lg1 md1 sm12 xs12 class="segments">
                                <v-checkbox
                                        v-model="segmentSelection"
                                        :label="labels.mainPage.segmentDescription.MS"
                                        value="MS"
                                        class="segment-description"
                                        :ripple="false"
                                        off-icon="check_box_outline_blank"
                                        on-icon="check_box"
                                ></v-checkbox>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                </v-layout>
            </div>
            <div class="additionalfilters">
                <div v-if="labels.mainPage">
                    <v-expansion-panels flat accordion>
                        <v-expansion-panel id="additionalFilters" @click="changePanelOpenState">
                            <v-expansion-panel-header id="panelHeader" style="background-color: #f2f2f2"
                                                      :expand-icon="expansionIcon" disable-icon-rotate>
                                {{ labels.mainPage.additionalFilters.additionalFilters }}
                            </v-expansion-panel-header>
                            <v-expansion-panel-content style="background-color: #f2f2f2" :key="panelOpenState">
                                <v-layout row v-if="labels.mainPage.additionalFilters">
                                    <v-flex xl3 lg3 md3 sm3 xs12 class="filterparameters" :class="$mq"
                                            v-if="labels.mainPage.additionalFilters.term">
                                        <v-select :items="termValues"
                                                  item-text="label"
                                                  item-value="value"
                                                  :label="labels.mainPage.additionalFilters.term"
                                                  outlined
                                                  dense
                                                  background-color="#ffffff"
                                                  width="315px"
                                                  class="financeParameter"
                                                  append-icon="$expand"
                                                  v-model="filterParameters.trm"
                                        ></v-select>
                                    </v-flex>
                                    <v-flex xl3 lg3 md3 sm3 xs12 class="filterparameters" :class="$mq"
                                            v-if="labels.mainPage.additionalFilters.deposit">
                                        <v-select :items="depositValues"
                                                  item-text="label"
                                                  item-value="value"
                                                  :label="labels.mainPage.additionalFilters.deposit"
                                                  outlined
                                                  dense
                                                  color="#1C69D4"
                                                  background-color="#ffffff"
                                                  class="financeParameter"
                                                  append-icon="$expand"
                                                  v-model="filterParameters.dep"
                                        ></v-select>
                                    </v-flex>
                                    <v-flex xl3 lg3 md3 sm3 xs12 class="filterparameters" :class="$mq"
                                            v-if="labels.mainPage.additionalFilters.mileage">
                                        <v-select :items="mileageValues"
                                                  item-text="label"
                                                  item-value="value"
                                                  :label="labels.mainPage.additionalFilters.mileage"
                                                  outlined
                                                  dense
                                                  color="#1C69D4"
                                                  background-color="#ffffff"
                                                  class="financeParameter"
                                                  append-icon="$expand"
                                                  v-model="filterParameters.mil"
                                        ></v-select>
                                    </v-flex>
                                </v-layout>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>

                </div>
            </div>
            <div class="filterwrapper" v-if="labels.mainPage">
                <div class="reset-filters" @click="resetFilters" v-show="filtersChanged && loading == false">
                    ALLE FILTER ZURÜCKSETZEN
                </div>
                <div class="modelsfound" v-if="labels.mainPage.vehiclesList">
                    <img v-show="loading == true" src="/spinner.svg" class="spinner" alt="spinner image"/>
                    <h6 v-show="modelsFoundTotal == 0 && loading == false">{{ modelsFoundTotal }} {{
                        labels.mainPage.vehiclesList.modelsFoundPlural }}</h6>
                    <h6 v-show="modelsFoundTotal == 1 && loading == false">{{ modelsFoundTotal }} {{
                        labels.mainPage.vehiclesList.modelsFoundSingular }}</h6>
                    <h6 v-show="modelsFoundTotal > 1 && loading == false">{{ modelsFoundTotal }} {{
                        labels.mainPage.vehiclesList.modelsFoundPlural }}</h6>
                </div>
            </div>
            <div class="clear"></div>
            <div class="gridwrapper" v-if="labels.mainPage" :class="{'filtersreset': filtersChanged}">
                <v-layout v-if="$mq != 'xs'">
                    <div class="grid"
                         v-if="bucketStats.seriesOverview && labels.mainPage.segmentDescription">
                        <div v-if="bucketStats.seriesOverview.seriesS" class="grid-row"
                             v-show="segmentSelection.length == 0 || segmentSelection.includes('SP')">
                            <div class="segment">{{ labels.mainPage.segmentDescription.SP }}</div>
                            <MotoGridItem :modelStats=bucketStats.seriesOverview.seriesS.modelStatsOverview
                                          :series=bucketStats.seriesOverview.seriesS.series
                            ></MotoGridItem>
                        </div>
                        <div v-if="bucketStats.seriesOverview.seriesL" class="grid-row"
                             v-show="segmentSelection.length == 0 || segmentSelection.includes('LT')">
                            <div class="segment">{{ labels.mainPage.segmentDescription.LT }}</div>
                            <MotoGridItem :modelStats=bucketStats.seriesOverview.seriesL.modelStatsOverview
                                          :series=bucketStats.seriesOverview.seriesL.series
                            ></MotoGridItem>
                        </div>
                        <div v-if="bucketStats.seriesOverview.seriesN" class="grid-row"
                             v-show="segmentSelection.length == 0 || segmentSelection.includes('NA')">
                            <div class="segment">{{ labels.mainPage.segmentDescription.NA }}</div>
                            <MotoGridItem :modelStats=bucketStats.seriesOverview.seriesN.modelStatsOverview
                                          :series=bucketStats.seriesOverview.seriesN.series
                            ></MotoGridItem>
                        </div>
                        <div v-if="bucketStats.seriesOverview.seriesH" class="grid-row"
                             v-show="segmentSelection.length == 0 || segmentSelection.includes('HE')">
                            <div class="segment">{{ labels.mainPage.segmentDescription.HE }}</div>
                            <MotoGridItem :modelStats=bucketStats.seriesOverview.seriesH.modelStatsOverview
                                          :series=bucketStats.seriesOverview.seriesH.series
                            ></MotoGridItem>
                        </div>
                        <div v-if="bucketStats.seriesOverview.seriesA" class="grid-row"
                             v-show="segmentSelection.length == 0 || segmentSelection.includes('AD')">
                            <div class="segment">{{ labels.mainPage.segmentDescription.AD }}</div>
                            <MotoGridItem :modelStats=bucketStats.seriesOverview.seriesA.modelStatsOverview
                                          :series=bucketStats.seriesOverview.seriesA.series
                            ></MotoGridItem>
                        </div>
                        <div v-if="bucketStats.seriesOverview.seriesM" class="grid-row"
                             v-show="segmentSelection.length == 0 || segmentSelection.includes('MS')">
                            <div class="segment">{{ labels.mainPage.segmentDescription.MS }}</div>
                            <MotoGridItem :modelStats=bucketStats.seriesOverview.seriesM.modelStatsOverview
                                          :series=bucketStats.seriesOverview.seriesM.series
                            ></MotoGridItem>
                        </div>
                    </div>
                </v-layout>
                <v-layout v-if="$mq == 'xs'">
                    <v-expansion-panels accordion multiple v-if="bucketStats.seriesOverview"
                                        :key="filterSettingsChange">
                        <v-expansion-panel v-if="bucketStats.seriesOverview.seriesS"
                                           v-show="segmentSelection.length == 0 || segmentSelection.includes('SP')"
                                           @click="scrollIntoView('seriesS')">
                            <v-expansion-panel-header id="seriesS">
                                <template v-slot:actions>
                                    <v-icon class="expansion-icon">$expand</v-icon>
                                </template>
                                <span class="expansion-header">
                                    <span>{{ labels.mainPage.segmentDescription.SP }}</span>
                                </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <MotoGridItem :modelStats=bucketStats.seriesOverview.seriesS.modelStatsOverview
                                              :series=bucketStats.seriesOverview.seriesS.series
                                ></MotoGridItem>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel v-if="bucketStats.seriesOverview.seriesL"
                                           v-show="segmentSelection.length == 0 || segmentSelection.includes('LT')"
                                           @click="scrollIntoView('seriesL')">
                            <v-expansion-panel-header id="seriesL">
                                <template v-slot:actions>
                                    <v-icon class="expansion-icon">$expand</v-icon>
                                </template>
                                <span class="expansion-header">
                                    <span>{{ labels.mainPage.segmentDescription.LT }}</span>
                                </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <MotoGridItem :modelStats=bucketStats.seriesOverview.seriesL.modelStatsOverview
                                              :series=bucketStats.seriesOverview.seriesL.series
                                ></MotoGridItem>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel v-if="bucketStats.seriesOverview.seriesN"
                                           v-show="segmentSelection.length == 0 || segmentSelection.includes('NA')"
                                           @click="scrollIntoView('seriesN')">
                            <v-expansion-panel-header id="seriesN">
                                <template v-slot:actions>
                                    <v-icon class="expansion-icon">$expand</v-icon>
                                </template>
                                <span class="expansion-header">
                                    <span>{{ labels.mainPage.segmentDescription.NA }}</span>
                                </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <MotoGridItem :modelStats=bucketStats.seriesOverview.seriesN.modelStatsOverview
                                              :series=bucketStats.seriesOverview.seriesN.series
                                ></MotoGridItem>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel v-if="bucketStats.seriesOverview.seriesH"
                                           v-show="segmentSelection.length == 0 || segmentSelection.includes('HE')"
                                           @click="scrollIntoView('seriesH')">
                            <v-expansion-panel-header id="seriesH">
                                <template v-slot:actions>
                                    <v-icon class="expansion-icon">$expand</v-icon>
                                </template>
                                <span class="expansion-header">
                                    <span>{{ labels.mainPage.segmentDescription.HE }}</span>
                                </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <MotoGridItem :modelStats=bucketStats.seriesOverview.seriesH.modelStatsOverview
                                              :series=bucketStats.seriesOverview.seriesH.series
                                ></MotoGridItem>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel v-if="bucketStats.seriesOverview.seriesA"
                                           v-show="segmentSelection.length == 0 || segmentSelection.includes('AD')"
                                           @click="scrollIntoView('seriesA')">
                            <v-expansion-panel-header id="seriesA">
                                <template v-slot:actions>
                                    <v-icon class="expansion-icon">$expand</v-icon>
                                </template>
                                <span class="expansion-header">
                                    <span>{{ labels.mainPage.segmentDescription.AD }}</span>
                                </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <MotoGridItem :modelStats=bucketStats.seriesOverview.seriesA.modelStatsOverview
                                              :series=bucketStats.seriesOverview.seriesA.series
                                ></MotoGridItem>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel v-if="bucketStats.seriesOverview.seriesM"
                                           v-show="segmentSelection.length == 0 || segmentSelection.includes('MS')"
                                           @click="scrollIntoView('seriesM')">
                            <v-expansion-panel-header id="seriesM">
                                <template v-slot:actions>
                                    <v-icon class="expansion-icon">$expand</v-icon>
                                </template>
                                <span class="expansion-header">
                                    <span>{{ labels.mainPage.segmentDescription.MS }}</span>
                                </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <MotoGridItem :modelStats=bucketStats.seriesOverview.seriesM.modelStatsOverview
                                              :series=bucketStats.seriesOverview.seriesM.series
                                ></MotoGridItem>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-layout>
            </div>
        </div>
    </div>
</template>

<script>
    import MotoGridItem from "./MotoGridItem";
    import TrackingHandler from "../helpers/TrackingHandler";
    import Slider from '@vueform/slider/dist/slider.vue2.js';
    import '@vueform/slider/themes/default.css';

    export default {
        name: 'MotoMainPage',
        components: {
            MotoGridItem,
            Slider,
        },
        props: {
            motoRateMin: '',
            motoRateMax: '',
            motoRateRange: '',
            trm: '',
            dep: '',
            mil: '',
            segments: null,
            isReturn: ''
        },
        data() {
            return {
                returnFromDetails: this.isReturn,
                filterParameters: {
                    'trm': this.trm ? this.trm : 24,
                    'dep': this.dep ? this.dep : 0,
                    'mil': this.mil ? this.mil : 4000
                },
                slider: {
                    min: this.motoRateMin,
                    max: this.motoRateMax,
                    range: [this.motoRateRange[0], this.motoRateRange[1]],
                    format: {
                        suffix: ' €',
                        decimals: 0
                    },
                    step: 100
                },
                segmentSelection: this.segments ? this.segments : [],
                filterSettingsChange: false,
                panelOpenState: false,
                sliderMinChanged: false,
                sliderMaxChanged: false,
            }
        },
        computed: {
            expansionIcon() {
                if (this.panelOpenState == true) {
                    return 'remove'
                } else {
                    return 'add'
                }
            },
            fallbackMessages() {
                if (this.parameters.brand == 'bmw' && this.parameters.market == 'de') {
                    let fallbackMessages = {
                        "title": "BMW MODELLE ZU IHREM BUDGET.",
                        "subtitle": "Hier ist etwas schiefgegangen!!",
                        "text": "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet."
                    }
                    return fallbackMessages
                }
                if (this.parameters.brand == 'bmw' && this.parameters.market == 'ca') {
                    if (this.parameters.language == 'en') {
                        let fallbackMessages = {
                            "title": "YOUR BMW, YOUR BUDGET.",
                            "subtitle": "This service is not available at the moment.",
                            "text": "BMW Financial Services offers you a choice of car finance options to help you get behind the wheel of a new or used BMW. At every BMW Centre, you'll find a BMW Financial Services specialist who can talk you through the options we can provide. These will depend on whether you want the option to own the car at the end of the agreement or just lease it. The difference will be explained to you to help inform your decision. Once you've chosen the finance package that suits you and your budget, we'll do the rest. From paperwork and phone calls to delivery arrangements, we'll handle all the details and do everything to get you on the road as quickly and easily as possible."
                        }
                        return fallbackMessages
                    }
                    if (this.parameters.language == 'fr') {
                        let fallbackMessages = {
                            "title": "VOTRE BMW. VOTRE BUDGET.",
                            "subtitle": "Ce service n'est pas disponible pour le moment.",
                            "text": "Les services financiers BMW vous offrent un choix d'options de financement et de location afin de vous aider à prendre le volant d'une BMW neuve ou d'occasion. Chez chaque concessionnaire BMW, vous trouverez un spécialiste des services financiers BMW qui saura vous expliquer les différentes options de financement et de location disponibles. Ces options dépendent de votre choix de posséder la voiture à la fin du contrat ou d’en profiter pour une période donnée. La différence vous sera expliquée afin que vous preniez une décision éclairée. Nous pouvons également vous aider à compléter votre Ultimate Driving Experience® avec notre gamme de produits de protection des Services financiers BMW vous offrant une tranquillité d'esprit additionnelle. Une fois que vous avez choisi le type de financement offert par les Services financiers BMW qui vous convient et qui convient à votre budget, nous nous occuperons du reste. Des formalités administratives aux appels téléphoniques en passant par les arrangements de livraison, nous nous occuperons de tous les détails et ferons tout pour vous mettre sur la route le plus rapidement et le plus facilement possible. Vous vous demandez quelle option de financement vous convient le mieux? Répondez à quelques brèves questions sur l’expérience que vous recherchez en tant que propriétaire d’un véhicule BMW et nous vous proposerons diverses options spécialement conçues pour répondre à vos besoins. <a href=\"https://customerneeds.bmwgroup.com/can/frontend/index.html?market=ca&lang=fr&brand=bmw#\">En savoir plus.</a>"
                        }
                        return fallbackMessages
                    }
                }
                if (this.parameters.brand == 'bmwBike' && this.parameters.market == 'de') {
                    let fallbackMessages = {
                        "title": "BMW MODELLE ZU IHREM BUDGET.",
                        "subtitle": "Hier ist etwas schiefgegangen!!",
                        "text": "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet."
                    }
                    return fallbackMessages
                }
            },
            loading() {
                return this.$store.getters.filterSettings.loading
            },
            isSelected(segment) {
                return true
            },
            parameters() {
                return this.$store.state.parameters
            },
            labels() {
                return this.$store.state.labels
            },
            settings() {
                return this.$store.state.settings
            },
            filterSettings() {
                return this.$store.state.filterSettings
            },
            bucketStats() {
                return this.$store.state.bucketStats
            },
            termValuesPlain() {
                if (this.$store.state.settings.filter) {
                    return this.$store.state.settings.filter.term.values
                }
            },
            depositValuesPlain() {
                if (this.$store.state.settings.filter) {
                    return this.$store.state.settings.filter.deposit.values
                }
            },
            mileageValuesPlain() {
                if (this.$store.state.settings.filter) {
                    return this.$store.state.settings.filter.mileage.values
                }
            },
            termValues() {
                if (this.termValuesPlain) {
                    let termValues = []
                    let terms = this.termValuesPlain
                    for (const term of terms
                        ) {
                        termValues.push({
                            'label': this.formatValue(term, 'term', this.parameters.language, this.parameters.market),
                            'value': term
                        })
                    }
                    return termValues
                }
            },
            depositValues() {
                if (this.depositValuesPlain) {
                    let depositValues = []
                    let deposits = this.depositValuesPlain
                    for (const deposit of deposits
                        ) {
                        if (deposit == 0) {
                            let deposit10 = this.formatValue(10, 'currencyInteger', this.parameters.language, this.parameters.market)
                            let depositStripped = deposit10.replace('10', '0')
                            depositValues.push({
                                'label': depositStripped,
                                'value': deposit
                            })
                        } else {
                            depositValues.push({
                                'label': this.formatValue(deposit, 'currencyInteger', this.parameters.language, this.parameters.market),
                                'value': deposit
                            })
                        }
                    }
                    return depositValues
                }
            },
            mileageValues() {
                if (this.mileageValuesPlain) {
                    let mileageValues = []
                    let mileages = this.mileageValuesPlain
                    for (const mileage of mileages
                        ) {
                        mileageValues.push({
                            'label': this.formatValue(mileage, 'mileage', this.parameters.language, this.parameters.market),
                            'value': mileage
                        })
                    }
                    return mileageValues
                }
            },
            filtersChanged() {
                if (this.filterParameters.trm == 24 && this.filterParameters.dep == 0 && this.filterParameters.mil == 4000 && this.slider.range[0] == 0 && this.slider.range[1] == 600 && this.segmentSelection.length == 0) {
                    return false
                } else {
                    return true
                }
            },
            modelsFoundSP() {
                if (this.bucketStats.seriesOverview) {
                    if (this.bucketStats.seriesOverview.seriesS) {
                        return this.bucketStats.seriesOverview.seriesS.modelsFoundPerSeries
                    } else return 0
                } else return 0
            },
            modelsFoundLT() {
                if (this.bucketStats.seriesOverview) {
                    if (this.bucketStats.seriesOverview.seriesL) {
                        return this.bucketStats.seriesOverview.seriesL.modelsFoundPerSeries
                    } else return 0
                } else return 0
            },
            modelsFoundNA() {
                if (this.bucketStats.seriesOverview) {
                    if (this.bucketStats.seriesOverview.seriesN) {
                        return this.bucketStats.seriesOverview.seriesN.modelsFoundPerSeries
                    } else return 0
                } else return 0
            },
            modelsFoundHE() {
                if (this.bucketStats.seriesOverview) {
                    if (this.bucketStats.seriesOverview.seriesH) {
                        return this.bucketStats.seriesOverview.seriesH.modelsFoundPerSeries
                    } else return 0
                } else return 0
            },
            modelsFoundAD() {
                if (this.bucketStats.seriesOverview) {
                    if (this.bucketStats.seriesOverview.seriesA) {
                        return this.bucketStats.seriesOverview.seriesA.modelsFoundPerSeries
                    } else return 0
                } else return 0
            },
            modelsFoundMS() {
                if (this.bucketStats.seriesOverview) {
                    if (this.bucketStats.seriesOverview.seriesM) {
                        return this.bucketStats.seriesOverview.seriesM.modelsFoundPerSeries
                    } else return 0
                } else return 0
            },
            modelsFoundTotal() {
                if (this.bucketStats != null) {
                    let modelsFound = 0
                    if (this.segmentSelection.includes('SP') || this.segmentSelection.length == 0) {
                        modelsFound = modelsFound + this.modelsFoundSP
                    }
                    if (this.segmentSelection.includes('LT') || this.segmentSelection.length == 0) {
                        modelsFound = modelsFound + this.modelsFoundLT
                    }
                    if (this.segmentSelection.includes('NA') || this.segmentSelection.length == 0) {
                        modelsFound = modelsFound + this.modelsFoundNA
                    }
                    if (this.segmentSelection.includes('HE') || this.segmentSelection.length == 0) {
                        modelsFound = modelsFound + this.modelsFoundHE
                    }
                    if (this.segmentSelection.includes('AD') || this.segmentSelection.length == 0) {
                        modelsFound = modelsFound + this.modelsFoundAD
                    }
                    if (this.segmentSelection.includes('MS') || this.segmentSelection.length == 0) {
                        modelsFound = modelsFound + this.modelsFoundMS
                    }
                    return modelsFound
                } else {
                    return 0
                }
            },
        },
        beforeMount() {
        },

        created() {
            if (this.returnFromDetails == true) {
                this.$store.commit('setLoading', true)
                this.updateOfferParameters()
            }
        },
        methods: {
            async updateRates(minMaxState) {
                let baseUrl = this.$store.getters.parameters.baseUrl
                let market = this.$store.getters.parameters.market
                let brand = this.$store.getters.parameters.brand
                let channel = this.$store.getters.parameters.channel
                let language = this.$store.getters.parameters.language
                let baseProduct = this.$store.getters.filterSettings.baseProduct
                let trm = this.$store.getters.filterSettings.trm
                let dep = this.$store.getters.filterSettings.dep
                let mil = this.$store.getters.filterSettings.mil
                let min = this.$store.getters.filterSettings.min
                if (this.$store.getters.filterSettings.min != this.slider.range[0]) {
                    if (this.$store.getters.filterSettings.initialRange[0] == this.slider.range[0]) {
                        this.sliderMinChanged = false
                    }
                    else {
                        this.sliderMinChanged = true
                    }
                    min = this.slider.range[0]
                    this.$store.commit('setMinInstallment', min)
                    TrackingHandler.sendSelectBudgetEvent(Date.now(), 'min', this.$store);
                }
                let max = this.$store.getters.filterSettings.max
                if (this.$store.getters.filterSettings.max != this.slider.range[1]) {
                    // if (this.$store.getters.filterSettings.initialRange[1] == this.slider.range[1]) {
                    if (600 == this.slider.range[1]) {
                        this.sliderMaxChanged = false
                    }
                    else {
                        this.sliderMaxChanged = true
                    }
                    max = this.slider.range[1]
                    this.$store.commit('setMaxInstallment', max)
                    TrackingHandler.sendSelectBudgetEvent(Date.now(), 'max', this.$store);
                }
                let filterUrl = baseUrl + '/filter/dc/market/' + market.toLowerCase() + '/brand/' + brand.toLowerCase() + '/channel/' + channel + '/language/' + language + '/trm/' + trm + '/mil/' + mil + '/dep/' + dep + '/min/' + min + '/max/' + max
                this.$store.commit('setFilterUrl', filterUrl)
                let bucketUrl = baseUrl + '/filter/models/market/' + market.toLowerCase() + '/brand/' + brand.toLowerCase() + '/channel/' + channel + '/language/' + language + '/trm/' + trm + '/mil/' + mil + '/dep/' + dep + '/min/' + min + '/max/' + max
                this.$store.commit('setBucketUrl', bucketUrl)
                await this.$store.dispatch('fetchBucketStats', bucketUrl)
                this.$store.commit('setLoading', false)
            },
            async updateOfferParameters() {
                let baseUrl = this.$store.getters.parameters.baseUrl
                let market = this.$store.getters.parameters.market
                let brand = this.$store.getters.parameters.brand
                let channel = this.$store.getters.parameters.channel
                let language = this.$store.getters.parameters.language
                let baseProduct = this.$store.getters.filterSettings.baseProduct
                let trm = this.filterParameters.trm
                this.$store.commit('setTerm', trm)
                let dep = this.filterParameters.dep
                this.$store.commit('setDeposit', dep)
                let mil = this.filterParameters.mil
                this.$store.commit('setMileage', mil)
                let min = this.$store.getters.filterSettings.min
                let max = this.$store.getters.filterSettings.max
                let filterUrl = baseUrl + '/filter/dc/market/' + market.toLowerCase() + '/brand/' + brand.toLowerCase() + '/channel/' + channel + '/language/' + language + '/trm/' + trm + '/mil/' + mil + '/dep/' + dep + '/min/' + min + '/max/' + max
                this.$store.commit('setFilterUrl', filterUrl)
                let bucketUrl = bucketUrl = baseUrl + '/filter/models/market/' + market.toLowerCase() + '/brand/' + brand.toLowerCase() + '/channel/' + channel + '/language/' + language + '/trm/' + trm + '/mil/' + mil + '/dep/' + dep + '/min/' + min + '/max/' + max
                this.$store.commit('setBucketUrl', bucketUrl)
                await this.$store.dispatch('fetchBucketStats', bucketUrl)
                this.$store.commit('setLoading', false)
            },
            scrollIntoView(segment) {
                let element = document.getElementById(segment);
                element.scrollIntoView();
            },
            sliderEvent() {
                this.$store.commit('setLoading', true)
                this.updateRates('range')
            },
            changePanelOpenState() {
                this.panelOpenState = !this.panelOpenState
            },
            async resetFilters() {
                if (this.filterParameters.trm == 24 && this.filterParameters.dep == 0 && this.filterParameters.mil == 4000 && this.slider.range[0] == 0 && this.slider.range[1] == 600 && this.segmentSelection.length > 0) {
                    this.segmentSelection = []
                    this.filterSettingsChange = !this.filterSettingsChange
                } else {
                    this.filterParameters.trm = 24
                    this.filterParameters.dep = 0
                    this.filterParameters.mil = 4000
                    this.slider.range = [0, 600]
                    this.segmentSelection = []
                    this.$store.commit('setLoading', true)
                    this.filterSettingsChange = !this.filterSettingsChange
                    this.sliderMinChanged = false
                    this.sliderMaxChanged = false
                }

            },
            formatValue: function (value, type, language, market) {
                if (!value) {
                    return ''
                }
                let locale = language + '-' + market.toUpperCase()
                let currency = (function () {
                    switch (locale) {
                        case 'de-DE':
                            return 'EUR'
                        case 'en-CA':
                            return 'CAD'
                        case 'fr-CA':
                            return 'CAD'
                    }
                })('EUR')
                if (type == 'currency') {
                    return new Intl.NumberFormat(locale, {style: 'currency', currency: currency}).format(value)
                }
                if (type == 'currencyInteger') {
                    return new Intl.NumberFormat(locale, {
                        style: 'currency',
                        currency: currency,
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                    }).format(value)
                }
                if (type == 'mileage') {
                    try {
                        return new Intl.NumberFormat(locale, {
                            style: 'unit',
                            unit: 'kilometer',
                        }).format(value)
                    } catch (e) {
                        console.log('Error: ' + e)
                        if (market == 'de' && language == 'de') {
                            return value + ' ' + this.labels.financeParameterFormat.mileage
                        } else {
                            return value
                        }
                    }
                }
                if (type == 'term') {
                    try {
                        return new Intl.NumberFormat(locale, {
                            style: 'unit',
                            unit: 'month',
                        }).format(value)
                    } catch (e) {
                        console.log('Error: ' + e)
                        if (market == 'de' && language == 'de') {
                            return value + ' ' + this.labels.financeParameterFormat.term
                        } else {
                            return value
                        }
                    }
                }
            },
        },
        watch: {
            filterParameters: {
                deep: true,
                handler() {
                    this.$store.commit('setLoading', true)
                    this.updateOfferParameters()
                    this.filterSettingsChange = !this.filterSettingsChange
                }
            },
            segmentSelection: {
                deep: true,
                handler() {
                    this.$store.commit('setSegments', this.segmentSelection)
                }
            },
        }
    }
</script>

<style scoped>
    #motoMainPage {
        font-family: BMWMotorrad;
    }

    h1.heading.xl {
        font-family: BMWMotorrad;
        font-size: 80px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        color: #16171a;
    }

    h1.heading.lg {
        font-family: BMWMotorrad;
        font-size: 80px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        color: #16171a;
    }

    h1.heading.md {
        font-family: BMWMotorrad;
        font-size: 60px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        color: #16171a;
    }

    h1.heading.sm {
        font-family: BMWMotorrad;
        font-size: 40px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        color: #16171a;
    }

    h1.heading.xs {
        font-family: BMWMotorrad;
        font-size: 28px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        color: #16171a;
    }

    h2.heading.xl {
        font-family: BMWMotorrad;
        font-size: 32px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.13;
        letter-spacing: normal;
        color: #16171a;
    }

    h2.heading.lg {
        font-family: BMWMotorrad;
        font-size: 32px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.13;
        letter-spacing: normal;
        color: #16171a;
    }

    h2.heading.md {
        font-family: BMWMotorrad;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.13;
        letter-spacing: normal;
        color: #16171a;
    }

    h2.heading.sm {
        font-family: BMWMotorrad;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.13;
        letter-spacing: normal;
        color: #16171a;
    }

    h2.heading.xs {
        font-family: BMWMotorrad;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.13;
        letter-spacing: normal;
        color: #16171a;
    }

    h5 {
        font-family: BMWMotorrad;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #16171a;
    }

    h6 {
        font-family: BMWMotorrad;
        font-size: 13px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
    }

    p {
        font-family: BMWMotorrad;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #16171a;
    }

    #default {
        max-width: 1600px;
    }

    .warning-box {
        background-color: #ffe6bb;
        min-height: 45px;
        max-width: 800px;
        padding: 10px 15px;
        word-break: break-word;
        margin-bottom: 25px;
        display: flex;
        align-items: center;
    }

    .warning-icon {
        height: 24px;
        width: 24px;
        fill: #ffad1f;
        justify-content: center;
        stroke: none;
        vertical-align: middle;
        margin-right: 15px;
        display: flex;
        align-items: center;
    }

    .warning-message {
        min-height: 24px;
        text-align: left;
        display: flex;
        align-items: center;
        margin-bottom: 0;
    }

    .installment {
        display: block;
        /*max-width: 446px;
        min-width: 446px;*/
        flex-basis: initial;
        flex-grow: unset;
        flex-shrink: unset;
        margin-right: 10px !important;
    }

    #mobile .installment {
        position: center;
        float: left;
    }

    /*.product {
        margin-right: 15px;
        margin-top: -10px;
    }*/

    .installmentSelection {
        margin-left: 0px;
    }

    .info-icon {
        width: 12px;
        height: auto;
    }

    .errormessage {
        background-color: #ffe6eb;
    }

    .disclaimer {
        font-size: 12px;
        letter-spacing: 0.16px;
        margin-top: 20px;
        margin-bottom: 20px;
        text-align: justify;
        max-width: 1000px;
    }

    #default .disclaimer {
        padding-left: 100px;
    }

    .budgetParameter {
        min-width: 140px;
    }

    #fab {
        background-color: #1C69D4;
        padding-left: 15px;
        padding-right: 15px;
        width: unset;
    }

    .sub-header {
        font-weight: bold;
        margin-top: 20px;
    }

    .fallback {
        margin-left: 10px;
    }

    /*.headerimage {
        background-image: url(/header_bmwBike.jpg);
        background-repeat: no-repeat;
        width: 1920px;
        height: 1080px;
        margin: 0 auto;
    }*/

    .headerimage {
        background-image: url(/header_bmwBike.jpg);
        background-repeat: no-repeat;
        background-size: contain;
        width: 100vw;
        max-width: 100%;
        height: 0;
        padding-top: 28.65%;
    }

    #mobile .headerimage {
        margin-top: 40px;
    }

    .attentionimage {
        width: 50px;
        height: 50px;
        margin: 0px auto;
    }

    #default .attentiontext {
        margin-left: 20px;
    }

    #mobile .attentiontext {
        text-align: center;
        margin-top: 40px;
    }

    /*#mobile .headerimage {
        background-image: url(/header_bmwBike_small.jpg);
        width: 100%;
        height: 0;
        padding-top: 28.65%;
    }*/

    .bmwBike {
        max-width: 1600px;
    }

    .main {
        margin-top: 20px;
        margin: auto;
        max-width: 1600px;
    }

    .header {
        max-width: 1200px;
        margin: 0px auto 80px;
        padding: 0px 30px;
    }

    .headline.heading.xl {
        max-width: 980px;
        margin: 80px 0px 40px;
    }

    .headline.heading.lg {
        max-width: 980px;
        margin: 80px 0px 40px;
    }

    .headline.heading.md {
        max-width: 980px;
        margin: 60px 0px 40px;
    }

    .headline.heading.sm {
        max-width: 980px;
        margin: 40px 0px 40px;
    }

    .headline.heading.xs {
        max-width: 980px;
        margin: 40px 0px 40px;
    }

    .subheadline {
        font-family: BMWMotorrad;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
    }

    .productconfig {
        max-width: 1600px;
        padding: 0px 30px;
    }

    .productconfig .row {
        margin-left: 0px;
        margin-right: 0px;
    }

    #additionalFilters {
        margin-bottom: 10px;
        margin-top: 30px;
    }

    .expansion-panel__container {
        background-color: #f2f2f2 !important;
    }

    .gridwrapper {
        padding: 10px 30px 60px;
        margin-bottom: 120px;
        background-color: #e6e6e6;
        /*min-height: 500px;*/
    }

    #mobile .gridwrapper {
        padding: 0px 0px 40px !important;
    }

    .gridwrapper /deep/ .v-expansion-panels {
        /*padding: 0px 25px 0px !important;*/
        background-color: white;
    }

    .segment {
        font-family: BMWMotorrad;
        font-size: 32px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.13;
        letter-spacing: normal;
        color: #16171a;
        margin-top: 30px;
    }

    .filterparameters {
        margin-right: 15px;
        /*margin-top: 15px;*/
        min-width: 155px;
    }

    .filterparameters /deep/ .v-input__slot {
        min-height: 40px !important;
    }

    .filterparameters.xs /deep/ .v-input__slot, .filterparameters.sm /deep/ .v-input__slot {
        min-height: 50px !important;
    }

    .filterwrapper {
        height: 26px;
        margin-bottom: 25px;
        padding: 0px 30px;
        float: right;
    }

    #mobile .filterwrapper {
        margin-bottom: 0px;
        width: 100%;
    }

    #mobile .filterwrapper:after, #mobile .reset-filters:after, #mobile .modelsfound:after {
        clear: both;
        display: block;
    }

    .filterwrapper .row {
        margin: 0px;
    }

    .clear {
        clear: both;
        display: block;
        height: 46px;
    }

    .segments {
        min-width: 140px;
        height: 50px;
    }

    #default.budget {
        min-width: 500px;
        margin-bottom: 10px;
    }

    #mobile.budget {
        min-width: 320px;
        margin-bottom: 10px;
    }

    .budgetRow {
        margin-bottom: 40px;
    }

    .v-input.v-text-field {
        margin-top: 0px;
        padding-top: 0px;
        text-align: end;
    }

    .v-input__slot::before {
        border-width: 0px !important;
        border-color: unset !important;
        border-style: none !important;
        border-top-color: unset !important;
        border-top-style: none !important;
        border-top-width: 0px !important;
        border: none !important;
        text-decoration-style: unset !important;
        text-decoration-color: unset !important;
        display: none !important;
    }

    .v-input__slot:after {
        border-color: unset !important;
        border-style: unset !important;
        border-width: unset !important;
        border: none !important;
    }

    input[type="number" i] {
        border-color: unset !important;
        border-style: unset !important;
        border-width: unset !important;
        border: none !important;
    }

    #slider.v-slider--horizontal {
        margin-right: 15px !important;
    }

    .budgetCol {
        padding-right: 30px;
    }

    .productconfig .row {
        justify-content: space-between;
    }

    #panelHeader {
        font-size: unset;
        font-weight: unset;
        text-transform: unset;
        padding: unset;
        height: unset;
        width: unset;
    }

    .v-expansion-panel-header {
        width: 100% !important;
        display: block;
        border-radius: 0px 0px 0px 0px !important;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        line-height: unset !important;
        padding: 11px 25px 11px !important;
        background-color: white;
    }

    .gridwrapper.v-expansion-panel-header {
        padding: 20px 25px 20px !important;
        height: 65px !important;
        font-size: 16px !important;
    }

    .v-expansion-panel::before {
        box-shadow: none !important;
    }

    .v-expansion-panel {
        background-color: #f2f2f2 !important;
    }

    .gridwrapper .v-expansion-panel {
        background-color: unset !important;
    }

    .additionalfilters {
        padding-left: 30px;
        padding-right: 30px;
    }

    #mobile .additionalfilters {
        margin-bottom: 10px;
    }

    button.v-expansion-panel-header {
        margin-bottom: 0px !important;
    }

    .segment-description:hover {
        color: transparent;
    }

    .v-input__slot {
        transition: unset !important;
    }

    .segment-description /deep/ label {
        color: #16171a;
    }

    .segment-description /deep/ i {
        color: #16171a !important;
    }

    .segment-description /deep/ .mdi-checkbox-marked {
        color: #0062ff !important;
    }

    segment-description /deep/ .v-icon {
        font-family: 'Material Icons Sharp' !important;
        font-size: 30px;
    }

    .financeParameter /deep/ fieldset {
        border: solid 2px #16171a;
        border-radius: 0;
    }

    #motoMainPage /deep/ .v-menu__content {
        border-radius: 0 !important;
    }

    #motoMainPage /deep/ .v-card:not(.v-sheet--tile):not(.v-card--shaped) {
        border-radius: 0 !important;
    }

    #motoMainPage /deep/ .v-list {
        padding: 0px;
    }

    .gridwrapper /deep/ .v-expansion-panels {
        border-radius: 0px;
    }

    .gridwrapper /deep/ i {
        color: #16171a !important;
    }

    .gridwrapper .v-expansion-panel:not(:first-child)::after {
        border-top: 2px solid #e6e6e6 !important;
        margin-left: 25px;
        margin-right: 25px;
    }

    .gridwrapper .v-expansion-panel--active + .v-expansion-panel::after {
        border-top: none !important;
    }

    .gridwrapper .expansion-header {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #000000;
    }

    #mobile .gridwrapper {
        padding-bottom: 0px !important;
    }

    #mobile .gridwrapper.filtersreset {
        margin-top: 46px;
    }

    .gridwrapper .v-expansion-panel--active /deep/ .vehicle-element {
        margin-right: 0px !important;
        margin-bottom: 0px !important;
    }

    .gridwrapper .v-expansion-panel--active /deep/ .v-expansion-panel-content__wrap {
        padding: 0px !important;
        /*margin-right: -30px;*/
    }

    .gridwrapper .v-expansion-panel-content {
        margin-top: 30px;
    }

    .gridwrapper .v-expansion-panel--active /deep/ .tile {
        margin-bottom: 20px;
    }

    .gridwrapper .v-expansion-panel--active /deep/ .headerimg {
        margin-top: 0px;
    }

    .gridwrapper .v-expansion-panel--active {
        /*padding-bottom: 20px !important;*/
        padding: 0px 25px 20px !important;
        background-color: #e6e6e6 !important;
    }

    .gridwrapper .v-expansion-panel-header--active {
        background-color: #e6e6e6;
        border-bottom: 2px solid #16171a;
    }

    .fallback /deep/ .header {
        border-bottom: 2px solid black;
        margin-bottom: 0px;
        padding-bottom: 80px;
    }

    .error {
        margin: 80px auto 60px;
        padding: 0px 30px;
    }

    .error /deep/ .row {
        max-width: 600px;
        margin: 0 auto;
    }

    .font-weight-bold {
        font-weight: bold;
    }

    .errorwrapper {
        margin: 0 auto;
    }

    .segmentRow {
        min-width: 840px;
        margin-top: 2px;
    }

    #slider {
        margin-top: 35px;
        padding-left: 8px;
    }

    #slider /deep/ .slider-connect {
        background-color: #16171a;
    }

    #slider /deep/ .slider-base {
        height: 2px;
        background-color: #f2f2f2;
    }

    #slider /deep/ .slider-handle {
        box-shadow: none;
        border: 2px solid #16171a;
    }

    #slider /deep/ .slider-tooltip {
        background-color: unset;
        color: #16171a;
        font-size: 12px;
        border: none;
        bottom: 15px;
    }

    #slider /deep/ .slider-tooltip::before {
        border: none;
    }

    .spinner {
        width: 30px;
        height: 30px;
        margin-left: -45px;
        margin-top: -5px;
    }

    .reset-filters {
        float: left;
        font-size: 14px;
        margin-right: 40px;
        margin-top: 3px;
    }

    #mobile .reset-filters {
        margin-right: 25px;
        margin-bottom: 20px;
        padding-left: 25px;
    }

    .reset-filters:hover {
        color: #0062ff;
        cursor: pointer;
    }

    .modelsfound {
        float: left;
        min-width: 85px;
        height: 26px;
        background-color: #16171a;
        padding: 3px 12px 3px 11px;
        border-radius: 5px;
    }

    #mobile .modelsfound {
        display: inline-block;
        float: right;
    }

    .additionalfilters /deep/ .v-expansion-panel-content__wrap .row {
        margin: 0px;
    }

    .expansion-header:hover ~ .v-expansion-panel-header__icon i {
        color: black;
        font-size: 30px;
    }

    .expansion-header:hover {
        font-weight: bold;
        color: black;
    }

    .additionalfilters /deep/ .v-icon {
        color: black !important;
    }

    .grid {
        display: inline-flex;
        flex-wrap: wrap;
        margin-right: -16px;
        width: calc(100% + 16px);
    }

    .grid .grid-row {
        width: 100% !important;
    }

    .sliderMinChanged /deep/ .slider-handle-lower {
        background-color: black !important;
    }

    .sliderMaxChanged /deep/ .slider-handle-upper {
        background-color: black !important;
    }

    .sliderMinChanged /deep/ .slider-base, .sliderMaxChanged /deep/ .slider-base {
        height: 3px !important;
    }

    .segment-description /deep/ .v-icon {
        font-family: 'Material Icons Sharp' !important;
    }

    .segment-description /deep/ .v-label {
        padding-top: 5px;
    }



</style>
