/**
 * Handles the creation and sending of tracking messages to Adobe Analytics via parent page of the iFrame.
 */

// ------------------------------ imports & configurations  ---------------------------------------
'use strict';

// ------------------------------ variables -------------------------------------------------------
/**
 * version: General version of of the tagging plan.
 */
const GENERAL_VERSION = '3CT_20210302';

// ------------------------------ messages --------------------------------------------------------

// ------------------------------ functions -------------------------------------------------------

/**
 * Returns name of the finance product identified by the productId.
 *
 * @param producId
 * @param financeProducts
 */
const getFinanceProductName = function (productId, financeProducts) {
    if (productId && financeProducts) {
        return financeProducts['product' + productId].productName;
    }
    return undefined;
}

/**
 * Returns category of the finance product identified by the productId.
 *
 * @param producId
 * @param financeProducts
 */
const getFinanceCategory = function (productId, financeProducts) {
    if (productId && financeProducts) {
        return financeProducts['product' + productId].productBaseType;
    }
    return undefined;
}

// 'component' parts: --------------------------------------------------------------------------------------------------

/**
 * component: General component for ACS, which can be used for all messages.
 *
 * @param brand
 *
 * @returns {[{componentInfo: {componentID: string, componentName: string}, category: {componentType: string, subCategory1: string, primaryCategory: string}}]}
 */
const getGeneral_COMPONENT = function (brand) {
    return [
        {
            componentInfo: {
                componentID: brand !== 'bmwBike' ? 'fin > finance > acs central' : 'fin > finance > acs motorcycle',
                componentName: brand !== 'bmwBike' ? 'acs central' : 'acs motorcycle'
            },
            category: {
                componentType: 'iframed in-page',
                primaryCategory: 'finance',
                subCategory1: 'offers'
            }
        }
    ]
};

// 'event' parts: ------------------------------------------------------------------------------------------------------

/**
 * event: Event to be used after ACS had been loaded.
 *
 * @param timestamp
 * @param brand
 *
 * @returns {[{eventInfo: {timeStamp, eventPoints: string, effect: (string), eventName: string, cause: string, type: string, eventAction: string}}]}
 */
const getAcsLoaded_EVENT = function (timestamp, brand) {
    return [
        {
            eventInfo: {
                eventName: 'interaction',
                eventAction: 'component load',
                eventPoints: 'na',
                type: 'automatic',
                timeStamp: timestamp,
                cause: 'automatic',
                effect: brand !== 'bmwBike' ? 'fin > acs central > loaded' : 'fin > acs motorcycle > loaded'
            }
        }
    ]
};

/**
 * event: Event to be used in case the user is changing the rates.
 *
 * @param timestamp
 * @param minMaxState
 * @param brand
 *
 * @returns {[{eventInfo: {timeStamp, eventPoints: string, effect: string, eventName: string, cause: string, type: string, eventAction: string}}]}
 */
const getSelectBudget_EVENT = function (timestamp, minMaxState, brand) {
    return [
        {
            eventInfo: {
                eventName: 'search',
                eventAction: 'drop-down menu',
                eventPoints: 'na',
                type: 'finance options',
                timeStamp: timestamp,
                cause: 'default',
                effect: brand !== 'bmwBike' ? 'fin > acs central > select budget (' + minMaxState + ')' : 'fin > acs motorcycle > select budget (' + minMaxState + ')'
            }
        }
    ]
};

/**
 * event: Event to be used in case the user is selecting a range.
 *
 * @param timestamp
 * @param brand
 *
 * @returns {[{eventInfo: {timeStamp, eventPoints: string, effect: (string), eventName: string, cause: string, type: string, eventAction: string}}]}
 */
const getSelectRange_EVENT = function (timestamp, brand) {
    return [
        {
            eventInfo: {
                eventName: 'interaction',
                eventAction: 'internal click',
                eventPoints: 'na',
                type: 'success',
                timeStamp: timestamp,
                cause: 'default',
                effect: brand !== 'bmwBike' ? 'fin > acs central > select range' : 'fin > acs motorcycle > select model'
            }
        }
    ]
};

/**
 * event: Event to be used in case the user is clicking on the "Go to configurator" button.
 *
 * @param timestamp
 * @param brand
 *
 * @returns {[{eventInfo: {timeStamp, eventPoints: string, effect: (string), eventName: string, cause: string, type: string, eventAction: string}}]}
 */
const getOpenConfigurator_EVENT = function (timestamp, brand) {
    return [
        {
            eventInfo: {
                eventName: 'interaction',
                eventAction: 'internal click',
                eventPoints: 'na',
                type: 'ux',
                timeStamp: timestamp,
                cause: 'default',
                effect: brand !== 'bmwBike' ? 'fin > acs central > open configurator' : 'fin > acs motorcycle > open configurator'
            }
        }
    ]
};

/**
 * event: Event to be used in case the user is clicking on the "Request a test drive" button.
 *
 * @param timestamp
 *
 * @returns {[{eventInfo: {timeStamp, eventPoints: string, effect: string, eventName: string, cause: string, type: string, eventAction: string}}]}
 */
const getRequestTestDrive_EVENT = function (timestamp) {
    return [
        {
            eventInfo: {
                eventName: 'interaction',
                eventAction: 'internal click',
                eventPoints: 'na',
                type: 'ux',
                timeStamp: timestamp,
                cause: 'default',
                effect: 'fin > acs central > test drive' // Not needed to be adjusted for motorcycle so far (feature not in use)
            }
        }
    ]
};
// 'custom' parts: -----------------------------------------------------------------------------------------------------

/**
 * custom: Custom part to be used after ACS had been loaded.
 *
 * @returns {{}}
 */
const getAcsLoaded_CUSTOM = function () {
    return {
        // Current it is fine to leave the custom object empty for this event
    }
};

/**
 * custom: Custom part to be used in case the user is changing the rates [search event].
 *
 * @param minMaxState
 * @param store
 *
 * @returns {{event: [{attributes: {search: [{unit: *, term: *, state: string, label: string}]}}]}}
 */
const getSelectBudget_CUSTOM = function (minMaxState, store) {
    return {
        event: [
            {
                attributes: {
                    search: [
                        {
                            state: 'user selected',
                            unit: store.getters.labels.financeParameterFormat.currencySymbol, // e.g. "CAD"
                            label: 'monthly payment(' + minMaxState + ')', // "monthly payment (max)" or "monthly payment (min)"
                            term: minMaxState === 'min' ? store.getters.filterSettings.min : store.getters.filterSettings.max // e.g. "500"
                        }
                    ]
                }
            }
        ]
    }
};

// [non search events]: SelectModel, OpenConfigurator, RequestTestDrive

/**
 * Overview over format of non search custom parts with calls to related function:
 *
 * {
 *     "custom": {
 *         "event": [
 *             {
 *                 "attributes": {
 *                     "linkedProduct": [
 *                         {
 *                             -> getCustomFinanceProductLink()
 *                         },
 *                         {
 *                             -> getCustomVehicleProductLink()
 *                         }
 *                     ]
 *                 }
 *             }
 *         ],
 *         "product": [
 *             {
 *                 -> getCustomFinanceProduct()
 *             },
 *             {
 *                 -> getCustomVehicleProduct()
 *             }
 *         ]
 *     }
 * }
 */

/**
 * Returns 'linkedProduct' for finance product.
 *
 * @param store
 * @param financeProductInformation
 * @param financeProductDescription
 * @param financeProducts
 *
 * @returns {{productInfo: {productId: string}}}
 */
const getCustomFinanceProductLink = function (store, financeProductInformation, financeProductDescription, financeProducts) {
    const productName = financeProductDescription.productName; // From offer or 'undefined' in case of selectModel event
    const selectedProduct = financeProductInformation.productId !== undefined ? financeProductInformation.productId : store.getters.filterSettings.selectedProduct;
    return {
        productInfo: {
            productId: productName !== undefined
                ? productName + ' > ' + selectedProduct
                : getFinanceProductName(selectedProduct, financeProducts) + ' > ' + selectedProduct // e.g. "Standard Lease > 1001"
        }
    };
}

/**
 * Returns 'linkedProduct' for vehicle product.
 *
 * @returns {{productInfo: {productID: string}}}
 */
const getCustomVehicleProductLink = function (vehicleInformation) {
    return {
        productInfo: {
            productID: 'BMW-' + vehicleInformation.modelRange, // e.g. "BMW-F30"
        }
    };
}

/**
 * Returns finance 'product'.
 *
 * @param store
 * @param financePaymentInformation
 * @param financeProductInformation
 * @param financeProductDescription
 * @param financeProducts
 *
 * @returns {{attributes: {fin: {annualMileage: (number|string|null|*), monthlyPayment: *, downPayment, term: (number|null|*)}}, category: {subCategory1: *, primaryCategory: string, productType: string}, productInfo: {productID: string, productName: *}}}
 */
const getCustomFinanceProduct = function (store, financePaymentInformation, financeProductInformation, financeProductDescription, financeProducts) {
    const productName = financeProductDescription.productName; // From offer or 'undefined' in case of selectModel event
    const financeCategory = financeProductInformation.productBaseType; // From offer or 'undefined' in case of selectModel event
    const selectedProduct = financeProductInformation.productId !== undefined ? financeProductInformation.productId : store.getters.filterSettings.selectedProduct;
    return {
        category: {
            productType: 'service',
            primaryCategory: 'finance offer',
            subCategory1: financeCategory !== undefined ? financeCategory : getFinanceCategory(selectedProduct, financeProducts) // e.g. "lease", "loan"
        },
        productInfo: {
            productName: productName !== undefined ? productName : getFinanceProductName(selectedProduct, financeProducts), // e.g. "Standard Leasing", "Select Leasing"
            productID: productName !== undefined ?
                productName + ' > ' + selectedProduct
                : getFinanceProductName(selectedProduct, financeProducts) + ' > ' + selectedProduct // "[productName] > product number"
        },
        attributes: {
            fin: {
                downPayment: store.getters.filterSettings.dep, // e.g. 10000
                monthlyPayment: financePaymentInformation.basePayment, // e.g. 1000
                term: store.getters.filterSettings.trm, // e.g. 48
                annualMileage: store.getters.filterSettings.mil // e.g. 15000
            }
        }
    }
}

/**
 * Returns vehicle 'product'.
 *
 * @param store
 * @param vehicleInformation
 *
 * @returns {{attributes: {modelCode, series: *, hybridVersion: string}, category: {subCategory1: string, primaryCategory: (string), productType: string}, productInfo: {productID: string, productName: (*|string), manufacturer: string}}}
 */
const getCustomVehicleProduct = function (store, vehicleInformation) {
    return {
        category: {
            productType: 'vehicle',
            primaryCategory: store.getters.parameters.brand !== 'bmwBike' ? 'new car' : 'new bike',
            subCategory1: 'bto' // = build-to-order
        },
        productInfo: {
            productName: vehicleInformation.modelName !== undefined ? vehicleInformation.modelName : vehicleInformation.rangeDescription, // e.g. "520d m sport saloon"
            productID: 'BMW-' + vehicleInformation.modelRange, // e.g. "BMW-F30"
            manufacturer: 'bmw'
        },
        attributes: {
            modelCode: vehicleInformation.code, // e.g. "2u11"
            hybridVersion: store.getters.parameters.brand !== 'bmwBike' ? vehicleInformation.hybridCode : undefined, // e.g. "BEVE", "NOHY", "PHEV","HYBR","EREX"
            series: vehicleInformation.modelSeries, // e.g. "3 series", "1 series","X series", "M series"
        }
    }
}

/**
 * Builds 'custom' parts for non search messages.
 *
 * @param store
 * @param vehicleInformation
 * @param financePaymentInformation
 * @param financeProductInformation
 * @param financeProductDescription
 * @param financeProducts
 *
 * @returns {{product: [], event: [{attributes: {linkedProduct: []}}]}}
 */
const getNonSearch_CUSTOM = function (store, vehicleInformation, financePaymentInformation, financeProductInformation, financeProductDescription, financeProducts) {
    const linkedProduct = [];

    linkedProduct.push(getCustomFinanceProductLink(store, financeProductInformation, financeProductDescription, financeProducts));
    linkedProduct.push(getCustomVehicleProductLink(vehicleInformation));

    const product = [];
    product.push(getCustomFinanceProduct(store, financePaymentInformation, financeProductInformation, financeProductDescription, financeProducts));
    product.push(getCustomVehicleProduct(store, vehicleInformation));

    return {
        event: [
            {
                attributes: {
                    linkedProduct
                }
            }
        ],
        product
    }
}
/**
 * This is especially for BMW Motorrad as in that case the vehicle is already picked (not the range and not the finance product).
 * Not finance product data gets filled as the finance product is not picked yet.
 *
 * Builds 'custom' parts for non search messages.
 *
 * @param store
 * @param vehicleInformation
 *
 * @returns {{product: [], event: [{attributes: {linkedProduct: []}}]}}
 */
const getNonSearch_CUSTOM_BMWBIKE_SELECT_MODEL = function (store, vehicleInformation) {
    const linkedProduct = [];

    linkedProduct.push(getCustomVehicleProductLink(vehicleInformation));

    const product = [];
    product.push(getCustomVehicleProduct(store, vehicleInformation));

    return {
        event: [
            {
                attributes: {
                    linkedProduct
                }
            }
        ],
        product
    }
}

/**
 * custom: Custom part to be used in case the user is selecting a range.
 *
 * @param store
 * @param vehicleInformation
 * @param financeProducts
 *
 * @returns {{product: [], event: [{attributes: {linkedProduct: []}}]}}
 */
const getSelectRange_CUSTOM = function (store, vehicleInformation, financeProducts) {

    if(store.getters.parameters.brand === 'bmwBike') { // Special case for BMW Motorrad: Model (not range) selected, but no finance product
        return getNonSearch_CUSTOM_BMWBIKE_SELECT_MODEL(store, vehicleInformation);
    }

    return getNonSearch_CUSTOM(store, vehicleInformation, {}, {}, {}, financeProducts);
};

/**
 *
 * custom: Custom part to be used in case the user is clicking on the "Go to configurator" button.
 *
 * @param store
 * @param vehicleInformation
 * @param financePaymentInformation
 * @param financeProductInformation
 * @param financeProductDescription
 *
 * @returns {{product: [], event: [{attributes: {linkedProduct: []}}]}}
 */
const getOpenConfigurator_CUSTOM = function (store, vehicleInformation, financePaymentInformation, financeProductInformation, financeProductDescription) {
    return getNonSearch_CUSTOM(store, vehicleInformation, financePaymentInformation, financeProductInformation, financeProductDescription, undefined);
};

/**
 * custom: Custom part to be used in case the user is clicking on the "Request a test drive" button.
 *
 * @param store
 * @param vehicleInformation
 * @param financePaymentInformation
 * @param financeProductInformation
 * @param financeProductDescription
 *
 * @returns {{product: [], event: [{attributes: {linkedProduct: []}}]}}
 */
const getRequestTestDrive_CUSTOM = function (store, vehicleInformation, financePaymentInformation, financeProductInformation, financeProductDescription) {
    return getNonSearch_CUSTOM(store, vehicleInformation, financePaymentInformation, financeProductInformation, financeProductDescription, undefined);
};

// Serve complete messages: --------------------------------------------------------------------------------------------

function sendMessage(detail, brand) {
    const message = JSON.stringify({
        methodName: brand !== 'bmwBike' ? 'acs central' : 'acs motorcycle',
        detail: detail
    });
    window.top.postMessage(message, '*');
}

const events = {};

/**
 * - For bmwCar + bmwBike: Sends event message after ACS had been loaded.
 *
 * @param timestamp
 * @param brand
 */
events.sendAcsLoadedEvent = function (timestamp, brand) {
    sendMessage(
        {
            component: getGeneral_COMPONENT(brand),
            event: getAcsLoaded_EVENT(timestamp, brand),
            custom: getAcsLoaded_CUSTOM(),
            version: GENERAL_VERSION
        },
        brand
    )
}

/**
 * - For bmwCar + bmwBike: Sends event message after monthly budget had been adjusted (min + max).
 *
 * @param timestamp
 * @param minMaxState
 * @param store
 */
events.sendSelectBudgetEvent = function (timestamp, minMaxState, store) {
    sendMessage(
        {
            component: getGeneral_COMPONENT(store.getters.parameters.brand),
            event: getSelectBudget_EVENT(timestamp, minMaxState, store.getters.parameters.brand),
            custom: getSelectBudget_CUSTOM(minMaxState, store),
            version: GENERAL_VERSION
        },
        store.getters.parameters.brand
    )
}

/**
 * - For bmwCar: Sends event message after 'Select' button had been used.
 * - For bmwBike: Send event message after a click on the vehicle.
 *
 * @param timestamp
 * @param store
 * @param vehicleInformation
 * @param financeProducts
 */
events.sendSelectRangeEvent = function (timestamp, store, vehicleInformation, financeProducts) {
    sendMessage(
        {
            component: getGeneral_COMPONENT(store.getters.parameters.brand),
            event: getSelectRange_EVENT(timestamp, store.getters.parameters.brand),
            custom: getSelectRange_CUSTOM(store, vehicleInformation, financeProducts),
            version: GENERAL_VERSION
        },
        store.getters.parameters.brand
    )
}

/**
 * - For bmwCar: Sends event message after 'Request a test drive' button had been used.
 * - For bmwBike: This is NOT IN USE for bmwBike.
 *
 * @param timestamp
 * @param store
 * @param offer
 */
events.sendRequestTestDriveEvent = function (timestamp, store, offer) {
    sendMessage(
        {
            component: getGeneral_COMPONENT(store.getters.parameters.brand),
            event: getRequestTestDrive_EVENT(timestamp),
            custom: getRequestTestDrive_CUSTOM(store, offer.vehicleInformation, offer.financePaymentInformation, offer.financeProductInformation, offer.financeProductDescription),
            version: GENERAL_VERSION
        },
        store.getters.parameters.brand
    )
}

/**
 * - For bmwCar + bmwBike: Sends event message after 'Got to configurator' button had been used.
 *
 * @param timestamp
 * @param store
 * @param offer
 */
events.sendOpenConfiguratorEvent = function (timestamp, store, offer) {
    sendMessage(
        {
            component: getGeneral_COMPONENT(store.getters.parameters.brand),
            event: getOpenConfigurator_EVENT(timestamp, store.getters.parameters.brand),
            custom: getOpenConfigurator_CUSTOM(store, offer.vehicleInformation, offer.financePaymentInformation, offer.financeProductInformation, offer.financeProductDescription),
            version: GENERAL_VERSION
        },
        store.getters.parameters.brand
    )
}

// ------------------------------ exports ---------------------------------------------------------
module.exports = events;
