<template>
    <div class="offerDetails">
        <div class="info-row">
            <div class="info-text highlighted">
                {{labels.mainPage.vehiclesList.leaseOrFinanceFrom | replaceProductBaseTypeWithValue(filterSettings.selectedProduct)}}
                <span class="monthly-installment">{{modelRangeObject.minPayment | formatNumber('currencyInteger', parameters.language, parameters.market)}}</span>
                {{labels.mainPage.vehiclesList.perMonth}}
            </div>
            <router-link :to="{ name: 'EngineSelectionPageCost', params: {modelRange: modelRange, rangeDescription: rangeDescription, enginesFound: enginesFound, modelImageMobile: modelImageMobile, modelImageDesktop: modelImageDesktop } }">
                <div class="info-icon">
                    <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" focusable="false"
                         style="pointer-events: none; display: block; width: 100%; height: 100%;">
                        <g fill="none" fill-rule="evenodd" viewBox="0 0 24 24">
                            <circle cx="12" cy="12" r="11" stroke="#BBB" stroke-width="2"></circle>
                            <path fill="#4D4D4D"
                                  d="M11 16.09h1.818v-5.555H11v5.556zm0-7.272h1.818V7H11v1.818z"></path>
                        </g>
                    </svg>
                </div>
            </router-link>
        </div>
        <div class="info-row break">
            <div class="info-text highlighted">
                {{labels.mainPage.vehiclesList.interestRate | replaceInterestRateWithValue(modelRangeObject.interestRate)}} {{labels.mainPage.vehiclesList.chosenParameters | replaceTermWithValue(filterSettings.trm)}}
            </div>
        </div>
        <div class="info-row">
            <div class="info-text wrap">
                {{labels.mainPage.vehiclesList.offerDetails | replaceDepositWithValue(filterSettings.dep, 'currencyInteger', parameters.language, parameters.market) | replaceExpiryDateWithValue(validity.validTill, 'date', parameters.language, parameters.market)}}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'VehicleGridItemCost',
        components: {
        },
        props: {
            modelRange: '',
            modelRangeObject: {},
            rangeDescription: '',
            enginesFound: '',
            modelImageMobile: '',
            modelImageDesktop: ''
        },
        data() {
            return {}
        },
        computed: {
            labels() {
                return this.$store.state.labels
            },
            validity() {
                return this.$store.state.validity
            },
            parameters() {
                return this.$store.state.parameters
            },
            modelStatsOverview() {
                return this.$store.state.bucketStats.modelStatsOverview
            },
            bucketStats() {
                return this.$store.state.bucketStats
            },
            filterSettings() {
                return this.$store.state.filterSettings
            }
        },
        created() {
        },
        methods: {
            modelNVehicles(modelRange) {
                const filteredModelNOffers = this.seriesNOffers.filter((offer) => {
                    return offer.vehicleInformation.modelRange.includes(modelRange);
                })
                const modelNVehicles = [];
                const vehicles = new Map();
                for (const offer of filteredModelNOffers) {
                    if (!vehicles.has(offer.vehicleInformation.vehicleId)) {
                        vehicles.set(offer.vehicleInformation.vehicleId, true);
                        modelNVehicles.push({
                            id: offer.vehicleInformation.vehicleId,
                            data: offer
                        });
                    }
                }
                return modelNVehicles;
            },
        },
        filters: {
            formatValue: function (value, type) {
                if (type == 'Integer') {
                    return parseInt(value)
                }
                if (type == 'Float') {
                    return parseFloat(value).toFixed(1).replace('.', ',')
                }
                if (type == 'Percentage') {
                    value = parseFloat(value * 100).toFixed(2)
                    value = value.replace('.', ',')
                    return value + ' %'
                }
                if (type == 'Currency') {
                    value = parseFloat(value).toFixed(2)
                    value = value.replace('.', ',')
                    let splitValue = value.split(',')
                    if (splitValue[0].length > 3) {
                        let thousands = splitValue[0].slice(0, -3)
                        let hundreds = splitValue[0].slice(-3)
                        return thousands + '.' + hundreds + ',' + splitValue[1] + ' ' + labels.financeParameterFormat.currencySymbol
                    }
                    return value + ' €'
                }
            },
            calculatePS: function (value, kW) {
                return parseInt(kW * 1.35962)
            },
            replaceFuelType: function (value, fuelType) {
                value = value.replace('O', 'Benzin')
                value = value.replace('E', 'Voll-Elektrisch')
                return value
            },
            replaceModelsWithValue: function (value, modelsFound) {
                if (!value) {
                    return ''
                }
                if (value.search('{models}')) {
                    value = value.replace('{models}', modelsFound)
                    return value
                } else {
                    return value
                }
            },
            replaceTermWithValue: function (value, term) {
                if (!value) {
                    return ''
                }
                if (value.search('{term}')) {
                    value = value.replace('{term}', term)
                    return value
                } else {
                    return value
                }
            },
            replaceMileageWithValue: function (value, mileage) {
                if (!value) {
                    return ''
                }
                if (value.search('{mileage}')) {
                    value = value.replace('{mileage}', mileage)
                    return value
                } else {
                    return value
                }
            },
            replaceDepositWithValue: function (value, deposit, type, language, market) {
                if (!value) {
                    return ''
                }
                if (value.search('deposit')) {
                    let locale = language + '-' + market.toUpperCase()
                    let currency = (function() {
                        switch (locale) {
                            case 'de-DE': return 'EUR'
                            case 'en-CA': return 'CAD'
                            case 'fr-CA': return 'CAD'
                        }
                    })('EUR')
                    let depositFormatted = Intl.NumberFormat(locale, {style: 'currency', currency: currency, minimumFractionDigits: 0, maximumFractionDigits: 0}).format(deposit)
                    value = value.replace('{deposit}', depositFormatted)
                    return value
                } else {
                    return value
                }
            },
            replaceExpiryDateWithValue: function (value, date, type, language, market) {
                if (!value) {
                    return ''
                }
                if (value.search('expiryDate')) {
                    let locale = language + '-' + market.toUpperCase()
                    let currentDate = new Date(date)
                    currentDate.setDate(currentDate.getDate() - 1)
                    const options = { year: 'numeric', month: 'long', day: 'numeric' }
                    let dateFormatted = currentDate.toLocaleDateString(locale, options)
                    value = value.replace('{expiryDate}', dateFormatted)
                    return value
                } else {
                    return value
                }
            },
            replaceProductBaseTypeWithValue: function (value, selectedProduct) {
                // TODO: this filter has to be changed, once the correct ProductBaseType is delivered from PAPI
                if (!value) {
                    return ''
                }
                if (value.search('productBaseType')) {
                    if (selectedProduct == '1001') {
                        value = value.replace('{productBaseType}', 'Lease')
                    }
                    else if (selectedProduct == '1002' || selectedProduct == '1004') {
                        value = value.replace('{productBaseType}', 'Finance')
                    }
                    return value
                } else {
                    return value
                }
            },
            replaceInterestRateWithValue: function (value, interestRate) {
                if (!value) {
                    return ''
                }
                if (value.search('{interestRate}')) {
                    value = value.replace('{interestRate}', parseFloat(interestRate * 100).toFixed(2)) + '% '
                    return value
                } else {
                    return value
                }
            },
            formatNumber: function (value, type, language, market) {
                if (!value) {
                    return ''
                }
                let locale = language + '-' + market.toUpperCase()
                let currency = (function() {
                    switch (locale) {
                        case 'de-DE': return 'EUR'
                        case 'en-CA': return 'CAD'
                        case 'fr-CA': return 'CAD'
                    }
                })('EUR')

                if (type == 'currency') {
                    return new Intl.NumberFormat(locale, {style: 'currency', currency: currency}).format(value)
                }

                if (type == 'currencyInteger') {
                    return new Intl.NumberFormat(locale, {style: 'currency', currency: currency, minimumFractionDigits: 0, maximumFractionDigits: 0}).format(value)
                }

                if (type == 'percentage') {
                    return new Intl.NumberFormat(locale, {style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2}).format(value)
                }

            },
        }
    }
</script>

<style scoped>
    .tile {
        display: block;
        width: 270px;
        margin-right: 10px;
    }

    .flex {
        min-width: 270px;
    }

    .modelDetails {
        margin-bottom: 25px;
        padding-right: 15px;
        height: 140px;
    }

    img {
        width: 270px;
        display: block;
        margin: 5px 0px;
    }

    h3 {
        word-wrap: break-word;
        white-space: normal;
        margin-bottom: 5px;
        width: 250px;
    }

    p, span {
        font-size: 12px;
        letter-spacing: 0.16px;
        margin-bottom: 3px;
    }

    .header {
        height: 200px;
        margin-top: 25px;
    }

    .transmissions {
        font-weight: bold;
        color: #8E8E8E;
        margin-top: 5px;
        margin-bottom: 10px;
    }

    .vehicleDetails {
        margin-bottom: 25px;
        padding-right: 15px;
        height: 140px;
    }

    .info-row {
        min-height: 12px;
        display: flex;
        align-items: center;
        margin-bottom: 3px;
    }

    .info-text {
        min-height: 12px;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        letter-spacing: 0.16px;
    }

    .wrap {
        display: block;
        word-wrap: break-word;
        white-space:normal;
    }
    .info-icon {
        height: 12px;
        width: 12px;
        min-width: 12px;
        justify-content: center;
        stroke: none;
        vertical-align: middle;
        margin-right: 5px;
        display: flex;
        align-items: center;
    }

    .monthly-installment {
        font-weight: bold;
        font-size: 16px;
        color: grey;
        margin-left: 3px;
    }

    .highlighted {
        font-size: 15px;
        letter-spacing: 0.22px;
    }

    .seperator {
        margin: 0px 2px;
    }

    .emission {
        background-color: green;
    }

    #mobile .engineSelectionButton {
        display: block;
        margin-top: 10px;
    }

    .break {
        margin-top: -5px;
        margin-bottom: 10px;
    }

    .minEnergyLabel {
        color: white;
        background-color: darkgrey;
        font-weight: bold;
        font-size: 8px;
        letter-spacing: 0.16px;
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 0.7%;
        margin-left: 5px;
        margin-right: 2px;
        height: 10px;
        display: flex;
    }

    .maxEnergyLabel {
        color: white;
        background-color: darkgrey;
        font-weight: bold;
        font-size: 8px;
        letter-spacing: 0.16px;
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 0.7%;
        margin-left: 2px;
        height: 5px;
        display: flex;
    }

    /* emission colors */

    .Aplus {
        background-color: #009036;
    }

    .A {
        background-color: #009642;
    }

    .B {
        background-color: #52ae34;
    }

    .C {
        background-color: #c6d300;
    }

    .D {
        background-color: #ffed00;
    }

    .E {
        background-color: #edae00;
    }

    .F {
        background-color: #ea670b;
    }

    .G {
        background-color: #e20c15;
    }
</style>
