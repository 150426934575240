<template>
    <div class="grid">
        <div class="vehicle-grid" v-if="labels.mainPage">
            <!--<div v-if="filteredOffers <1">
                <h3>Suche Modelle...</h3>
            </div>
            <div v-else>-->
            <div class="info-row" v-if="bucketStats">
                <div id="modelsFound">
                    <h2 class="engines-info" v-show="loading == false">{{ bucketStats.modelsFoundTotal }} </h2>
                    <v-progress-circular v-show="loading == true" class="progress"
                                         :size="30"
                                         :width="3"
                                         color="#1c69d4"
                                         indeterminate
                    ></v-progress-circular>
                </div>
                <div class="info-text">
                    <span v-show="bucketStats.modelsFoundTotal == 0">{{ labels.mainPage.vehiclesList.modelsFoundPlural }}</span>
                    <span v-show="bucketStats.modelsFoundTotal == 1">{{ labels.mainPage.vehiclesList.modelsFoundSingular }}</span>
                    <span v-show="bucketStats.modelsFoundTotal > 1">{{ labels.mainPage.vehiclesList.modelsFoundPlural }}</span>
                </div>
                <div class="info-icon" v-show="labels.mainPage.vehiclesList.modelsFoundInfoIconDisplay == true"
                     @click="alert = !alert" ref="icon">
                    <svg v-if="bucketStats.modelsFoundTotal" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"
                         focusable="false"
                         style="pointer-events: none; display: block; width: 100%; height: 100%;">
                        <g fill="none"
                           fill-rule="evenodd"
                           viewBox="0 0 24 24">
                            <circle cx="12" cy="12" r="11" stroke="#BBB" stroke-width="2"></circle>
                            <path fill="#4D4D4D"
                                  d="M11 16.09h1.818v-5.555H11v5.556zm0-7.272h1.818V7H11v1.818z"></path>
                        </g>
                    </svg>
                </div>
                <mq-layout mq="sm+" class="layout">
                    <v-alert :value="alert" transition="scale-transition" class="infobox"
                             v-closable="{exclude: ['icon'], handler: onClose}">
                        <v-card class="infocard"
                                elevation="4"
                        >
                            {{ labels.mainPage.vehiclesList.modelsFoundInfoIconText |
                            replaceModelsWithValue(bucketStats.modelsFoundTotal)}}
                        </v-card>
                    </v-alert>
                </mq-layout>
                <mq-layout mq="xs" class="layout">
                    <v-alert :value="alert" transition="scale-transition" class="infobox-mobile"
                             v-closable="{exclude: ['icon'], handler: onClose}">
                        <v-card class="infocard"
                                elevation="4"
                        >
                            {{ labels.mainPage.vehiclesList.modelsFoundInfoIconText |
                            replaceModelsWithValue(bucketStats.modelsFoundTotal)}}
                        </v-card>
                    </v-alert>
                </mq-layout>
            </div>
        </div>
        <mq-layout mq="sm+">
            <v-container v-if="bucketStats.seriesOverview && this.parameters.brand == 'bmw'">
                <div v-if="bucketStats.seriesOverview.series1" class="grid-row">
                    <div class="modelSeries">{{ bucketStats.seriesOverview.series1.series }}</div>
                    <VehicleGridItem :modelStats=bucketStats.seriesOverview.series1.modelStatsOverview
                                     :series=bucketStats.seriesOverview.series1.series
                    ></VehicleGridItem>
                </div>
                <div v-if="bucketStats.seriesOverview.series2" class="grid-row">
                    <div class="modelSeries">{{ bucketStats.seriesOverview.series2.series }}</div>
                    <VehicleGridItem :modelStats=bucketStats.seriesOverview.series2.modelStatsOverview
                                     :series=bucketStats.seriesOverview.series2.series
                    ></VehicleGridItem>
                </div>
                <div v-if="bucketStats.seriesOverview.series3" class="grid-row">
                    <div class="modelSeries">{{ bucketStats.seriesOverview.series3.series }}</div>
                    <VehicleGridItem :modelStats=bucketStats.seriesOverview.series3.modelStatsOverview
                                     :series=bucketStats.seriesOverview.series3.series
                    ></VehicleGridItem>
                </div>
                <div v-if="bucketStats.seriesOverview.series4" class="grid-row">
                    <div class="modelSeries">{{ bucketStats.seriesOverview.series4.series }}</div>
                    <VehicleGridItem :modelStats=bucketStats.seriesOverview.series4.modelStatsOverview
                                     :series=bucketStats.seriesOverview.series4.series
                    ></VehicleGridItem>
                </div>
                <div v-if="bucketStats.seriesOverview.series5" class="grid-row">
                    <div class="modelSeries">{{ bucketStats.seriesOverview.series5.series }}</div>
                    <VehicleGridItem :modelStats=bucketStats.seriesOverview.series5.modelStatsOverview
                                     :series=bucketStats.seriesOverview.series5.series
                    ></VehicleGridItem>
                </div>
                <div v-if="bucketStats.seriesOverview.series6" class="grid-row">
                    <div class="modelSeries">{{ bucketStats.seriesOverview.series6.series }}</div>
                    <VehicleGridItem :modelStats=bucketStats.seriesOverview.series6.modelStatsOverview
                                     :series=bucketStats.seriesOverview.series6.series
                    ></VehicleGridItem>
                </div>
                <div v-if="bucketStats.seriesOverview.series7" class="grid-row">
                    <div class="modelSeries">{{ bucketStats.seriesOverview.series7.series }}</div>
                    <VehicleGridItem :modelStats=bucketStats.seriesOverview.series7.modelStatsOverview
                                     :series=bucketStats.seriesOverview.series7.series
                    ></VehicleGridItem>
                </div>
                <div v-if="bucketStats.seriesOverview.series8" class="grid-row">
                    <div class="modelSeries">{{ bucketStats.seriesOverview.series8.series }}</div>
                    <VehicleGridItem :modelStats=bucketStats.seriesOverview.series8.modelStatsOverview
                                     :series=bucketStats.seriesOverview.series8.series
                    ></VehicleGridItem>
                </div>
                <div v-if="bucketStats.seriesOverview.seriesX" class="grid-row">
                    <div class="modelSeries">{{ bucketStats.seriesOverview.seriesX.series }}</div>
                    <VehicleGridItem :modelStats=bucketStats.seriesOverview.seriesX.modelStatsOverview
                                     :series=bucketStats.seriesOverview.seriesX.series
                    ></VehicleGridItem>
                </div>
                <div v-if="bucketStats.seriesOverview.seriesZ" class="grid-row">
                    <div class="modelSeries">{{ bucketStats.seriesOverview.seriesZ.series }}</div>
                    <VehicleGridItem :modelStats=bucketStats.seriesOverview.seriesZ.modelStatsOverview
                                     :series=bucketStats.seriesOverview.seriesZ.series
                    ></VehicleGridItem>
                </div>
                <div v-if="bucketStats.seriesOverview.seriesM" class="grid-row">
                    <div class="modelSeries">{{ bucketStats.seriesOverview.seriesM.series }}</div>
                    <VehicleGridItem :modelStats=bucketStats.seriesOverview.seriesM.modelStatsOverview
                                     :series=bucketStats.seriesOverview.seriesM.series
                    ></VehicleGridItem>
                </div>
                <div v-if="bucketStats.seriesOverview.seriesi" class="grid-row">
                    <div class="modelSeries">{{ bucketStats.seriesOverview.seriesi.series }}</div>
                    <VehicleGridItem :modelStats=bucketStats.seriesOverview.seriesi.modelStatsOverview
                                     :series=bucketStats.seriesOverview.seriesi.series
                    ></VehicleGridItem>
                </div>
            </v-container>
        </mq-layout>
        <mq-layout mq="xs">
            <v-expansion-panels accordion multiple v-if="bucketStats.seriesOverview" :key="filterSettingsChange">
                <v-expansion-panel v-if="bucketStats.seriesOverview.series1" @click="scrollIntoView('series1')">
                    <v-expansion-panel-header id="series1">
                        <template v-slot:actions>
                            <v-icon class="expansion-icon">$expand</v-icon>
                        </template>
                        <span class="expansion-header">
                        <span class="font-weight-bold">{{ bucketStats.seriesOverview.series1.series }}</span> <span
                                class="modelOverview">({{ bucketStats.seriesOverview.series1.modelsFoundPerSeries }} {{bucketStats.seriesOverview.series1.modelsFoundPerSeries  | addModels(labels) }})</span>
                    </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <VehicleGridItem :modelStats=bucketStats.seriesOverview.series1.modelStatsOverview
                                         :series=bucketStats.seriesOverview.series1.series
                        ></VehicleGridItem>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel v-if="bucketStats.seriesOverview.series2" @click="scrollIntoView('series2')">
                    <v-expansion-panel-header id="series2">
                        <template v-slot:actions>
                            <v-icon class="expansion-icon">$expand</v-icon>
                        </template>
                        <span class="expansion-header">
                        <span class="font-weight-bold">{{ bucketStats.seriesOverview.series2.series }}</span> <span
                                class="modelOverview">({{ bucketStats.seriesOverview.series2.modelsFoundPerSeries }} {{bucketStats.seriesOverview.series2.modelsFoundPerSeries  | addModels(labels) }})</span>
                    </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <VehicleGridItem :modelStats=bucketStats.seriesOverview.series2.modelStatsOverview
                                         :series=bucketStats.seriesOverview.series2.series
                        ></VehicleGridItem>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel v-if="bucketStats.seriesOverview.series3" @click="scrollIntoView('series3')">
                    <v-expansion-panel-header id="series3">
                        <template v-slot:actions>
                            <v-icon class="expansion-icon">$expand</v-icon>
                        </template>
                        <span class="expansion-header">
                        <span class="font-weight-bold">{{ bucketStats.seriesOverview.series3.series }}</span> <span
                                class="modelOverview">({{ bucketStats.seriesOverview.series3.modelsFoundPerSeries }} {{bucketStats.seriesOverview.series3.modelsFoundPerSeries  | addModels(labels) }})</span>
                    </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <VehicleGridItem :modelStats=bucketStats.seriesOverview.series3.modelStatsOverview
                                         :series=bucketStats.seriesOverview.series3.series
                        ></VehicleGridItem>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel v-if="bucketStats.seriesOverview.series4" @click="scrollIntoView('series4')">
                    <v-expansion-panel-header id="series4">
                        <template v-slot:actions>
                            <v-icon class="expansion-icon">$expand</v-icon>
                        </template>
                        <span class="expansion-header">
                        <span class="font-weight-bold">{{ bucketStats.seriesOverview.series4.series }}</span> <span
                                class="modelOverview">({{ bucketStats.seriesOverview.series4.modelsFoundPerSeries }} {{bucketStats.seriesOverview.series4.modelsFoundPerSeries  | addModels(labels) }})</span>
                    </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <VehicleGridItem :modelStats=bucketStats.seriesOverview.series4.modelStatsOverview
                                         :series=bucketStats.seriesOverview.series4.series
                        ></VehicleGridItem>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel v-if="bucketStats.seriesOverview.series5" @click="scrollIntoView('series5')">
                    <v-expansion-panel-header id="series5">
                        <template v-slot:actions>
                            <v-icon class="expansion-icon">$expand</v-icon>
                        </template>
                        <span class="expansion-header">
                        <span class="font-weight-bold">{{ bucketStats.seriesOverview.series5.series }}</span> <span
                                class="modelOverview">({{ bucketStats.seriesOverview.series5.modelsFoundPerSeries }} {{bucketStats.seriesOverview.series5.modelsFoundPerSeries  | addModels(labels) }})</span>
                    </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <VehicleGridItem :modelStats=bucketStats.seriesOverview.series5.modelStatsOverview
                                         :series=bucketStats.seriesOverview.series5.series
                        ></VehicleGridItem>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel v-if="bucketStats.seriesOverview.series6" @click="scrollIntoView('series6')">
                    <v-expansion-panel-header id="series6">
                        <template v-slot:actions>
                            <v-icon class="expansion-icon">$expand</v-icon>
                        </template>
                        <span class="expansion-header">
                        <span class="font-weight-bold">{{ bucketStats.seriesOverview.series6.series }}</span> <span
                                class="modelOverview">({{ bucketStats.seriesOverview.series6.modelsFoundPerSeries }} {{bucketStats.seriesOverview.series6.modelsFoundPerSeries  | addModels(labels) }})</span>
                    </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <VehicleGridItem :modelStats=bucketStats.seriesOverview.series6.modelStatsOverview
                                         :series=bucketStats.seriesOverview.series6.series
                        ></VehicleGridItem>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel v-if="bucketStats.seriesOverview.series7" @click="scrollIntoView('series7')">
                    <v-expansion-panel-header id="series7">
                        <template v-slot:actions>
                            <v-icon class="expansion-icon">$expand</v-icon>
                        </template>
                        <span class="expansion-header">
                        <span class="font-weight-bold">{{ bucketStats.seriesOverview.series7.series }}</span> <span
                                class="modelOverview">({{ bucketStats.seriesOverview.series7.modelsFoundPerSeries }} {{bucketStats.seriesOverview.series7.modelsFoundPerSeries  | addModels(labels) }})</span>
                    </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <VehicleGridItem :modelStats=bucketStats.seriesOverview.series7.modelStatsOverview
                                         :series=bucketStats.seriesOverview.series7.series
                        ></VehicleGridItem>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel v-if="bucketStats.seriesOverview.series8" @click="scrollIntoView('series8')">
                    <v-expansion-panel-header id="series8">
                        <template v-slot:actions>
                            <v-icon class="expansion-icon">$expand</v-icon>
                        </template>
                        <span class="expansion-header">
                        <span class="font-weight-bold">{{ bucketStats.seriesOverview.series8.series }}</span> <span
                                class="modelOverview">({{ bucketStats.seriesOverview.series8.modelsFoundPerSeries }} {{bucketStats.seriesOverview.series8.modelsFoundPerSeries  | addModels(labels) }})</span>
                    </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <VehicleGridItem :modelStats=bucketStats.seriesOverview.series8.modelStatsOverview
                                         :series=bucketStats.seriesOverview.series8.series
                        ></VehicleGridItem>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel v-if="bucketStats.seriesOverview.seriesX" @click="scrollIntoView('seriesX')">
                    <v-expansion-panel-header id="seriesX">
                        <template v-slot:actions>
                            <v-icon class="expansion-icon">$expand</v-icon>
                        </template>
                        <span class="expansion-header">
                        <span class="font-weight-bold">{{ bucketStats.seriesOverview.seriesX.series }}</span> <span
                                class="modelOverview">({{ bucketStats.seriesOverview.seriesX.modelsFoundPerSeries }} {{bucketStats.seriesOverview.seriesX.modelsFoundPerSeries  | addModels(labels) }})</span>
                    </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <VehicleGridItem :modelStats=bucketStats.seriesOverview.seriesX.modelStatsOverview
                                         :series=bucketStats.seriesOverview.seriesX.series
                        ></VehicleGridItem>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel v-if="bucketStats.seriesOverview.seriesZ" @click="scrollIntoView('seriesZ')">
                    <v-expansion-panel-header id="seriesZ">
                        <template v-slot:actions>
                            <v-icon class="expansion-icon">$expand</v-icon>
                        </template>
                        <span class="expansion-header">
                        <span class="font-weight-bold">{{ bucketStats.seriesOverview.seriesZ.series }}</span> <span
                                class="modelOverview">({{ bucketStats.seriesOverview.seriesZ.modelsFoundPerSeries }} {{bucketStats.seriesOverview.seriesZ.modelsFoundPerSeries  | addModels(labels) }})</span>
                    </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <VehicleGridItem :modelStats=bucketStats.seriesOverview.seriesZ.modelStatsOverview
                                         :series=bucketStats.seriesOverview.seriesZ.series
                        ></VehicleGridItem>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel v-if="bucketStats.seriesOverview.seriesM" @click="scrollIntoView('seriesM')">
                    <v-expansion-panel-header id="seriesM">
                        <template v-slot:actions>
                            <v-icon class="expansion-icon">$expand</v-icon>
                        </template>
                        <span class="expansion-header">
                        <span class="font-weight-bold">{{ bucketStats.seriesOverview.seriesM.series }}</span> <span
                                class="modelOverview">({{ bucketStats.seriesOverview.seriesM.modelsFoundPerSeries }} {{bucketStats.seriesOverview.seriesM.modelsFoundPerSeries  | addModels(labels) }})</span>
                    </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <VehicleGridItem :modelStats=bucketStats.seriesOverview.seriesM.modelStatsOverview
                                         :series=bucketStats.seriesOverview.seriesM.series
                        ></VehicleGridItem>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel v-if="bucketStats.seriesOverview.seriesi" @click="scrollIntoView('seriesi')">
                    <v-expansion-panel-header id="seriesi">
                        <template v-slot:actions>
                            <v-icon class="expansion-icon">$expand</v-icon>
                        </template>
                        <span class="expansion-header">
                        <span class="font-weight-bold">{{ bucketStats.seriesOverview.seriesi.series }}</span> <span
                                class="modelOverview">({{ bucketStats.seriesOverview.seriesi.modelsFoundPerSeries }} {{bucketStats.seriesOverview.seriesi.modelsFoundPerSeries  | addModels(labels) }})</span>
                    </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <VehicleGridItem :modelStats=bucketStats.seriesOverview.seriesi.modelStatsOverview
                                         :series=bucketStats.seriesOverview.seriesi.series
                        ></VehicleGridItem>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </mq-layout>
        <v-container
                v-if="bucketStats.seriesOverview && labels.mainPage.vehiclesList.segmentDescription && this.parameters.brand == 'bmwBike'">
            <div v-if="bucketStats.seriesOverview.seriesAD" class="grid-row">
                <div class="segment">{{ labels.mainPage.vehiclesList.segmentDescription.AD }}</div>
                <VehicleGridItem :modelStats=bucketStats.seriesOverview.seriesAD.modelStatsOverview
                                 :series=bucketStats.seriesOverview.seriesAD.series
                ></VehicleGridItem>
            </div>
            <div v-if="bucketStats.seriesOverview.seriesHE" class="grid-row">
                <div class="segment">{{ labels.mainPage.vehiclesList.segmentDescription.HE }}</div>
                <VehicleGridItem :modelStats=bucketStats.seriesOverview.seriesHE.modelStatsOverview
                                 :series=bucketStats.seriesOverview.seriesHE.series
                ></VehicleGridItem>
            </div>
            <div v-if="bucketStats.seriesOverview.seriesLT" class="grid-row">
                <div class="segment">{{ labels.mainPage.vehiclesList.segmentDescription.LT }}</div>
                <VehicleGridItem :modelStats=bucketStats.seriesOverview.seriesLT.modelStatsOverview
                                 :series=bucketStats.seriesOverview.seriesLT.series
                ></VehicleGridItem>
            </div>
            <div v-if="bucketStats.seriesOverview.seriesMS" class="grid-row">
                <div class="segment">{{ labels.mainPage.vehiclesList.segmentDescription.MS }}</div>
                <VehicleGridItem :modelStats=bucketStats.seriesOverview.seriesMS.modelStatsOverview
                                 :series=bucketStats.seriesOverview.seriesMS.series
                ></VehicleGridItem>
            </div>
            <div v-if="bucketStats.seriesOverview.seriesNA" class="grid-row">
                <div class="segment">{{ labels.mainPage.vehiclesList.segmentDescription.NA }}</div>
                <VehicleGridItem :modelStats=bucketStats.seriesOverview.seriesNA.modelStatsOverview
                                 :series=bucketStats.seriesOverview.seriesNA.series
                ></VehicleGridItem>
            </div>
            <div v-if="bucketStats.seriesOverview.seriesSP" class="grid-row">
                <div class="segment">{{ labels.mainPage.vehiclesList.segmentDescription.SP }}</div>
                <VehicleGridItem :modelStats=bucketStats.seriesOverview.seriesSP.modelStatsOverview
                                 :series=bucketStats.seriesOverview.seriesSP.series
                ></VehicleGridItem>
            </div>
        </v-container>
    </div>
</template>

<script>
    import VehicleGridItem from "./VehicleGridItem";

    let handleOutsideClick

    export default {
        name: 'VehicleGrid',
        components: {
            VehicleGridItem,
        },
        props: {},
        data() {
            return {
                alert: false,
                filterSettingsChange: false
            }
        },
        computed: {
            labels() {
                return this.$store.state.labels
            },
            parameters() {
                return this.$store.state.parameters
            },
            filterSettings() {
                return this.$store.state.filterSettings
            },
            bucketStats() {
                return this.$store.state.bucketStats
            },
            loading() {
                return this.$store.getters.filterSettings.loading
            }
        },
        created() {
        },
        methods: {
            onClose() {
                this.alert = false
            },
            scrollIntoView(series) {
                /*let element = document.getElementById(series);
                element.scrollIntoView();*/
            },
        },
        watch: {
            filterSettings: {
                handler() {
                    this.filterSettingsChange = !this.filterSettingsChange
                }
            }
        },
        directives: {
            closable: {
                bind(el, binding, vnode) {
                    handleOutsideClick = (e) => {
                        e.stopPropagation()
                        const {handler, exclude} = binding.value
                        let clickedOnExcludedEl = false

                        // Gives you the ability to exclude certain elements if you want, pass as array of strings to exclude
                        if (exclude) {
                            exclude.forEach(refName => {
                                if (!clickedOnExcludedEl) {
                                    const excludedEl = vnode.context.$refs[refName]
                                    clickedOnExcludedEl = excludedEl.contains(e.target)
                                }
                            })
                        }

                        if (!el.contains(e.target) && !clickedOnExcludedEl) {
                            handler(e)
                        }
                    }
                    document.addEventListener('click', handleOutsideClick)
                    document.addEventListener('touchstart', handleOutsideClick)
                },

                unbind() {
                    document.removeEventListener('click', handleOutsideClick)
                    document.removeEventListener('touchstart', handleOutsideClick)
                }
            }
        },
        filters: {
            replaceModelsWithValue: function (value, modelsFound) {
                if (!value) {
                    return ''
                }
                if (value.search('{models}')) {
                    value = value.replace('{models}', modelsFound)
                    return value
                } else {
                    return value
                }
            },
            replaceTermWithValue: function (value, term) {
                if (!value) {
                    return ''
                }
                if (value.search('{term}')) {
                    value = value.replace('{term}', term)
                    return value
                } else {
                    return value
                }
            },
            replaceMileageWithValue: function (value, mileage) {
                if (!value) {
                    return ''
                }
                if (value.search('{mileage}')) {
                    value = value.replace('{mileage}', mileage)
                    return value
                } else {
                    return value
                }
            },
            replaceDepositWithValue: function (value, deposit) {
                if (!value) {
                    return ''
                }
                if (value.search('{deposit}')) {
                    value = value.replace('{deposit}', deposit)
                    return value
                } else {
                    return value
                }
            },
            replaceProductBaseTypeWithValue: function (value, productBaseType) {
                if (!value) {
                    return ''
                }
                if (value.search('{productBaseType}')) {
                    value = value.replace('{productBaseType}', productBaseType)
                    return value
                } else {
                    return value
                }
            },
            replaceMonthlyPaymentWithValue: function (value, monthlyPayment) {
                if (!value) {
                    return ''
                }
                if (value.search('{monthlyPayment}')) {
                    value = value.replace('{monthlyPayment}', monthlyPayment)
                    return value
                } else {
                    return value
                }
            },
            addModels: function (value, labels) {
                let singular = labels.mainPage.vehiclesList.modelsFoundSingular.split(' ')
                let plural = labels.mainPage.vehiclesList.modelsFoundPlural.split(' ')

                if (value == 1) {
                    return singular[0]
                } else {
                    return plural[0]
                }
            }
        }
    }
</script>

<style scoped>
    .font-weight-bold {
        font-weight: bold;
        display: inline-flex;
    }

    .modelOverview {

    }

    .expansion-header {
        order: 1;
    }

    .expansion-icon {
        order: 0;
        margin-right: 20px;
    }

    .v-expansion-panel::before {
        box-shadow: none !important;
    }

    .v-expansion-panel:not(:first-child)::after {
        border-top-width: 0px !important;
    }

    .v-expansion-panel-header {
        padding-left: 0px !important;
    }

    .v-expansion-panel-content {
        margin-right: 0px !important;
    }

    .grid {
        margin-top: 0px;
    }

    .grid-row {
        white-space: nowrap;
        user-select: none;
        border-bottom: lightgray solid thin;
        display: block;
        padding-bottom: 15px;
    }

    .grid-row:last-of-type {
        border-bottom: none;
    }

    .info-row {
        min-height: 18px;
        display: flex;
        align-items: baseline;
        margin-bottom: 3px;
    }

    .info-text {
        min-height: 18px;
        text-align: left;
        display: flex;
        align-items: center;
    }

    .info-icon {
        height: 18px;
        width: 18px;
        justify-content: center;
        stroke: none;
        vertical-align: middle;
        margin-right: 15px;
        display: flex;
        align-items: center;
    }

    .infobox {
        position: absolute;
        width: 50%;
        max-width: 520px;
        padding: 0;
        margin-left: -60px;
        margin-top: 10px;
    }

    .infobox-mobile {
        position: absolute;
        padding: 0;
        margin: 20px;
        width: 90%;
        left: 0;
    }

    .infocard {
        padding: 10px;
        font-size: 15px;
        z-index: 100;
    }

    .v-card__text {
        padding: 0px 0px !important;
    }

    .engines-info {
        margin-bottom: 0px;
        margin-right: 5px;
        display: flex;
    }

    .modelSeries {
        font-size: 100px;
        color: #E6E6E6;
        float: left;
        width: 90px;
        margin-right: 10px;
        margin-top: -15px;
        padding: 15px 0;
    }

    .segment {
        font-size: 60px;
        font-weight: bold;
        color: #E6E6E6;
        float: left;
        width: 90px;
        padding: 15px 0px;
        margin-right: 10px;
        margin-top: -15px;
    }

    div.container {
        padding: 0;
        margin: 0;
    }

    .v-expansion-panel-header {
        font-size: 20px !important;
        letter-spacing: 0.32px;
    }

    .v-expansion-panel-header--active {
        color: #1c69d4;
    }

    #modelsFound {
        min-width: 35px;
    }

</style>
