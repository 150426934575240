<template>
    <div class="main" data-app="true">
        <div class="fallback padding-iframe" v-if="!isValid && loading == false">
            <h1>{{ labels.mainPage.mainPageTitle }}</h1>
            <v-skeleton-loader
                    class="mx-auto"
                    max-width="1500"
                    type="heading"
            ></v-skeleton-loader>
            <h3 class="sub-header">{{ labels.mainPage.mainPageOfferValiditySubTitle }}</h3>
            <p v-html="labels.mainPage.mainPageOfferValiditySubText"></p>
        </div>
        <div v-if="!settings || !labels || !bucketStats && isValid" class="fallback padding-iframe">
            <h1>{{ fallbackMessages.title }}</h1>
            <h3 class="sub-header">{{ fallbackMessages.subtitle }}</h3>
            <p v-html="fallbackMessages.text"></p>
        </div>
        <div v-if="settings && labels && bucketStats && isValid">
            <div class="innerText" v-if="labels.mainPage">
                <section>
                    <h1>{{ labels.mainPage.mainPageTitle }}</h1>
                    <h3>{{ labels.mainPage.mainPageSubTitle }}</h3>
                    <p>{{ labels.mainPage.mainPageSubText }}</p>
                </section>
                <div id="productConfiguration">
                    <div id="installmentSelection" v-if="(settings.filter)">
                        <v-layout row class="installmentSelection" v-if="parameters.brand == 'bmw'"
                                  id="budgetSelection">
                            <v-flex md6 sm6 xs6 wrap class="budgetParameter">
                                <v-select class="installment"
                                          v-model="filterParametersRateMin"
                                          :label="labels.mainPage.budgetSelection.budgetFrom"
                                          :items="filterParametersRate"
                                          item-text="label"
                                          item-value="value"
                                          outlined
                                          dense
                                          color="#1C69D4"
                                          @change="validateInput"
                                >
                                </v-select>
                            </v-flex>
                            <v-flex md6 sm6 xs6 wrap class="budgetParameter">
                                <v-select class="installment"
                                          v-model="filterParametersRateMax"
                                          :label="labels.mainPage.budgetSelection.budgetTo"
                                          :items="filterParametersRate"
                                          item-text="label"
                                          item-value="value"
                                          outlined
                                          dense
                                          color="#1C69D4"
                                          @change="validateInput"
                                ></v-select>
                            </v-flex>
                        </v-layout>
                    </div>
                    <div v-if="errors.length" class="warning-box">
                        <div class="warning-icon">
                            <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" focusable="false"
                                 style="pointer-events: none; display: block; width: 24px; height: 24px;">
                                <g width="24" height="24">
                                    <path d="M11 14h2V7h-2v7zm0 4h2v-2h-2v2zm1-18C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0z"
                                          fill-rule="evenodd"></path>
                                </g>
                            </svg>
                        </div>
                        <span class="warning-message">{{ labels.mainPage.budgetSelection.budgetSelectionWarningMessage }}</span>
                    </div>
                    <div id="productSelection">
                        <MainFilterProductBaseType v-if="parameters.market == 'de'"></MainFilterProductBaseType>
                        <MainFilterProductSpecific v-if="parameters.market == 'ca'"
                                                   :initialTab=this.initialTab :trm1001=this.trm1001
                                                   :dep1001=this.dep1001
                                                   :mil1001=this.mil1001 :trm1002=this.trm1002 :dep1002=this.dep1002
                                                   :trm1004=this.trm1004
                                                   :dep1004=this.dep1004></MainFilterProductSpecific>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="settings && labels && bucketStats && isValid">
            <div class="inner" v-if="labels.mainPage">
                <VehicleGrid></VehicleGrid>
                <div class="disclaimer" v-if="bucketStats.modelsFoundTotal" v-html="labels.mainPage.disclaimer">

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import VehicleGrid from "./VehicleGrid"
    import AdjustFinancePage from "./AdjustFinancePage";
    import MainFilterProductBaseType from "./MainFilterProductBaseType";
    import MainFilterProductSpecific from "./MainFilterProductSpecific";
    import TrackingHandler from "../helpers/TrackingHandler";

    export default {
        name: 'MainPage',
        components: {
            AdjustFinancePage,
            VehicleGrid,
            MainFilterProductBaseType,
            MainFilterProductSpecific
        },
        props: {
            row: null,
            allModels: null,
            rateMin: null,
            rateMax: null,
            initialTab: null,
            trm1001: null,
            dep1001: null,
            mil1001: null,
            trm1002: null,
            dep1002: null,
            trm1004: null,
            dep1004: null
        },
        data() {
            return {
                errors: [],
                filterParametersRateMin: this.rateMin,
                filterParametersRateMax: this.rateMax,
                provincesEN: [
                    {
                        'label': 'Select your Province.',
                        'value': 'start'
                    },
                    {
                        'label': 'Alberta',
                        'value': 'ab'
                    },
                    {
                        'label': 'British Columbia',
                        'value': 'bc'
                    },
                    {
                        'label': 'Manitoba',
                        'value': 'mb'
                    },
                    {
                        'label': 'New Brunswick',
                        'value': 'nb'
                    },
                    {
                        'label': 'Newfoundland and Labrador',
                        'value': 'nl'
                    },
                    {
                        'label': 'Nova Scotia',
                        'value': 'ns'
                    },
                    {
                        'label': 'Ontario',
                        'value': 'on'
                    },
                    {
                        'label': 'Quebec',
                        'value': 'qc'
                    },
                    {
                        'label': 'Saskatchewan',
                        'value': 'sk'
                    },
                ],
                provincesFR: [
                    {
                        'label': 'Sélectionnez votre province.',
                        'value': 'start'
                    },
                    {
                        'label': 'Alberta',
                        'value': 'ab'
                    },
                    {
                        'label': 'Colombie-Britannique',
                        'value': 'bc'
                    },
                    {
                        'label': 'Manitoba',
                        'value': 'mb'
                    },
                    {
                        'label': 'Nouveau-Brunswick',
                        'value': 'nb'
                    },
                    {
                        'label': 'Terre-neuve-et-Labrador',
                        'value': 'nl'
                    },
                    {
                        'label': 'Nouvelle-Écosse',
                        'value': 'ns'
                    },
                    {
                        'label': 'Ontario',
                        'value': 'on'
                    },
                    {
                        'label': 'Québec',
                        'value': 'qc'
                    },
                    {
                        'label': 'Saskatchewan',
                        'value': 'sk'
                    },
                ]
            }
        },
        computed: {
            isValid() {
                if (this.validity == undefined) {
                    return true
                }
                let currentDate = new Date()
                let validFrom = new Date(this.validity.validFrom)
                let validTill = new Date(this.validity.validTill)
                if (currentDate >= validFrom && currentDate <= validTill) {
                    return true
                } else if (currentDate < validFrom || currentDate > validTill) {
                    return false
                }
            },
            fallbackMessages() {
                if (this.parameters.brand == 'bmw' && this.parameters.market == 'de') {
                    let fallbackMessages = {
                        "title": "BMW MODELLE ZU IHREM BUDGET.",
                        "subtitle": "Hier ist etwas schiefgegangen!!",
                        "text": "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet."
                    }
                    return fallbackMessages
                }
                if (this.parameters.brand == 'bmw' && this.parameters.market == 'ca') {
                    if (this.parameters.language == 'en') {
                        let fallbackMessages = {
                            "title": "YOUR BMW, YOUR BUDGET.",
                            "subtitle": "This service is not available at the moment.",
                            "text": "BMW Financial Services offers you a choice of car finance options to help you get behind the wheel of a new or used BMW. At every BMW Centre, you'll find a BMW Financial Services specialist who can talk you through the options we can provide. These will depend on whether you want the option to own the car at the end of the agreement or just lease it. The difference will be explained to you to help inform your decision. Once you've chosen the finance package that suits you and your budget, we'll do the rest. From paperwork and phone calls to delivery arrangements, we'll handle all the details and do everything to get you on the road as quickly and easily as possible."
                        }
                        return fallbackMessages
                    }
                    if (this.parameters.language == 'fr') {
                        let fallbackMessages = {
                            "title": "VOTRE BMW. VOTRE BUDGET.",
                            "subtitle": "Ce service n'est pas disponible pour le moment.",
                            "text": "Les services financiers BMW vous offrent un choix d'options de financement et de location afin de vous aider à prendre le volant d'une BMW neuve ou d'occasion. Chez chaque concessionnaire BMW, vous trouverez un spécialiste des services financiers BMW qui saura vous expliquer les différentes options de financement et de location disponibles. Ces options dépendent de votre choix de posséder la voiture à la fin du contrat ou d’en profiter pour une période donnée. La différence vous sera expliquée afin que vous preniez une décision éclairée. Nous pouvons également vous aider à compléter votre Ultimate Driving Experience® avec notre gamme de produits de protection des Services financiers BMW vous offrant une tranquillité d'esprit additionnelle. Une fois que vous avez choisi le type de financement offert par les Services financiers BMW qui vous convient et qui convient à votre budget, nous nous occuperons du reste. Des formalités administratives aux appels téléphoniques en passant par les arrangements de livraison, nous nous occuperons de tous les détails et ferons tout pour vous mettre sur la route le plus rapidement et le plus facilement possible. Vous vous demandez quelle option de financement vous convient le mieux? Répondez à quelques brèves questions sur l’expérience que vous recherchez en tant que propriétaire d’un véhicule BMW et nous vous proposerons diverses options spécialement conçues pour répondre à vos besoins. <a href=\"https://customerneeds.bmwgroup.com/can/frontend/index.html?market=ca&lang=fr&brand=bmw#\">En savoir plus.</a>"
                        }
                        return fallbackMessages
                    }
                }
                if (this.parameters.brand == 'bmwBike' && this.parameters.market == 'de') {
                    let fallbackMessages = {
                        "title": "BMW MODELLE ZU IHREM BUDGET.",
                        "subtitle": "Hier ist etwas schiefgegangen!!",
                        "text": "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet."
                    }
                    return fallbackMessages
                }
            },
            financeProducts() {
                if (this.bucketStats != null) {
                    let financeProducts = this.bucketStats.financeProducts
                    return financeProducts;
                }
            },
            filterParametersRate() {
                let rates = []
                for (const rate of this.settings.filter.rate.values) {
                    rates.push({
                        'label': this.formatValue(rate, 'currencyInteger', this.parameters.language, this.parameters.market),
                        'value': rate
                    })
                }
                return rates
            },
            parameters() {
                return this.$store.state.parameters
            }
            ,
            endpoints() {
                return this.$store.state.endpoints
            }
            ,
            labels() {
                return this.$store.state.labels
            }
            ,
            settings() {
                return this.$store.state.settings
            }
            ,
            validity() {
                return this.$store.state.validity
            }
            ,
            offers() {
                return this.$store.state.offers
            }
            ,

            bucketStats() {
                return this.$store.state.bucketStats
            },
            loading() {
                return this.$store.getters.filterSettings.loading
            },

            modelsFoundTotal() {
                if (this.bucketStats != null) {
                    let modelsFoundTotal = this.bucketStats.modelsFoundTotal
                    return modelsFoundTotal;
                }
            }
            ,
            filterSettings() {
                return this.$store.state.filterSettings
            }
            ,
        },
        beforeMount() {
        },

        created() {

        },
        methods: {
            async updateOffers(minMaxState) {
                let baseUrl = this.$store.getters.parameters.baseUrl
                let market = this.$store.getters.parameters.market
                let region = this.$store.getters.parameters.region
                let brand = this.$store.getters.parameters.brand
                let channel = this.$store.getters.parameters.channel
                let language = this.$store.getters.parameters.language
                let baseProduct = this.$store.getters.filterSettings.baseProduct
                let selectedProduct = this.$store.getters.filterSettings.selectedProduct
                let trm = this.$store.getters.filterSettings.trm
                let dep = this.$store.getters.filterSettings.dep
                let mil = this.$store.getters.filterSettings.mil
                let min = this.filterParametersRateMin
                this.$store.commit('setMinInstallment', min)
                let max = this.filterParametersRateMax
                this.$store.commit('setMaxInstallment', max)
                let filterUrl = baseUrl + '/filter/dc/market/' + market.toLowerCase() + '/brand/' + brand.toLowerCase() + '/channel/' + channel + '/language/' + language + '/trm/' + trm + '/mil/' + mil + '/dep/' + dep + '/min/' + min + '/max/' + max + '?baseproduct=' + baseProduct
                this.$store.commit('setFilterUrl', filterUrl)
                let bucketUrl = ''
                if (region != 0) {
                    bucketUrl = baseUrl + '/filter/bucket/market/' + market.toLowerCase() + ':' + region + '/brand/' + brand.toLowerCase() + '/channel/' + channel + '/language/' + language + '/trm/' + trm + '/mil/' + mil + '/dep/' + dep + '/min/' + min + '/max/' + max + '?productid=' + selectedProduct
                } else if (market == 'de') {
                    bucketUrl = baseUrl + '/filter/bucket/market/' + market.toLowerCase() + '/brand/' + brand.toLowerCase() + '/channel/' + channel + '/language/' + language + '/trm/' + trm + '/mil/' + mil + '/dep/' + dep + '/min/' + min + '/max/' + max + '?baseproduct=' + baseProduct
                } else {
                    bucketUrl = baseUrl + '/filter/bucket/market/' + market.toLowerCase() + '/brand/' + brand.toLowerCase() + '/channel/' + channel + '/language/' + language + '/trm/' + trm + '/mil/' + mil + '/dep/' + dep + '/min/' + min + '/max/' + max
                }
                this.$store.commit('setBucketUrl', bucketUrl)
                await this.$store.dispatch('fetchBucketStats', bucketUrl)
                this.$store.commit('setLoading', false)
                if (minMaxState) {
                    TrackingHandler.sendSelectBudgetEvent(Date.now(), minMaxState, this.$store);
                }
            },

            validateInput() {
                this.errors = [];
                let minInstallment = parseInt(this.filterParametersRateMin)
                let maxInstallment = parseInt(this.filterParametersRateMax)

                if (minInstallment > maxInstallment) {
                    this.errors.push('min cannot be higher as max.')
                }
            },
            formatValue: function (value, type, language, market) {
                if (value == null) {
                    return ''
                }
                let locale = language + '-' + market.toUpperCase()
                let currency = (function () {
                    switch (locale) {
                        case 'de-DE':
                            return 'EUR'
                        case 'en-CA':
                            return 'CAD'
                        case 'fr-CA':
                            return 'CAD'
                    }
                })('EUR')
                if (type == 'currency') {
                    return new Intl.NumberFormat(locale, {style: 'currency', currency: currency}).format(value)
                }
                if (type == 'currencyInteger') {
                    return new Intl.NumberFormat(locale, {
                        style: 'currency',
                        currency: currency,
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                    }).format(value)
                }
            },
        },
        watch: {
            filterParametersRateMin: {
                deep: true,
                handler() {
                    this.$store.commit('setLoading', true)
                    this.updateOffers('min')
                }
            },
            filterParametersRateMax: {
                deep: true,
                handler() {
                    this.$store.commit('setLoading', true)
                    this.updateOffers('max')
                }
            },
            filterParametersMoto: {
                deep: true,
                handler() {
                    this.$store.commit('setLoading', true)
                    this.updateOffers()
                }
            },
        }
    }
</script>

<style scoped>
    /*body {
        font-family: BMWTypeWeb;
        font-size: 15px;
        letter-spacing: 0.22px;
        color: #262626;
    }*/

    .main {
        margin-top: 20px;
        width: 100%;
    }

    #default p {
        margin-bottom: 20px;
        /*max-width: 1000px;*/
        max-width: 980px;
    }

    #mobile p {
        margin-bottom: 25px;
    }

    .warning-box {
        background-color: #ffe6bb;
        min-height: 45px;
        max-width: 800px;
        padding: 10px 15px;
        word-break: break-word;
        margin-bottom: 25px;
        display: flex;
        align-items: center;
    }

    .warning-icon {
        height: 24px;
        width: 24px;
        fill: #ffad1f;
        justify-content: center;
        stroke: none;
        vertical-align: middle;
        margin-right: 15px;
        display: flex;
        align-items: center;
    }

    .warning-message {
        min-height: 24px;
        text-align: left;
        display: flex;
        align-items: center;
        margin-bottom: 0;
    }

    .installment {
        display: block;
        /*max-width: 446px;
        min-width: 446px;*/
        flex-basis: initial;
        flex-grow: unset;
        flex-shrink: unset;
        margin-right: 10px !important;
    }

    #provinceSelection {
        margin: 0 !important;
    }

    #mobile .installment {
        position: center;
        float: left;
    }

    /*.product {
        margin-right: 15px;
        margin-top: -10px;
    }*/

    .installmentSelection {
        margin-left: 0px;
    }

    .info-icon {
        width: 12px;
        height: auto;
    }

    .errormessage {
        background-color: #ffe6eb;
    }

    .disclaimer {
        font-size: 12px;
        letter-spacing: 0.16px;
        margin-top: 20px;
        margin-bottom: 20px;
        text-align: justify;
        max-width: 1000px;
    }

    #default .disclaimer {
        padding-left: 100px;
    }

    #default .innerText {
        /*max-width: 1750px;*/
        max-width: 1000px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .budgetParameter {
        min-width: 140px;
    }

    .sub-header {
        font-weight: bold;
        margin-top: 20px;
    }

    .fallback {
        margin-left: 10px;
    }

    .padding-iframe {
        padding-bottom: 700px;
    }

</style>
