import  Vue  from  "vue";

Vue.filter("formatValue",  function(value, type, language, market, labels)  {
    if (value == null) {
        return ''
    }
    let locale = language + '-' + market.toUpperCase()
    let currency = (function () {
        switch (locale) {
            case 'de-DE':
                return 'EUR'
            case 'en-CA':
                return 'CAD'
            case 'fr-CA':
                return 'CAD'
        }
    })('EUR')
    if (type == 'integer') {
        return parseInt(value)
    }
    if (type == 'float') {
        return parseFloat(value).toFixed(1).replace('.', ',')
    }
    if (type == 'currency') {
        return new Intl.NumberFormat(locale, {style: 'currency', currency: currency}).format(value)
    }
    if (type == 'currencyInteger') {
        return new Intl.NumberFormat(locale, {
            style: 'currency',
            currency: currency,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        }).format(value)
    }
    if (type == 'percentage') {
        value = parseFloat(value * 100).toFixed(2)
        value = value.replace('.', ',')
        return value + ' %'
    }
    if (type == 'boolean') {
        if (language == 'de') {
            if (value == 'true') {
                return 'Ja'
            }
            if (value == 'false') {
                return 'Nein'
            }
        }
        if (language == 'en') {
            if (value == 'true') {
                return 'Yes'
            }
            if (value == 'false') {
                return 'No'
            }
        }
        if (language == 'fr') {
            if (value == 'true') {
                return 'Oui'
            }
            if (value == 'false') {
                return 'Non'
            }
        }
    }
    if (type == 'mileage') {
        try {
            return new Intl.NumberFormat(locale, {
                style: 'unit',
                unit: 'kilometer',
            }).format(value)
        } catch (e) {
            console.log('Error: ' + e)
            return value
        }

    }
    if (type == 'term') {
        try {
            return new Intl.NumberFormat(locale, {
                style: 'unit',
                unit: 'month',
            }).format(value)
        } catch (e) {
            console.log('Error: ' + e)
            return value
        }
    }
});

Vue.filter("replaceBrackets",  function(value, placeholder, replacement)  {
    if (value == null) {
        return ''
    }
    if (placeholder == '{Term_Leasing}') {
        value = value.replace('{Term_Leasing}', replacement)
        return value
    }
    if (placeholder == '{Term}') {
        value = value.replace('{Term}', replacement)
        return value
    }
    if (placeholder == '{TermAdjustedZiel}') {
        value = value.replace('{TermAdjustedZiel}', replacement)
        return value
    } else {
        return value
    }
});

