<template>
    <div class="vehicleOffer">
        <div class="vehicleInformation">
            <h3>{{ offer.vehicleInformation.modelName }}</h3>
            <v-layout row class="flex-row" v-if="offer.vehicleInformation.transmissionType">
                <v-flex md4 sm6 xs12 wrap>
                    <p class="headline">{{ labels.engineSelectionPage.hybridDetails.transmission }}</p>
                </v-flex>
                <v-flex md8 sm10 xs12 wrap>
                    <p>{{ offer.vehicleInformation.transmissionName || 'Manuell' }}</p>
                </v-flex>
            </v-layout>
            <v-layout row class="flex-row" v-if="offer.vehicleInformation.power">
                <v-flex md4 sm6 xs12 wrap>
                    <p class="headline">{{ labels.engineSelectionPage.hybridDetails.powerAndAcceleration }}</p>
                </v-flex>
                <v-flex md8 sm10 xs12 wrap>
                    <p>{{ offer.vehicleInformation.power.combined.kW }} {{ labels.engineDetailFormat.powerPS }} ({{
                        offer.vehicleInformation.power.combined.PS }}
                        {{ labels.engineDetailFormat.powerPS }}) / {{ offer.vehicleInformation.power.acceleration |
                        formatValue('Float') }} s (0-100km/h)</p>
                </v-flex>
            </v-layout>
            <v-layout row class="flex-row" v-if="offer.vehicleInformation.nefzRanges">
                <v-flex md4 sm6 xs12 wrap>
                    <p class="headline">{{ labels.engineSelectionPage.hybridDetails.fuelConsumptionAndEmissions }}</p>
                </v-flex>
                <mq-layout mq="sm+">
                    <div class="info-row">
                        <div class="info-text">
                            {{ offer.vehicleInformation.nefzRanges.engineNEFZ.fuelConsumption.minFuelConsumptionCombined
                            | formatValue('Float') }}
                        </div>
                        <div class="info-text"
                             v-if="offer.vehicleInformation.nefzRanges.engineNEFZ.fuelConsumption.minFuelConsumptionCombined != offer.vehicleInformation.nefzRanges.engineNEFZ.fuelConsumption.maxFuelConsumptionCombined">
                            -
                        </div>
                        <div class="info-text"
                             v-if="offer.vehicleInformation.nefzRanges.engineNEFZ.fuelConsumption.minFuelConsumptionCombined != offer.vehicleInformation.nefzRanges.engineNEFZ.fuelConsumption.maxFuelConsumptionCombined">
                            {{
                            offer.vehicleInformation.nefzRanges.engineNEFZ.fuelConsumption.maxFuelConsumptionCombined |
                            formatValue('Float') }}
                        </div>
                        <div class="info-text">
                            {{ labels.engineDetailFormat.fuelConsumptionAmount }}
                        </div>
                        <EfficiencyInformationPage :efficiencyData="efficiencyData(offer)"
                                                   origin="offer"></EfficiencyInformationPage>
                        <div class="info-text">
                            / {{
                            offer.vehicleInformation.nefzRanges.engineNEFZ.emissionCO2.minCo2Combined }}
                        </div>
                        <div class="info-text"
                             v-if="offer.vehicleInformation.nefzRanges.engineNEFZ.emissionCO2.minCo2Combined != offer.vehicleInformation.nefzRanges.engineNEFZ.emissionCO2.maxCo2Combined">
                            -
                        </div>
                        <div class="info-text"
                             v-if="offer.vehicleInformation.nefzRanges.engineNEFZ.emissionCO2.minCo2Combined != offer.vehicleInformation.nefzRanges.engineNEFZ.emissionCO2.maxCo2Combined">
                            {{
                            offer.vehicleInformation.nefzRanges.engineNEFZ.emissionCO2.maxCo2Combined }}
                        </div>
                        <div class="info-text">
                            {{ labels.engineDetailFormat.emissionsAmount }} CO2
                        </div>
                        <div class="info-text minEnergyLabel"
                             :class="offer.vehicleInformation.nefzRanges.classifier.minEnergyLabel">
                            {{ offer.vehicleInformation.nefzRanges.classifier.minEnergyLabel }}
                        </div>
                        <div class="info-text"
                             v-if="offer.vehicleInformation.nefzRanges.classifier.minEnergyLabel !== offer.vehicleInformation.nefzRanges.classifier.maxEnergyLabel">
                            -
                        </div>
                        <div class="info-text maxEnergyLabel"
                             :class="offer.vehicleInformation.nefzRanges.classifier.maxEnergyLabel"
                             v-if="offer.vehicleInformation.nefzRanges.classifier.minEnergyLabel !== offer.vehicleInformation.nefzRanges.classifier.maxEnergyLabel">
                            {{
                            offer.vehicleInformation.nefzRanges.classifier.maxEnergyLabel }}
                        </div>
                        <EfficiencyInformationPage :efficiencyData="efficiencyData(offer)"
                                                   origin="offer"></EfficiencyInformationPage>
                    </div>
                </mq-layout>
                <mq-layout mq="xs">
                    <v-flex md8 sm10 xs12 wrap>
                        <div class="info-row">
                            <div class="info-text">
                                {{
                                offer.vehicleInformation.nefzRanges.engineNEFZ.fuelConsumption.minFuelConsumptionCombined
                                | formatValue('Float') }}
                            </div>
                            <div class="info-text"
                                 v-if="offer.vehicleInformation.nefzRanges.engineNEFZ.fuelConsumption.minFuelConsumptionCombined != offer.vehicleInformation.nefzRanges.engineNEFZ.fuelConsumption.maxFuelConsumptionCombined">
                                -
                            </div>
                            <div class="info-text"
                                 v-if="offer.vehicleInformation.nefzRanges.engineNEFZ.fuelConsumption.minFuelConsumptionCombined != offer.vehicleInformation.nefzRanges.engineNEFZ.fuelConsumption.maxFuelConsumptionCombined">
                                {{
                                offer.vehicleInformation.nefzRanges.engineNEFZ.fuelConsumption.maxFuelConsumptionCombined
                                | formatValue('Float') }}
                            </div>
                            <div class="info-text">
                                {{ labels.engineDetailFormat.fuelConsumptionAmount }}
                            </div>
                            <EfficiencyInformationPage :efficiencyData="efficiencyData(offer)"
                                                       origin="offer"></EfficiencyInformationPage>
                            <div class="info-text">
                                /
                            </div>
                        </div>
                    </v-flex>
                    <v-flex md8 sm10 xs12 wrap>
                        <div class="info-row">
                            <div class="info-text">
                                / {{
                                offer.vehicleInformation.nefzRanges.engineNEFZ.emissionCO2.minCo2Combined }}
                            </div>
                            <div class="info-text"
                                 v-if="offer.vehicleInformation.nefzRanges.engineNEFZ.emissionCO2.minCo2Combined != offer.vehicleInformation.nefzRanges.engineNEFZ.emissionCO2.maxCo2Combined">
                                -
                            </div>
                            <div class="info-text"
                                 v-if="offer.vehicleInformation.nefzRanges.engineNEFZ.emissionCO2.minCo2Combined != offer.vehicleInformation.nefzRanges.engineNEFZ.emissionCO2.maxCo2Combined">
                                {{
                                offer.vehicleInformation.nefzRanges.engineNEFZ.emissionCO2.maxCo2Combined }}
                            </div>
                            <div class="info-text">
                                {{ labels.engineDetailFormat.emissionsAmount }} CO2
                            </div>
                            <div class="info-text minEnergyLabel"
                                 :class="offer.vehicleInformation.nefzRanges.classifier.minEnergyLabel">
                                {{ offer.vehicleInformation.nefzRanges.classifier.minEnergyLabel }}
                            </div>
                            <div class="info-text"
                                 v-if="offer.vehicleInformation.nefzRanges.classifier.minEnergyLabel !== offer.vehicleInformation.nefzRanges.classifier.maxEnergyLabel">
                                -
                            </div>
                            <div class="info-text maxEnergyLabel"
                                 :class="offer.vehicleInformation.nefzRanges.classifier.maxEnergyLabel"
                                 v-if="offer.vehicleInformation.nefzRanges.classifier.minEnergyLabel !== offer.vehicleInformation.nefzRanges.classifier.maxEnergyLabel">
                                {{
                                offer.vehicleInformation.nefzRanges.classifier.maxEnergyLabel }}
                            </div>
                            <EfficiencyInformationPage :efficiencyData="efficiencyData(offer)"
                                                       origin="offer"></EfficiencyInformationPage>
                        </div>
                    </v-flex>
                </mq-layout>
            </v-layout>
            <v-layout row class="flex-row" v-if="offer.vehicleInformation.nefzRanges.electric">
                <v-flex md4 sm6 xs12 wrap>
                    <p class="headline">{{ labels.engineSelectionPage.hybridDetails.electricConsumptionAndRange }}</p>
                </v-flex>
                <mq-layout mq="sm+">
                        <div class="info-row">
                            <div class="info-text">
                                {{
                                offer.vehicleInformation.nefzRanges.electric.electricConsumption.minElectricConsumption
                                | formatValue('Float') }}
                            </div>
                            <div class="info-text"
                                 v-if="offer.vehicleInformation.nefzRanges.electric.electricConsumption.minElectricConsumption != offer.vehicleInformation.nefzRanges.electric.electricConsumption.maxElectricConsumption">
                                -
                            </div>
                            <div class="info-text"
                                 v-if="offer.vehicleInformation.nefzRanges.electric.electricConsumption.minElectricConsumption != offer.vehicleInformation.nefzRanges.electric.electricConsumption.maxElectricConsumption">
                                {{
                                offer.vehicleInformation.nefzRanges.electric.electricConsumption.maxElectricConsumption
                                | formatValue('Float') }}
                            </div>
                            <div class="info-text">
                                {{ labels.engineDetailFormat.electricConsumptionAmount }}
                            </div>
                            <EfficiencyInformationPage :efficiencyData="efficiencyData(offer)"
                                                       origin="offer"></EfficiencyInformationPage>
                            <div class="info-text">
                                /
                            </div>
                            <div class="info-text">
                                {{ labels.engineSelectionPage.hybridDetails.rangeUpTo }}
                                {{
                                offer.vehicleInformation.nefzRanges.electric.electricRange.minRange }}
                            </div>
                            <div class="info-text"
                                 v-if="offer.vehicleInformation.nefzRanges.electric.electricRange.minRange != offer.vehicleInformation.nefzRanges.electric.electricRange.maxRange">
                                -
                            </div>
                            <div class="info-text"
                                 v-if="offer.vehicleInformation.nefzRanges.electric.electricRange.minRange != offer.vehicleInformation.nefzRanges.electric.electricRange.maxRange">
                                {{
                                offer.vehicleInformation.nefzRanges.electric.electricRange.maxRange }}
                            </div>
                            <div class="info-text">
                                {{ labels.engineDetailFormat.range }}
                            </div>
                            <EfficiencyInformationPage :efficiencyData="efficiencyData(offer)"
                                                       origin="offer"></EfficiencyInformationPage>
                        </div>
                </mq-layout>
                <mq-layout mq="xs">
                    <v-flex md8 sm10 xs12 wrap>
                        <div class="info-row">
                            <div class="info-text">
                                {{
                                offer.vehicleInformation.nefzRanges.electric.electricConsumption.minElectricConsumption
                                | formatValue('Float') }}
                            </div>
                            <div class="info-text"
                                 v-if="offer.vehicleInformation.nefzRanges.electric.electricConsumption.minElectricConsumption != offer.vehicleInformation.nefzRanges.electric.electricConsumption.maxElectricConsumption">
                                -
                            </div>
                            <div class="info-text"
                                 v-if="offer.vehicleInformation.nefzRanges.electric.electricConsumption.minElectricConsumption != offer.vehicleInformation.nefzRanges.electric.electricConsumption.maxElectricConsumption">
                                {{
                                offer.vehicleInformation.nefzRanges.electric.electricConsumption.maxElectricConsumption
                                | formatValue('Float') }}
                            </div>
                            <div class="info-text">
                                {{ labels.engineDetailFormat.electricConsumptionAmount }}
                            </div>
                            <EfficiencyInformationPage :efficiencyData="efficiencyData(offer)"
                                                       origin="offer"></EfficiencyInformationPage>
                            <div class="info-text">
                                /
                            </div>
                        </div>
                    </v-flex>
                    <v-flex md8 sm10 xs12 wrap>
                        <div class="info-row">
                            <div class="info-text">
                                {{ labels.engineSelectionPage.hybridDetails.rangeUpTo }}
                                {{
                                offer.vehicleInformation.nefzRanges.electric.electricRange.minRange }}
                            </div>
                            <div class="info-text"
                                 v-if="offer.vehicleInformation.nefzRanges.electric.electricRange.minRange != offer.vehicleInformation.nefzRanges.electric.electricRange.maxRange">
                                -
                            </div>
                            <div class="info-text"
                                 v-if="offer.vehicleInformation.nefzRanges.electric.electricRange.minRange != offer.vehicleInformation.nefzRanges.electric.electricRange.maxRange">
                                {{
                                offer.vehicleInformation.nefzRanges.electric.electricRange.maxRange }}
                            </div>
                            <div class="info-text">
                                {{ labels.engineDetailFormat.range }}
                            </div>
                            <EfficiencyInformationPage :efficiencyData="efficiencyData(offer)"
                                                       origin="offer"></EfficiencyInformationPage>
                        </div>
                    </v-flex>
                </mq-layout>
            </v-layout>
        </div>
        <div class="offerDetails">
            <p class="productName">{{ offer.financeProduct.financeProductDescription.productName }}</p>
            <mq-layout mq="sm+">
                <table class="financeParameters">
                    <tr>
                        <td class="financeParametersHeadline">
                            <p class="headline">{{ labels.engineSelectionPage.calculationInDetail }}</p>
                        </td>
                        <td>
                            <v-layout row class="parameterRow"
                                      v-for="(parameter, index) in offer.financeProduct.financeParameters"
                                      :key="index">
                                <v-flex desktop8 laptop8 tablet8 wrap
                                        v-if="parameter.parameterFlags.isDisplayPrint == 'true'">
                                    <p class="parameterName">{{ parameter.parameterDescription.parameterName |
                                        replaceTermWithValue(filterSettings.trm) }}</p>
                                </v-flex>
                                <v-flex desktop4 laptop4 tablet4 wrap
                                        v-if="parameter.parameterFlags.isDisplayPrint == 'true'">
                                    <p class="parameterValue">{{ parameter.value |
                                        formatParameter(parameter.parameterId,
                                        parameter.dataType, parameter.parameterDescription.parameterDisplayPattern,
                                        labels)
                                        }}</p>
                                </v-flex>
                            </v-layout>
                        </td>
                    </tr>
                </table>
            </mq-layout>
            <mq-layout mq="xs">
                <table class="financeParameters">
                    <tr>
                        <td class="financeParametersHeadline">
                            <p class="headline">{{ labels.engineSelectionPage.calculationInDetail }}</p>
                        </td>
                    </tr>
                    <tr v-for="(parameter, index) in offer.financeProduct.financeParameters"
                        :key="index" class="parameterRow">
                        <td class="parameterName" v-if="parameter.parameterFlags.isDisplayPrint == 'true'">
                            <p>{{ parameter.parameterDescription.parameterName |
                                replaceTermWithValue(filterSettings.trm) }}</p>
                        </td>
                        <td class="parameterValue" v-if="parameter.parameterFlags.isDisplayPrint == 'true'">
                            <p>{{ parameter.value | formatParameter(parameter.parameterId,
                                parameter.dataType, parameter.parameterDescription.parameterDisplayPattern, labels)
                                }}</p>
                        </td>
                    </tr>
                </table>
            </mq-layout>


        </div>
        <div id="finalSelection">
            <div>
                <p class="summary">{{ labels.engineSelectionPage.forExampleTotalInstallment }} <span class="h2inline">{{ offer.financePaymentInformation.basePayment | formatParameter(null, 'Currency', null, labels) }}</span>
                </p>
                <p class="summary"> {{ offer.vehicleInformation.modelName }} {{
                    labels.engineSelectionPage.totalInstallmentPerMonth }} </p>
            </div>
            <div class="continue">
                <v-btn dark tile depressed color="#262626" @click="closeDialog">
                    {{ labels.engineSelectionPage.closePageLong }}
                </v-btn>
                <v-btn dark tile depressed color="#1C69D4" @click="">
                    <a :href="configuratorUrl">{{ labels.engineSelectionPage.goToConfigurator }}</a>
                </v-btn>
            </div>

        </div>
        <div id="disclaimer">
            <p class="disclaimer">* {{ offer.financeProduct.financeProductDescription.disclaimerText | lineBreak0 }}</p>
            <p class="disclaimer">{{ offer.financeProduct.financeProductDescription.disclaimerText | lineBreak1 }}</p>
        </div>
    </div>
</template>

<script>
    import EfficiencyInformationPage from "./EfficiencyInformationPage";

    export default {
        name: 'engineSelectionItemHybrid',
        components: {
            EfficiencyInformationPage
        },
        props: {
            offer: {},
        },
        data() {
            return {}
        },
        computed: {
            labels() {
                return this.$store.state.labels
            },
            filterSettings() {
                return this.$store.state.filterSettings
            },
            configuratorUrl() {
                let baseUrl = 'https://configure.bmw.'
                let language = this.$store.getters.parameters.language
                let market = this.$store.getters.parameters.market
                let modelRange = this.offer.vehicleInformation.modelRange
                let modelCode = this.offer.vehicleInformation.code
                let vehicleIdInformation = this.offer.vehicleInformation.vehicleId.split('_')
                let transmissionCode = vehicleIdInformation[1]
                let configuratorUrl = baseUrl + market + '/' + language + '_' + market + '/configure/' + modelRange + '/' + modelCode + '/' + transmissionCode
                return configuratorUrl
            },
        },
        mounted() {

        },
        methods: {
            infoIconBtnClick() {
                console.log('Icon clicked: Info Icon')
            },
            closeDialog() {
                this.$emit('closeDialog')
            },
            efficiencyData(offer) {
                let line = ''
                if (offer.vehicleInformation.sportsFlag == 'N') {
                    line = 'Basisvariante'
                } else {
                    line = 'M-Automobile'
                }
                let hybridCodes = [offer.vehicleInformation.hybridCode]
                let efficiencyData = {
                    'title': offer.vehicleInformation.modelName,
                    'line': line,
                    'hybridCodes': hybridCodes,
                    'consumptionNEFZ': {
                        'fuelConsumptionNEFZMin': offer.vehicleInformation.nefzRanges.engineNEFZ.fuelConsumption.minFuelConsumptionCombined,
                        'fuelConsumptionNEFZMax': offer.vehicleInformation.nefzRanges.engineNEFZ.fuelConsumption.maxFuelConsumptionCombined,
                        'electricConsumptionNEFZMin': offer.vehicleInformation.nefzRanges.electric.electricConsumption.minElectricConsumption,
                        'electricConsumptionNEFZMax': offer.vehicleInformation.nefzRanges.electric.electricConsumption.maxElectricConsumption,
                    },
                    'emissionNEFZ': {
                        'emissionNEFZMin': offer.vehicleInformation.nefzRanges.engineNEFZ.emissionCO2.minCo2Combined,
                        'emissionNEFZMax': offer.vehicleInformation.nefzRanges.engineNEFZ.emissionCO2.maxCo2Combined,
                        'minEnergyLabel': offer.vehicleInformation.nefzRanges.classifier.minEnergyLabel,
                        'maxEnergyLabel': offer.vehicleInformation.nefzRanges.classifier.maxEnergyLabel,
                    },
                    'rangeNEFZ': {
                        'electricRangeNEFZMin': offer.vehicleInformation.nefzRanges.electric.electricRange.minRange,
                        'electricRangeNEFZMax': offer.vehicleInformation.nefzRanges.electric.electricRange.maxRange,
                    },
                    'consumptionWLTP': {
                        'fuelConsumptionWLTPMin': offer.vehicleInformation.wltpRanges.engineWLTP.fuelConsumption.combMin,
                        'fuelConsumptionWLTPMax': offer.vehicleInformation.wltpRanges.engineWLTP.fuelConsumption.combMax,
                        'electric': {
                            'electricConsumptionWLTPMin': '',
                            'electricConsumptionWLTPMax': '',
                        },
                        'hybrid': {
                            'fuelConsumptionWeightedWLTPMin': offer.vehicleInformation.wltpRanges.electric.fuelConsumptionWeighted.fuelConsumptionWeightedMin,
                            'fuelConsumptionWeightedWLTPMax': offer.vehicleInformation.wltpRanges.electric.fuelConsumptionWeighted.fuelConsumptionWeightedMax,
                            'electricConsumptionAcWeightedWLTPMin': offer.vehicleInformation.wltpRanges.electric.electricConsumptionAcWeighted.electricConsumptionAcWeightedMin,
                            'electricConsumptionAcWeightedWLTPMax': offer.vehicleInformation.wltpRanges.electric.electricConsumptionAcWeighted.electricConsumptionAcWeightedMax,
                        }
                    },
                    'emissionWLTP': {
                        'emissionWLTPMin': offer.vehicleInformation.wltpRanges.engineWLTP.emissionCO2.combMin,
                        'emissionWLTPMax': offer.vehicleInformation.wltpRanges.engineWLTP.emissionCO2.combMax,
                        'hybrid': {
                            'co2WeightedWLTPMin': offer.vehicleInformation.wltpRanges.electric.co2Weighted.co2WeightedMin,
                            'co2WeightedWLTPMax': offer.vehicleInformation.wltpRanges.electric.co2Weighted.co2WeightedMax,
                        },
                    },
                    'rangeWLTP': {
                        'electric': {
                            'pureElectricRangeWLTPMin': '',
                            'pureElectricRangeWLTPMax': '',
                        },
                        'hybrid': {
                            'equivalentAllElectricRangeWLTPMin': offer.vehicleInformation.wltpRanges.electric.equivalentAllElectricRange.combMin,
                            'equivalentAllElectricRangeWLTPMax': offer.vehicleInformation.wltpRanges.electric.equivalentAllElectricRange.combMax,
                            'equivalentAllElectricRangeCityWLTPMin': offer.vehicleInformation.wltpRanges.electric.equivalentAllElectricRange.cityMin,
                            'equivalentAllElectricRangeCityWLTPMax': offer.vehicleInformation.wltpRanges.electric.equivalentAllElectricRange.cityMax,
                        }
                    },
                }
                return efficiencyData
            },
        },
        filters: {
            replaceTermWithValue: function (value, trm) {
                if (!value) {
                    return ''
                }
                if (!value.search('{Term}')) {
                    value = value.replace('{Term}', trm)
                    return value
                } else {
                    return value
                }
            },
            formatParameter: function (value, id, type, pattern, labels) {
                if (type == 'Integer') {
                    value = parseInt(value)
                    if (id == 'Mileage') {
                        return value + ' ' + labels.financeParameterFormat.mileage
                    }
                    if (id == 'Term') {
                        return value + ' ' + labels.financeParameterFormat.term
                    }
                    return value
                }
                if (type == 'Percentage') {
                    value = parseFloat(value * 100).toFixed(2)
                    value = value.replace('.', ',')
                    return value + ' %'
                }
                if (type == 'Currency') {
                    value = parseFloat(value).toFixed(2)
                    value = value.replace('.', ',')
                    let splitValue = value.split(',')
                    if (splitValue[0].length > 3) {
                        let thousands = splitValue[0].slice(0, -3)
                        let hundreds = splitValue[0].slice(-3)
                        return thousands + '.' + hundreds + ',' + splitValue[1] + ' ' + labels.financeParameterFormat.currencySymbol
                    }
                    return value + ' ' + labels.financeParameterFormat.currencySymbol
                }
            },
            lineBreak0: function (value) {
                if (!value) {
                    return ''
                }
                value = value.replace('<br/>', '_')
                value = value.split('_')
                return value[0]

            },
            lineBreak1: function (value) {
                if (!value) {
                    return ''
                }
                value = value.replace('<br/>', '_')
                value = value.split('_')
                return value[1]
            },
            formatValue: function (value, type) {
                if (type == 'Integer') {
                    return parseInt(value)
                }
                if (type == 'Float') {
                    return parseFloat(value).toFixed(1).replace('.', ',')
                }
                if (type == 'Percentage') {
                    value = parseFloat(value * 100).toFixed(2)
                    value = value.replace('.', ',')
                    return value + ' %'
                }
                if (type == 'Currency') {
                    value = parseFloat(value).toFixed(2)
                    value = value.replace('.', ',')
                    let splitValue = value.split(',')
                    if (splitValue[0].length > 3) {
                        let thousands = splitValue[0].slice(0, -3)
                        let hundreds = splitValue[0].slice(-3)
                        return thousands + '.' + hundreds + ',' + splitValue[1] + ' ' + labels.financeParameterFormat.currencySymbol
                    }
                    return value + ' €'
                }
            },
        }
    }
</script>

<style scoped>
    .vehicleOffer {
        background-color: white;
        box-shadow: 0 0 5px lightgrey;
        padding: 20px 20px;
        margin-bottom: 20px;
    }

    .vehicleInformation, .offerDetails {
        border-bottom: lightgray solid thin;
        margin-bottom: 15px;
        padding-bottom: 10px;
    }

    .flex-row {
        margin-left: 0px;
    }

    .parameterRow {
        margin-left: 0px;
        margin-right: 0px;
    }

    .headline {
        color: #8E8E8E;
        font-weight: bold;
        font-size: 12px;
        letter-spacing: 0.16px;
    }

    .informationLast {
        margin-top: 3px;
    }

    p {
        font-size: 15px;
        letter-spacing: 0.22px;
    }

    button {
        font-size: 14px;
        letter-spacing: 0.20px;
        margin-bottom: 3px;
    }

    #default button, #mobile button {
        width: 315px;
    }

    .vehicleInformation .flex-row {
        margin-bottom: 5px;
    }

    .productName {
        margin-bottom: 10px;
    }

    .financeParameters {
        width: 100%;
    }

    .financeParametersHeadline {
        vertical-align: top !important;
        width: 225px;
    }

    .parameterValue {
        text-align: right;
    }

    #default .parameterName {
        /*width: 432px;*/
    }

    #mobile .parameterName {
        width: 70%;
        margin-right: 10px;
    }

    #mobile .parameterValue {
        vertical-align: bottom;
    }

    #default button {
        margin-bottom: 10px;
        margin-top: 10px;
    }

    #finalSelection button {
        margin-right: 10px;
    }

    .v-card__actions button {
        margin-bottom: 0px;
    }

    .summary {
        float: right;
        clear: right;
    }

    .continue {
        float: right;
        clear: right;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    #finalSelection a {
        color: white;
        text-decoration-line: unset;
    }

    #disclaimer {
        clear: right;
    }

    .h2inline {
        font-size: 25px;
        letter-spacing: 0.32px;
    }

    h2, h3, p, input {
        color: #262626;
    }

    h3 {
        margin-bottom: 15px;
    }

    .disclaimer {
        font-size: 12px;
        letter-spacing: 0.16px;
        line-height: 150%;
    }

    .info-row {
        min-height: 12px;
        display: flex;
        align-items: center;
        margin-bottom: 3px;
    }

    .info-text {
        min-height: 12px;
        text-align: left;
        display: flex;
        align-items: center;
        font-size: 15px;
        letter-spacing: 0.22px;
        margin-right: 5px;
    }

    .info-icon {
        height: 12px;
        width: 12px;
        justify-content: center;
        stroke: none;
        vertical-align: middle;
        margin-right: 5px;
        margin-left: 0px;
        display: flex;
        align-items: center;
    }

    .minEnergyLabel {
        color: white;
        background-color: darkgrey;
        font-weight: bold;
        font-size: 10px;
        letter-spacing: 0.16px;
        padding: 1px 10px;
        margin-right: 5px;
        height: 14px;
        display: flex;
    }

    .maxEnergyLabel {
        color: white;
        background-color: darkgrey;
        font-weight: bold;
        font-size: 10px;
        letter-spacing: 0.16px;
        padding: 1px 10px;
        height: 14px;
        display: flex;
        justify-content: flex-start;
    }

    /* emission colors */

    .Aplus {
        background-color: #009036;
    }

    .A {
        background-color: #009642;
    }

    .B {
        background-color: #52ae34;
    }

    .C {
        background-color: #c6d300;
    }

    .D {
        background-color: #ffed00;
    }

    .E {
        background-color: #edae00;
    }

    .F {
        background-color: #ea670b;
    }

    .G {
        background-color: #e20c15;
    }


</style>
