import Vue from 'vue'
import Vuex from 'vuex'
import client from 'api-client'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        parameters: {},
        endpoints: {},
        filterSettings: {},
        labels: {},
        settings: {},
        validity: {},
        offers: [],
        bucketStats: {},
        modelRangeOffers: [],
    },
    getters: {
        parameters: state => {
            return state.parameters
        },
        settings: state => {
            return state.settings
        },
        endpoints: state => {
            return state.endpoints
        },
        filterSettings: state => {
            return state.filterSettings
        },
        labels: state => {
            return state.labels
        },
    },
    mutations: {
        setSettingsUrl(state, value) {
            state.endpoints = {...state.endpoints, 'settingsUrl': value}
        },
        setValidityUrl(state, value) {
            state.endpoints = {...state.endpoints, 'validityUrl': value}
        },
        setLabelsUrl(state, value) {
            state.endpoints = {...state.endpoints, 'labelsUrl': value}
        },
        setFilterUrl(state, value) {
            state.endpoints = {...state.endpoints, 'filterUrl': value}
        },
        setBucketUrl(state, value) {
            state.endpoints = {...state.endpoints, 'bucketUrl': value}
        },
        setBaseUrl(state, value) {
            state.parameters = {...state.parameters, 'baseUrl': value}
        },
        setAccessToken(state, value) {
            state.parameters = {...state.parameters, 'accessToken': value}
        },
        setCubeVersion(state, value) {
            state.parameters = {...state.parameters, 'cubeVersion': value}
        },
        setMarket(state, value) {
            state.parameters = {...state.parameters, 'market': value}
        },
        setRegion(state, value) {
            state.parameters = {...state.parameters, 'region': value}
        },
        setProvince(state, value) {
            state.parameters = {...state.parameters, 'province': value}
        },
        setBrand(state, value) {
            state.parameters = {...state.parameters, 'brand': value}
        },
        setLanguage(state, value) {
            state.parameters = {...state.parameters, 'language': value}
        },
        setChannel(state, value) {
            state.parameters = {...state.parameters, 'channel': value}
        },
        setSessionStorage(state, value) {
            state.parameters = {...state.parameters, 'sessionStorage': value}
        },
        setIndex(state, value) {
            state.parameters = {...state.parameters, 'routeInformation': {
                    'index':value
                }}
        },
        setModelRange(state, value) {
            state.parameters.routeInformation = {...state.parameters.routeInformation, 'modelRange': value}
        },
        setModelSeries(state, value) {
            state.parameters.routeInformation = {...state.parameters.routeInformation, 'modelSeries': value}
        },
        setRangeDescription(state, value) {
            state.parameters.routeInformation = {...state.parameters.routeInformation, 'rangeDescription': value}
        },
        setModelImageMobile(state, value) {
            state.parameters.routeInformation = {...state.parameters.routeInformation, 'modelImageMobile': value}
        },
        setModelImageDesktop(state, value) {
            state.parameters.routeInformation = {...state.parameters.routeInformation, 'modelImageDesktop': value}
        },
        setSettings(state, settings) {
            state.settings = settings
        },
        setValidity(state, validity) {
            state.validity = validity
        },
        setLabels(state, labels) {
            state.labels = labels
        },
        setOffers(state, offers) {
            state.offers = offers
        },
        setBucketStats(state, bucketStats) {
            state.bucketStats = bucketStats
        },
        setModelRangeOffers(state, modelRangeOffers) {
            state.modelRangeOffers = modelRangeOffers
        },
        setBaseProduct(state, value) {
            state.filterSettings = {...state.filterSettings, 'baseProduct': value}
        },
        setSelectedTab(state, value) {
            state.filterSettings = {...state.filterSettings, 'selectedTab': value}
        },
        setSelectedProduct(state, value) {
            state.filterSettings = {...state.filterSettings, 'selectedProduct': value}
        },
        setSegments(state, value) {
            state.filterSettings = {...state.filterSettings, 'segments': value}
        },
        setTerm(state, value) {
            state.filterSettings = {...state.filterSettings, 'trm': value}
        },
        setMileage(state, value) {
            state.filterSettings = {...state.filterSettings, 'mil': value}
        },
        setDeposit(state, value) {
            state.filterSettings = {...state.filterSettings, 'dep': value}
        },
        setTermProduct1001(state, value) {
            state.filterSettings = {...state.filterSettings, 'trm1001': value}
        },
        setDepositProduct1001(state, value) {
            state.filterSettings = {...state.filterSettings, 'dep1001': value}
        },
        setMileageProduct1001(state, value) {
            state.filterSettings = {...state.filterSettings, 'mil1001': value}
        },
        setTermProduct1002(state, value) {
            state.filterSettings = {...state.filterSettings, 'trm1002': value}
        },
        setDepositProduct1002(state, value) {
            state.filterSettings = {...state.filterSettings, 'dep1002': value}
        },
        setTermProduct1004(state, value) {
            state.filterSettings = {...state.filterSettings, 'trm1004': value}
        },
        setDepositProduct1004(state, value) {
            state.filterSettings = {...state.filterSettings, 'dep1004': value}
        },
        setMinInstallment(state, value) {
            state.filterSettings = {...state.filterSettings, 'min': value}
        },
        setMaxInstallment(state, value) {
            state.filterSettings = {...state.filterSettings, 'max': value}
        },
        setInstallmentRange(state, value) {
            state.filterSettings = {...state.filterSettings, 'initialRange': value}
        },
        setLoading(state, value) {
            state.filterSettings = {...state.filterSettings, 'loading': value}
        },
    },
    actions: {
        fetchSettings({commit}, settingsUrl) {
            return client
                .fetchSettings(settingsUrl)
                .then(settings => commit('setSettings', settings))
        },
        fetchValidity({commit}, validityUrl) {
            return client
                .fetchValidity(validityUrl)
                .then(validity => commit('setValidity', validity))
        },
        fetchLabels({commit}, labelsUrl) {
            return client
                .fetchLabels(labelsUrl)
                .then(labels => commit('setLabels', labels))
        },
        fetchOffers({commit}, filterUrl) {
            return client
                .fetchOffers(filterUrl)
                .then(offers => commit('setOffers', offers))
        },
        fetchBucketStats({commit}, bucketUrl) {
            return client
                .fetchBucketStats(bucketUrl)
                .then(bucketStats => commit('setBucketStats', bucketStats))
        },
        fetchModelRangeOffers({commit}, filterUrl) {
            return client
                .fetchOffers(filterUrl)
                .then(modelRangeOffers => commit('setModelRangeOffers', modelRangeOffers))
        },
    }
})
