import axios from 'axios'

export default {
    async fetchSettings (settingsUrl) {
        let accessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHAiOiJhY3MifQ.-q83V-RvodyKuerRLEV_Q4mTY8NVUJrGmpuU226m1Z0'

        return await axios
            .get(settingsUrl,
            {
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            })
            .then(response => response.data)
            .catch(e => {
                console.log(e)
            })
    },
    async fetchValidity (validityUrl) {
        let accessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHAiOiJhY3MifQ.-q83V-RvodyKuerRLEV_Q4mTY8NVUJrGmpuU226m1Z0'

        return await axios
            .get(validityUrl,
                {
                    headers: {
                        Authorization: 'Bearer ' + accessToken
                    }
                })
            .then(response => response.data)
            .catch(e => {
                console.log(e)
            })
    },
    async fetchLabels (labelsUrl) {
        let accessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHAiOiJhY3MifQ.-q83V-RvodyKuerRLEV_Q4mTY8NVUJrGmpuU226m1Z0'

        return await axios
            .get(labelsUrl,
                {
                    headers: {
                        Authorization: 'Bearer ' + accessToken
                    }
                })
            .then(response => response.data)
            .catch(e => {
                console.log(e)
            })
    },
    async fetchOffers (filterUrl) {
        let accessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHAiOiJhY3MifQ.-q83V-RvodyKuerRLEV_Q4mTY8NVUJrGmpuU226m1Z0'

        return await axios
            .get(filterUrl,
                {
                    headers: {
                        Authorization: 'Bearer ' + accessToken
                    }
                })
            .then(response => response.data)
            .catch(e => {
                console.log(e)
            })
    },
    async fetchBucketStats (bucketUrl) {
        let accessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHAiOiJhY3MifQ.-q83V-RvodyKuerRLEV_Q4mTY8NVUJrGmpuU226m1Z0'

        return await axios
            .get(bucketUrl,
                {
                    headers: {
                        Authorization: 'Bearer ' + accessToken
                    }
                })
            .then(response => response.data)
            .catch(e => {
                console.log(e)
            })
    },
}
