<template>
    <div>
        <p v-if="!modelRange.hybridCodes.includes('BEVE')">{{ labels.mainPage.vehiclesList.fuel }} {{
            modelRange.fuelTypes | replaceFuelType(modelRange.fuelTypes) }}</p>
        <p v-if="modelRange.hybridCodes.includes('BEVE')">{{ labels.mainPage.vehiclesList.fuel }} {{
            labels.engineSelectionPage.fuelTypes.electric }}</p>
        <p v-if="modelRange.hybridCodes.includes('NOHY') || modelRange.hybridCodes.includes('HYBR') || parameters.brand == 'bmwBike'">
                            <span>{{ labels.mainPage.vehiclesList.power }} {{ modelRange.powerEngine.engineKWMin}} {{
                            labels.engineDetailFormat.powerkW}} ({{ modelRange.powerEngine.enginePSMin | calculatePS(modelRange.powerEngine.engineKWMin) }} {{
                            labels.engineDetailFormat.powerPS}})</span>
            <span v-if="modelRange.powerEngine.engineKWMin != modelRange.powerEngine.engineKWMax"> - </span>
            <span v-if="modelRange.powerEngine.engineKWMin != modelRange.powerEngine.engineKWMax">{{ modelRange.powerEngine.engineKWMax}} {{
                            labels.engineDetailFormat.powerkW}} ({{ modelRange.powerEngine.enginePSMax | calculatePS(modelRange.powerEngine.engineKWMax) }} {{
                            labels.engineDetailFormat.powerPS}})</span>
        </p>
        <p v-if="modelRange.hybridCodes.includes('BEVE')">
                            <span>{{ labels.mainPage.vehiclesList.power }} {{ modelRange.powerElectric.electricKWMin}} {{
                            labels.engineDetailFormat.powerkW}} ({{ modelRange.powerElectric.electricPSMin}} {{
                            labels.engineDetailFormat.powerPS}})</span>
            <span v-if="modelRange.powerElectric.electricKWMin != modelRange.powerElectric.electricKWMax"> - </span>
            <span v-if="modelRange.powerElectric.electricKWMin != modelRange.powerElectric.electricKWMax">{{ modelRange.powerElectric.electricKWMax}} {{
                            labels.engineDetailFormat.powerkW}} ({{ modelRange.powerElectric.electricPSMax}} {{
                                labels.engineDetailFormat.powerPS}})</span></p>
        <div class="info-row" v-if="!modelRange.hybridCodes.includes('BEVE') && parameters.brand != 'bmwBike'">
            <div class="info-text">
                {{ labels.mainPage.vehiclesList.fuelConsumption }} {{
                modelRange.consumptionNEFZ.fuelConsumptionMin | formatValue('Float')}}
            </div>
            <div class="info-text"
                 v-if="modelRange.consumptionNEFZ.fuelConsumptionMin != modelRange.consumptionNEFZ.fuelConsumptionMax">
                <span class="seperator"> - </span>
            </div>
            <div class="info-text"
                 v-if="modelRange.consumptionNEFZ.fuelConsumptionMin != modelRange.consumptionNEFZ.fuelConsumptionMax">
                {{
                modelRange.consumptionNEFZ.fuelConsumptionMax | formatValue('Float')}}
            </div>
            <div class="info-text">
                {{
                labels.engineDetailFormat.fuelConsumptionAmount}}
            </div>
            <EfficiencyInformationPage :efficiencyData="efficiencyData(modelRange)"
                                       origin="model"></EfficiencyInformationPage>
        </div>
        <div class="info-row" v-if="parameters.brand != 'bmwBike'">
            <div class="info-text" v-if="!modelRange.hybridCodes.includes('BEVE')">
                {{ labels.mainPage.vehiclesList.emissions }} {{
                modelRange.emissionNEFZ.emissionCO2Min | formatValue('Integer')}}
            </div>
            <div class="info-text seperator"
                 v-if="!modelRange.hybridCodes.includes('BEVE') && modelRange.emissionNEFZ.emissionCO2Min != modelRange.emissionNEFZ.emissionCO2Max">
                -
            </div>
            <div class="info-text"
                 v-if="!modelRange.hybridCodes.includes('BEVE') && modelRange.emissionNEFZ.emissionCO2Min != modelRange.emissionNEFZ.emissionCO2Max">
                {{
                modelRange.emissionNEFZ.emissionCO2Max | formatValue('Integer')}}
            </div>
            <div class="info-text" v-if="!modelRange.hybridCodes.includes('BEVE')">
                {{labels.engineDetailFormat.emissionsAmount}}
            </div>
            <div class="info-text" v-if="modelRange.hybridCodes.includes('BEVE')">
                {{ labels.mainPage.vehiclesList.emissions }} 0 {{
                labels.engineDetailFormat.emissionsAmount}}
            </div>
            <div class="info-text minEnergyLabel"
                 :class="modelRange.emissionNEFZ.emissionLabelMin">
                {{ modelRange.emissionNEFZ.emissionLabelMin }}
            </div>
            <div class="info-text"
                 v-if="modelRange.emissionNEFZ.emissionLabelMin !== modelRange.emissionNEFZ.emissionLabelMax">
                -
            </div>
            <div class="info-text maxEnergyLabel"
                 :class="modelRange.emissionNEFZ.emissionLabelMax"
                 v-if="modelRange.emissionNEFZ.emissionLabelMin !== modelRange.emissionNEFZ.emissionLabelMax">
                {{
                modelRange.emissionNEFZ.emissionLabelMax }}
            </div>
            <EfficiencyInformationPage :efficiencyData="efficiencyData(modelRange)"
                                       origin="model"></EfficiencyInformationPage>
        </div>
        <div class="info-row" v-if="modelRange.hybridCodes.includes('PHEV')||modelRange.hybridCodes.includes('BEVE')">
            <div class="info-text">
                {{ labels.mainPage.vehiclesList.electricConsumption }} {{
                modelRange.consumptionNEFZ.electricConsumptionMin | formatValue('Float')}}
            </div>
            <div class="info-text seperator"
                 v-if="modelRange.consumptionNEFZ.electricConsumptionMin != modelRange.consumptionNEFZ.electricConsumptionMax">
                -
            </div>
            <div class="info-text"
                 v-if="modelRange.consumptionNEFZ.electricConsumptionMin != modelRange.consumptionNEFZ.electricConsumptionMax">
                {{
                modelRange.consumptionNEFZ.electricConsumptionMax | formatValue('Float')}}
            </div>
            <div class="info-text">
                {{
                labels.engineDetailFormat.electricConsumptionAmount}}
            </div>
            <EfficiencyInformationPage :efficiencyData="efficiencyData(modelRange)"
                                       origin="model"></EfficiencyInformationPage>
        </div>
    </div>
</template>

<script>
    import EngineSelectionPage from "./EngineSelectionPage";
    import EngineSelectionPageElectric from "./EngineSelectionPageElectric";
    import EngineSelectionPageMoto from "./MotoOfferDetails";
    import EfficiencyInformationPage from "./EfficiencyInformationPage";

    export default {
        name: 'VehicleGridItemEfficiency',
        components: {
            EfficiencyInformationPage
        },
        props: {
            modelRange: {},
        },
        data() {
            return {}
        },
        computed: {
            labels() {
                return this.$store.state.labels
            },
            parameters() {
                return this.$store.state.parameters
            },
            modelStatsOverview() {
                return this.$store.state.bucketStats.modelStatsOverview
            },
            bucketStats() {
                return this.$store.state.bucketStats
            },
        },
        created() {
        },
        methods: {
            infoIconBtnClick() {
                console.log('Icon clicked: Info Icon')
            },
            efficiencyData(modelRange) {
                let efficiencyData = {
                    'title': modelRange.rangeDescription,
                    'line': '',
                    'hybridCodes': modelRange.hybridCodes,
                    'consumptionNEFZ': {
                        'fuelConsumptionNEFZMin': modelRange.consumptionNEFZ.fuelConsumptionMin,
                        'fuelConsumptionNEFZMax': modelRange.consumptionNEFZ.fuelConsumptionMax,
                        'electricConsumptionNEFZMin': modelRange.consumptionNEFZ.electricConsumptionMin,
                        'electricConsumptionNEFZMax': modelRange.consumptionNEFZ.electricConsumptionMax,
                    },
                    'emissionNEFZ': {
                        'emissionNEFZMin': modelRange.emissionNEFZ.emissionCO2Min,
                        'emissionNEFZMax': modelRange.emissionNEFZ.emissionCO2Max,
                        'minEnergyLabel': modelRange.emissionNEFZ.emissionLabelMin,
                        'maxEnergyLabel': modelRange.emissionNEFZ.emissionLabelMax,
                    },
                    'rangeNEFZ': {
                        'electricRangeNEFZMin': modelRange.rangeNEFZ.electricRangeMin,
                        'electricRangeNEFZMax': modelRange.rangeNEFZ.electricRangeMax,
                    },
                    'consumptionWLTP': {
                        'fuelConsumptionWLTPMin': modelRange.consumptionWLTP.fuelConsumptionMin,
                        'fuelConsumptionWLTPMax': modelRange.consumptionWLTP.fuelConsumptionMax,
                        'electric': {
                            'electricConsumptionWLTPMin': modelRange.consumptionWLTP.electric.electricConsumptionMin,
                            'electricConsumptionWLTPMax': modelRange.consumptionWLTP.electric.electricConsumptionMax,
                        },
                        'hybrid': {
                            'fuelConsumptionWeightedWLTPMin': modelRange.consumptionWLTP.hybrid.fuelConsumptionWeightedMin,
                            'fuelConsumptionWeightedWLTPMax': modelRange.consumptionWLTP.hybrid.fuelConsumptionWeightedMax,
                            'electricConsumptionAcWeightedWLTPMin': modelRange.consumptionWLTP.hybrid.electricConsumptionAcWeightedMin,
                            'electricConsumptionAcWeightedWLTPMax': modelRange.consumptionWLTP.hybrid.electricConsumptionAcWeightedMax,
                        }
                    },
                    'emissionWLTP': {
                        'emissionWLTPMin': modelRange.emissionWLTP.emissionCO2Min,
                        'emissionWLTPMax': modelRange.emissionWLTP.emissionCO2Max,
                        'hybrid': {
                            'co2WeightedWLTPMin': modelRange.emissionWLTP.hybrid.co2WeightedMin,
                            'co2WeightedWLTPMax': modelRange.emissionWLTP.hybrid.co2WeightedMax,
                        },
                    },
                    'rangeWLTP': {
                        'electric': {
                            'pureElectricRangeWLTPMin': modelRange.rangeWLTP.electric.pureElectricRangeMin,
                            'pureElectricRangeWLTPMax': modelRange.rangeWLTP.electric.pureElectricRangeMax,
                        },
                        'hybrid': {
                            'equivalentAllElectricRangeWLTPMin': modelRange.rangeWLTP.hybrid.equivalentAllElectricRangeMin,
                            'equivalentAllElectricRangeWLTPMax': modelRange.rangeWLTP.hybrid.equivalentAllElectricRangeMax,
                            'equivalentAllElectricRangeCityWLTPMin': modelRange.rangeWLTP.hybrid.equivalentAllElectricRangeCityMin,
                            'equivalentAllElectricRangeCityWLTPMax': modelRange.rangeWLTP.hybrid.equivalentAllElectricRangeCityMax,
                        }
                    },
                }
                return efficiencyData
            },
            modelNVehicles(modelRange) {
                const filteredModelNOffers = this.seriesNOffers.filter((offer) => {
                    return offer.vehicleInformation.modelRange.includes(modelRange);
                })
                const modelNVehicles = [];
                const vehicles = new Map();
                for (const offer of filteredModelNOffers) {
                    if (!vehicles.has(offer.vehicleInformation.vehicleId)) {
                        vehicles.set(offer.vehicleInformation.vehicleId, true);
                        modelNVehicles.push({
                            id: offer.vehicleInformation.vehicleId,
                            data: offer
                        });
                    }
                }
                return modelNVehicles;
            },
        },
        filters: {
            formatValue: function (value, type) {
                if (type == 'Integer') {
                    return parseInt(value)
                }
                if (type == 'Float') {
                    return parseFloat(value).toFixed(1).replace('.', ',')
                }
                if (type == 'Percentage') {
                    value = parseFloat(value * 100).toFixed(2)
                    value = value.replace('.', ',')
                    return value + ' %'
                }
                if (type == 'Currency') {
                    value = parseFloat(value).toFixed(2)
                    value = value.replace('.', ',')
                    let splitValue = value.split(',')
                    if (splitValue[0].length > 3) {
                        let thousands = splitValue[0].slice(0, -3)
                        let hundreds = splitValue[0].slice(-3)
                        return thousands + '.' + hundreds + ',' + splitValue[1] + ' ' + labels.financeParameterFormat.currencySymbol
                    }
                    return value + ' €'
                }
            },
            calculatePS: function (value, kW) {
                return parseInt(kW * 1.35962)
            },
            replaceFuelType: function (value, fuelType) {
                value = value.replace('O', 'Benzin')
                value = value.replace('E', 'Voll-Elektrisch')
                return value
            }
        }
    }
</script>

<style scoped>
    .tile {
        display: block;
        width: 270px;
        margin-right: 10px;
    }

    .flex {
        min-width: 270px;
    }

    .modelDetails {
        margin-bottom: 25px;
        padding-right: 15px;
        height: 100px;
    }

    img {
        width: 270px;
        display: block;
        margin: 5px 0px;
    }

    h3 {
        word-wrap: break-word;
        white-space: normal;
        margin-bottom: 5px;
        width: 250px;
    }

    p, span {
        font-size: 12px;
        letter-spacing: 0.16px;
        margin-bottom: 3px;
    }

    .header {
        height: 200px;
        margin-top: 25px;
    }

    .transmissions {
        font-weight: bold;
        color: #8E8E8E;
        margin-top: 5px;
        margin-bottom: 10px;
    }

    .vehicleDetails {
        margin-bottom: 25px;
        padding-right: 15px;
        height: 140px;
    }

    .info-row {
        min-height: 12px;
        display: flex;
        align-items: center;
        margin-bottom: 3px;
    }

    .info-text {
        min-height: 12px;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        letter-spacing: 0.16px;
    }

    .info-icon {
        height: 12px;
        width: 12px;
        min-width: 12px;
        justify-content: center;
        stroke: none;
        vertical-align: middle;
        margin-right: 15px;
        display: flex;
        align-items: center;
    }

    .seperator {
        margin: 0px 2px;
    }

    .emission {
        background-color: green;
    }

    #mobile .engineSelectionButton {
        display: block;
        margin-top: 10px;
    }

    .minEnergyLabel {
        color: white;
        background-color: darkgrey;
        font-weight: bold;
        font-size: 8px;
        letter-spacing: 0.16px;
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 0.7%;
        margin-left: 5px;
        margin-right: 2px;
        height: 10px;
        display: flex;
    }

    .maxEnergyLabel {
        color: white;
        background-color: darkgrey;
        font-weight: bold;
        font-size: 8px;
        letter-spacing: 0.16px;
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 0.7%;
        margin-left: 2px;
        height: 5px;
        display: flex;
    }

    /* emission colors */

    .Aplus {
        background-color: #009036;
    }

    .A {
        background-color: #009642;
    }

    .B {
        background-color: #52ae34;
    }

    .C {
        background-color: #c6d300;
    }

    .D {
        background-color: #ffed00;
    }

    .E {
        background-color: #edae00;
    }

    .F {
        background-color: #ea670b;
    }

    .G {
        background-color: #e20c15;
    }
</style>
