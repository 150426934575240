<template>
    <div data-app="true" v-if="labels.mainPage">
        <div id="productSpecificSelection" v-if="bucketStats">
            <h2>{{ labels.mainPage.productSelection.productSelectionTitle }}</h2>
            <p>{{ labels.mainPage.productSelection.productSelectionSubTitle }}</p>
            <p class="p-last">{{ labels.mainPage.productSelection.productSelectionText }}</p>
            <v-tabs v-model="selectedTab"
                    background-color="transparent"
                    grow
                    show-arrows
                    v-if="bucketStats.financeProducts"
                    id="tabSelection">
                <v-tab v-if="bucketStats.financeProducts.product1001" key="1001">{{
                    bucketStats.financeProducts.product1001.productName }}
                    <v-tooltip :open-on-hover="true" :open-on-click="false" bottom class="tooltip">
                        <template v-slot:activator="{ on, attrs }">
                            <div class="info-icon" v-bind="attrs" v-on="on">
                                <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" focusable="false"
                                     style="pointer-events: none; display: block; width: 100%; height: 100%;">
                                    <g fill="none" fill-rule="evenodd" viewBox="0 0 24 24">
                                        <circle cx="12" cy="12" r="11" stroke="#BBB" stroke-width="2"></circle>
                                        <path fill="#4D4D4D"
                                              d="M11 16.09h1.818v-5.555H11v5.556zm0-7.272h1.818V7H11v1.818z"></path>
                                    </g>
                                </svg>
                            </div>
                        </template>
                        <span v-html="bucketStats.financeProducts.product1001.productDescription"></span>
                    </v-tooltip>
                </v-tab>
                <v-tab v-if="bucketStats.financeProducts.product1002" key="1002">{{
                    bucketStats.financeProducts.product1002.productName }}
                    <v-tooltip :open-on-hover="true" :open-on-click="false" bottom class="tooltip">
                        <template v-slot:activator="{ on, attrs }">
                            <div class="info-icon" v-bind="attrs" v-on="on">
                                <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" focusable="false"
                                     style="pointer-events: none; display: block; width: 100%; height: 100%;">
                                    <g fill="none" fill-rule="evenodd" viewBox="0 0 24 24">
                                        <circle cx="12" cy="12" r="11" stroke="#BBB" stroke-width="2"></circle>
                                        <path fill="#4D4D4D"
                                              d="M11 16.09h1.818v-5.555H11v5.556zm0-7.272h1.818V7H11v1.818z"></path>
                                    </g>
                                </svg>
                            </div>
                        </template>
                        <span v-html="bucketStats.financeProducts.product1002.productDescription"></span>
                    </v-tooltip>
                </v-tab>
                <v-tab v-if="bucketStats.financeProducts.product1004" key="1004">{{
                    bucketStats.financeProducts.product1004.productName }}
                    <v-tooltip :open-on-hover="true" :open-on-click="false" bottom class="tooltip">
                        <template v-slot:activator="{ on, attrs }">
                            <div class="info-icon" v-bind="attrs" v-on="on">
                                <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" focusable="false"
                                     style="pointer-events: none; display: block; width: 100%; height: 100%;">
                                    <g fill="none" fill-rule="evenodd" viewBox="0 0 24 24">
                                        <circle cx="12" cy="12" r="11" stroke="#BBB" stroke-width="2"></circle>
                                        <path fill="#4D4D4D"
                                              d="M11 16.09h1.818v-5.555H11v5.556zm0-7.272h1.818V7H11v1.818z"></path>
                                    </g>
                                </svg>
                            </div>
                        </template>
                        <span v-html="bucketStats.financeProducts.product1004.productDescription"></span>
                    </v-tooltip>
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="selectedTab" v-if="bucketStats.financeProducts">
                <v-tab-item v-if="bucketStats.financeProducts.product1001">
                    <v-card flat>
                        <v-card-text>
                            <div v-if="labels.mainPage">
                                <v-layout>
                                    <v-select :items="termValues1001"
                                              item-text="label"
                                              item-value="value"
                                              :label="termLabel"
                                              outlined
                                              dense
                                              color="#1C69D4"
                                              class="financeParameter"
                                              v-model="productValues1001.trm"
                                    ></v-select>
                                </v-layout>
                                <v-layout>
                                    <v-select :items="depositValues"
                                              item-text="label"
                                              item-value="value"
                                              :label="labels.mainPage.adjustFinancePage.adjustFinanceOptions.adjustFinanceOptionDeposit"
                                              outlined
                                              dense
                                              color="#1C69D4"
                                              class="financeParameter"
                                              v-model="productValues1001.dep"
                                    ></v-select>
                                </v-layout>
                                <v-layout>
                                    <v-select :items="mileageValues"
                                              item-text="label"
                                              item-value="value"
                                              :label="labels.mainPage.adjustFinancePage.adjustFinanceOptions.adjustFinanceOptionMileageUnit"
                                              outlined
                                              dense
                                              color="#1C69D4"
                                              class="financeParameter"
                                              v-model="productValues1001.mil"
                                    ></v-select>
                                </v-layout>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item v-if="bucketStats.financeProducts.product1002">
                    <v-card flat>
                        <v-card-text>
                            <div v-if="labels.mainPage">
                                <v-layout>
                                    <v-select :items="termValues1002"
                                              item-text="label"
                                              item-value="value"
                                              :label="termLabel"
                                              outlined
                                              dense
                                              color="#1C69D4"
                                              class="financeParameter"
                                              v-model="productValues1002.trm"
                                    ></v-select>
                                </v-layout>
                                <v-layout>
                                    <v-select :items="depositValues"
                                              item-text="label"
                                              item-value="value"
                                              :label="labels.mainPage.adjustFinancePage.adjustFinanceOptions.adjustFinanceOptionDeposit"
                                              outlined
                                              dense
                                              color="#1C69D4"
                                              class="financeParameter"
                                              v-model="productValues1002.dep"
                                    ></v-select>
                                </v-layout>
                                <v-layout>
                                    <v-select :items="mileageValues"
                                              item-text="label"
                                              item-value="value"
                                              :label="labels.mainPage.adjustFinancePage.adjustFinanceOptions.adjustFinanceOptionMileageUnit"
                                              outlined
                                              dense
                                              disabled
                                              color="#1C69D4"
                                              class="financeParameter"
                                              v-model="productValues1002.mil"
                                    ></v-select>
                                </v-layout>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item v-if="bucketStats.financeProducts.product1004">
                    <v-card flat>
                        <v-card-text>
                            <div v-if="labels.mainPage">
                                <v-layout>
                                    <v-flex md12 sm12 xs12>
                                        <v-select :items="termValues1004"
                                                  item-text="label"
                                                  item-value="value"
                                                  :label="termLabel"
                                                  outlined
                                                  dense
                                                  color="#1C69D4"
                                                  class="financeParameter"
                                                  v-model="productValues1004.trm"
                                        ></v-select>
                                    </v-flex>
                                </v-layout>
                                <v-layout>
                                    <v-flex md12 sm12 xs12>
                                        <v-select :items="depositValues"
                                                  item-text="label"
                                                  item-value="value"
                                                  :label="labels.mainPage.adjustFinancePage.adjustFinanceOptions.adjustFinanceOptionDeposit"
                                                  outlined
                                                  dense
                                                  color="#1C69D4"
                                                  class="financeParameter"
                                                  v-model="productValues1004.dep"
                                        ></v-select>
                                    </v-flex>
                                </v-layout>
                                <v-layout>
                                    <v-flex md12 sm12 xs12>
                                        <v-select :items="mileageValues"
                                                  item-text="label"
                                                  item-value="value"
                                                  :label="labels.mainPage.adjustFinancePage.adjustFinanceOptions.adjustFinanceOptionMileageUnit"
                                                  outlined
                                                  dense
                                                  disabled
                                                  color="#1C69D4"
                                                  class="financeParameter"
                                                  v-model="productValues1004.mil"
                                        ></v-select>
                                    </v-flex>
                                </v-layout>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </div>
    </div>

</template>

<script>
    export default {
        name: 'MainFilterProductSpecific',
        components: {},
        props: {
            initialTab: null,
            trm1001: null,
            dep1001: null,
            mil1001: null,
            trm1002: null,
            dep1002: null,
            trm1004: null,
            dep1004: null,
        },
        data() {
            return {
                selectedTab: this.initialTab || 0,
                filterParameters() {
                    if (this.filterSettings.selectedProduct == "1001") {
                        return {
                            'trm': 48,
                            'dep': 5000,
                            'mil': 16000
                        }
                    } else if (this.filterSettings.selectedProduct == "1002") {
                        return {
                            'trm': 60,
                            'dep': 5000,
                            'mil': ''
                        }
                    } else if (this.filterSettings.selectedProduct == "1004") {
                        return {
                            'trm': 60,
                            'dep': 5000,
                            'mil': ''
                        }
                    }
                },
                productValues1001: {
                    'trm': this.trm1001 || 48,
                    'dep': this.dep1001 || 5000,
                    'mil': this.mil1001 || 16000
                },
                productValues1002: {
                    'trm': this.trm1002 || 60,
                    'dep': this.dep1002 || 5000,
                    'mil': ''
                },
                productValues1004: {
                    'trm': this.trm1004 || 60,
                    'dep': this.dep1004 || 5000,
                    'mil': ''
                },
            }
        },
        computed: {
            labels() {
                return this.$store.state.labels
            },
            termLabel() {
                if (this.isUnitCompatible(1234)) {
                    return this.labels.mainPage.adjustFinancePage.adjustFinanceOptions.adjustFinanceOptionTerm
                }
                else {
                    return this.labels.mainPage.adjustFinancePage.adjustFinanceOptions.adjustFinanceOptionTermUnit
                }
            },
            parameters() {
                return this.$store.state.parameters
            },
            bucketStats() {
                return this.$store.state.bucketStats
            },
            filterSettings() {
                return this.$store.state.filterSettings
            },
            selectedProduct() {
                if (this.selectedTab == 0) {
                    return '1001'
                } else if (this.selectedTab == 1) {
                    return '1002'
                } else if (this.selectedTab == 2) {
                    return '1004'
                }
            },
            termValues1001() {
                let termValues = []
                let terms = [
                    24,
                    36,
                    39,
                    42,
                    48]
                for (const term of terms
                    ) {
                    termValues.push({
                        'label': this.formatValue(term, 'term', this.parameters.language, this.parameters.market),
                        'value': term
                    })
                }
                return termValues
            },
            termValues1002() {
                let termValues = []
                let terms = [
                    24,
                    36,
                    48,
                    60,
                    72,
                    84]
                for (const term of terms
                    ) {
                    termValues.push({
                        'label': this.formatValue(term, 'term', this.parameters.language, this.parameters.market),
                        'value': term
                    })
                }
                return termValues
            },
            termValues1004() {
                let termValues = []
                let terms = [
                    24,
                    36,
                    48,
                    60]
                for (const term of terms
                    ) {
                    termValues.push({
                        'label': this.formatValue(term, 'term', this.parameters.language, this.parameters.market),
                        'value': term
                    })
                }
                return termValues
            },
            depositValues() {
                let depositValues = []
                let deposits = [
                    0,
                    1000,
                    2000,
                    3000,
                    4000,
                    5000,
                    6000,
                    7000,
                    8000,
                    9000,
                    10000]
                for (const deposit of deposits
                    ) {
                    depositValues.push({
                        'label': this.formatValue(deposit, 'currencyInteger', this.parameters.language, this.parameters.market),
                        'value': deposit
                    })
                }
                return depositValues
            },
            mileageValues() {
                let mileageValues = []
                let mileages = [
                    12000,
                    16000,
                    20000,
                    24000]
                for (const mileage of mileages
                    ) {
                    mileageValues.push({
                        'label': this.formatValue(mileage, 'mileage', this.parameters.language, this.parameters.market),
                        'value': mileage
                    })
                }
                return mileageValues
            }
        },
        created() {
        }
        ,
        methods: {
            isUnitCompatible: function(value) {
                try {
                    this.formatValue(value, 'term', this.parameters.language, this.parameters.market)
                }
                catch(e) {
                    console.log(e)
                    return false
                }
                return true
            },
            formatValue: function (value, type, language, market) {
                if (!value) {
                    return ''
                }
                let locale = language + '-' + market.toUpperCase()
                let currency = (function () {
                    switch (locale) {
                        case 'de-DE':
                            return 'EUR'
                        case 'en-CA':
                            return 'CAD'
                        case 'fr-CA':
                            return 'CAD'
                    }
                })('EUR')
                if (type == 'currency') {
                    return new Intl.NumberFormat(locale, {style: 'currency', currency: currency}).format(value)
                }
                if (type == 'currencyInteger') {
                    return new Intl.NumberFormat(locale, {
                        style: 'currency',
                        currency: currency,
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                    }).format(value)
                }
                if (type == 'mileage') {
                    try {
                        return new Intl.NumberFormat(locale, {
                            style: 'unit',
                            unit: 'kilometer',
                        }).format(value)
                    } catch (e) {
                        console.log('Error: ' + e)
                        return value
                    }

                }
                if (type == 'term') {
                    try {
                        return new Intl.NumberFormat(locale, {
                            style: 'unit',
                            unit: 'month',
                        }).format(value)
                    } catch (e) {
                        console.log('Error: ' + e)
                        return value
                    }
                }
            },
            async updateOfferProduct1001() {
                let baseUrl = this.$store.getters.parameters.baseUrl
                let market = this.$store.getters.parameters.market
                let region = this.$store.getters.parameters.region
                let brand = this.$store.getters.parameters.brand
                let channel = this.$store.getters.parameters.channel
                let language = this.$store.getters.parameters.language
                let baseProduct = this.$store.getters.filterSettings.baseProduct
                let selectedProduct = this.selectedProduct
                this.$store.commit('setSelectedProduct', selectedProduct)
                let trm = this.productValues1001.trm
                this.$store.commit('setTermProduct1001', trm)
                this.$store.commit('setTerm', trm)
                let dep = this.productValues1001.dep
                this.$store.commit('setDepositProduct1001', dep)
                this.$store.commit('setDeposit', dep)
                let mil = this.productValues1001.mil
                this.$store.commit('setMileageProduct1001', mil)
                this.$store.commit('setMileage', mil)
                let min = this.$store.getters.filterSettings.min
                let max = this.$store.getters.filterSettings.max
                let bucketUrl = ''
                if (region != 0) {
                    bucketUrl = baseUrl + '/filter/bucket/market/' + market.toLowerCase() + ':' + region + '/brand/' + brand.toLowerCase() + '/channel/' + channel + '/language/' + language + '/trm/' + trm + '/mil/' + mil + '/dep/' + dep + '/min/' + min + '/max/' + max + '?productid=' + selectedProduct
                } else if (market == 'de') {
                    bucketUrl = baseUrl + '/filter/bucket/market/' + market.toLowerCase() + '/brand/' + brand.toLowerCase() + '/channel/' + channel + '/language/' + language + '/trm/' + trm + '/mil/' + mil + '/dep/' + dep + '/min/' + min + '/max/' + max + '?baseproduct=' + baseProduct
                } else {
                    bucketUrl = baseUrl + '/filter/bucket/market/' + market.toLowerCase() + '/brand/' + brand.toLowerCase() + '/channel/' + channel + '/language/' + language + '/trm/' + trm + '/mil/' + mil + '/dep/' + dep + '/min/' + min + '/max/' + max
                }
                this.$store.commit('setBucketUrl', bucketUrl)
                await this.$store.dispatch('fetchBucketStats', bucketUrl)
                this.$store.commit('setLoading', false)
            }
            ,
            async updateOfferProduct1002() {
                let baseUrl = this.$store.getters.parameters.baseUrl
                let market = this.$store.getters.parameters.market
                let region = this.$store.getters.parameters.region
                let brand = this.$store.getters.parameters.brand
                let channel = this.$store.getters.parameters.channel
                let language = this.$store.getters.parameters.language
                let baseProduct = this.$store.getters.filterSettings.baseProduct
                let selectedProduct = this.selectedProduct
                this.$store.commit('setSelectedProduct', selectedProduct)
                let trm = this.productValues1002.trm
                this.$store.commit('setTermProduct1002', trm)
                this.$store.commit('setTerm', trm)
                let dep = this.productValues1002.dep
                this.$store.commit('setDepositProduct1002', dep)
                this.$store.commit('setDeposit', dep)
                let mil = '0'
                let min = this.$store.getters.filterSettings.min
                let max = this.$store.getters.filterSettings.max
                let bucketUrl = ''
                if (region != 0) {
                    bucketUrl = baseUrl + '/filter/bucket/market/' + market.toLowerCase() + ':' + region + '/brand/' + brand.toLowerCase() + '/channel/' + channel + '/language/' + language + '/trm/' + trm + '/mil/' + mil + '/dep/' + dep + '/min/' + min + '/max/' + max + '?productid=' + selectedProduct
                } else if (market == 'de') {
                    bucketUrl = baseUrl + '/filter/bucket/market/' + market.toLowerCase() + '/brand/' + brand.toLowerCase() + '/channel/' + channel + '/language/' + language + '/trm/' + trm + '/mil/' + mil + '/dep/' + dep + '/min/' + min + '/max/' + max + '?baseproduct=' + baseProduct
                } else {
                    bucketUrl = baseUrl + '/filter/bucket/market/' + market.toLowerCase() + '/brand/' + brand.toLowerCase() + '/channel/' + channel + '/language/' + language + '/trm/' + trm + '/mil/' + mil + '/dep/' + dep + '/min/' + min + '/max/' + max
                }
                this.$store.commit('setBucketUrl', bucketUrl)
                await this.$store.dispatch('fetchBucketStats', bucketUrl)
                this.$store.commit('setLoading', false)
            }
            ,
            async updateOfferProduct1004() {
                let baseUrl = this.$store.getters.parameters.baseUrl
                let market = this.$store.getters.parameters.market
                let region = this.$store.getters.parameters.region
                let brand = this.$store.getters.parameters.brand
                let channel = this.$store.getters.parameters.channel
                let language = this.$store.getters.parameters.language
                let baseProduct = this.$store.getters.filterSettings.baseProduct
                let selectedProduct = this.selectedProduct
                this.$store.commit('setSelectedProduct', selectedProduct)
                let trm = this.productValues1004.trm
                this.$store.commit('setTermProduct1004', trm)
                this.$store.commit('setTerm', trm)
                let dep = this.productValues1004.dep
                this.$store.commit('setDepositProduct1004', dep)
                this.$store.commit('setDeposit', dep)
                let mil = '0'
                let min = this.$store.getters.filterSettings.min
                let max = this.$store.getters.filterSettings.max
                let bucketUrl = ''
                if (region != 0) {
                    bucketUrl = baseUrl + '/filter/bucket/market/' + market.toLowerCase() + ':' + region + '/brand/' + brand.toLowerCase() + '/channel/' + channel + '/language/' + language + '/trm/' + trm + '/mil/' + mil + '/dep/' + dep + '/min/' + min + '/max/' + max + '?productid=' + selectedProduct
                } else if (market == 'de') {
                    bucketUrl = baseUrl + '/filter/bucket/market/' + market.toLowerCase() + '/brand/' + brand.toLowerCase() + '/channel/' + channel + '/language/' + language + '/trm/' + trm + '/mil/' + mil + '/dep/' + dep + '/min/' + min + '/max/' + max + '?baseproduct=' + baseProduct
                } else {
                    bucketUrl = baseUrl + '/filter/bucket/market/' + market.toLowerCase() + '/brand/' + brand.toLowerCase() + '/channel/' + channel + '/language/' + language + '/trm/' + trm + '/mil/' + mil + '/dep/' + dep + '/min/' + min + '/max/' + max
                }
                this.$store.commit('setBucketUrl', bucketUrl)
                await this.$store.dispatch('fetchBucketStats', bucketUrl)
                this.$store.commit('setLoading', false)
            }
            ,
            async updateOfferParameters1001() {
                let baseUrl = this.$store.getters.parameters.baseUrl
                let market = this.$store.getters.parameters.market
                let region = this.$store.getters.parameters.region
                let brand = this.$store.getters.parameters.brand
                let channel = this.$store.getters.parameters.channel
                let language = this.$store.getters.parameters.language
                let baseProduct = this.$store.getters.filterSettings.baseProduct
                let selectedProduct = this.$store.getters.filterSettings.selectedProduct
                let trm = this.productValues1001.trm
                this.$store.commit('setTermProduct1001', trm)
                this.$store.commit('setTerm', trm)
                let dep = this.productValues1001.dep
                this.$store.commit('setDepositProduct1001', dep)
                this.$store.commit('setDeposit', dep)
                let mil = this.productValues1001.mil
                this.$store.commit('setMileageProduct1001', mil)
                this.$store.commit('setMileage', mil)
                let min = this.$store.getters.filterSettings.min
                let max = this.$store.getters.filterSettings.max
                let filterUrl = baseUrl + '/filter/dc/market/' + market.toLowerCase() + '/brand/' + brand.toLowerCase() + '/channel/' + channel + '/language/' + language + '/trm/' + trm + '/mil/' + mil + '/dep/' + dep + '/min/' + min + '/max/' + max + '?baseproduct=' + baseProduct
                this.$store.commit('setFilterUrl', filterUrl)
                let bucketUrl = ''
                if (region != 0) {
                    bucketUrl = baseUrl + '/filter/bucket/market/' + market.toLowerCase() + ':' + region + '/brand/' + brand.toLowerCase() + '/channel/' + channel + '/language/' + language + '/trm/' + trm + '/mil/' + mil + '/dep/' + dep + '/min/' + min + '/max/' + max + '?productid=' + selectedProduct
                } else if (market == 'de') {
                    bucketUrl = baseUrl + '/filter/bucket/market/' + market.toLowerCase() + '/brand/' + brand.toLowerCase() + '/channel/' + channel + '/language/' + language + '/trm/' + trm + '/mil/' + mil + '/dep/' + dep + '/min/' + min + '/max/' + max + '?baseproduct=' + baseProduct
                } else {
                    bucketUrl = baseUrl + '/filter/bucket/market/' + market.toLowerCase() + '/brand/' + brand.toLowerCase() + '/channel/' + channel + '/language/' + language + '/trm/' + trm + '/mil/' + mil + '/dep/' + dep + '/min/' + min + '/max/' + max
                }
                this.$store.commit('setBucketUrl', bucketUrl)
                await this.$store.dispatch('fetchBucketStats', bucketUrl)
                this.$store.commit('setLoading', false)
            }
            ,
            async updateOfferParameters1002() {
                let baseUrl = this.$store.getters.parameters.baseUrl
                let market = this.$store.getters.parameters.market
                let region = this.$store.getters.parameters.region
                let brand = this.$store.getters.parameters.brand
                let channel = this.$store.getters.parameters.channel
                let language = this.$store.getters.parameters.language
                let baseProduct = this.$store.getters.filterSettings.baseProduct
                let selectedProduct = this.$store.getters.filterSettings.selectedProduct
                let trm = this.productValues1002.trm
                this.$store.commit('setTermProduct1002', trm)
                this.$store.commit('setTerm', trm)
                let dep = this.productValues1002.dep
                this.$store.commit('setDepositProduct1002', dep)
                this.$store.commit('setDeposit', dep)
                let mil = '0'
                let min = this.$store.getters.filterSettings.min
                let max = this.$store.getters.filterSettings.max
                let filterUrl = baseUrl + '/filter/dc/market/' + market.toLowerCase() + '/brand/' + brand.toLowerCase() + '/channel/' + channel + '/language/' + language + '/trm/' + trm + '/mil/' + mil + '/dep/' + dep + '/min/' + min + '/max/' + max + '?baseproduct=' + baseProduct
                this.$store.commit('setFilterUrl', filterUrl)
                let bucketUrl = ''
                if (region != 0) {
                    bucketUrl = baseUrl + '/filter/bucket/market/' + market.toLowerCase() + ':' + region + '/brand/' + brand.toLowerCase() + '/channel/' + channel + '/language/' + language + '/trm/' + trm + '/mil/' + mil + '/dep/' + dep + '/min/' + min + '/max/' + max + '?productid=' + selectedProduct
                } else if (market == 'de') {
                    bucketUrl = baseUrl + '/filter/bucket/market/' + market.toLowerCase() + '/brand/' + brand.toLowerCase() + '/channel/' + channel + '/language/' + language + '/trm/' + trm + '/mil/' + mil + '/dep/' + dep + '/min/' + min + '/max/' + max + '?baseproduct=' + baseProduct
                } else {
                    bucketUrl = baseUrl + '/filter/bucket/market/' + market.toLowerCase() + '/brand/' + brand.toLowerCase() + '/channel/' + channel + '/language/' + language + '/trm/' + trm + '/mil/' + mil + '/dep/' + dep + '/min/' + min + '/max/' + max
                }
                this.$store.commit('setBucketUrl', bucketUrl)
                await this.$store.dispatch('fetchBucketStats', bucketUrl)
                this.$store.commit('setLoading', false)
            }
            ,
            async updateOfferParameters1004() {
                let baseUrl = this.$store.getters.parameters.baseUrl
                let market = this.$store.getters.parameters.market
                let region = this.$store.getters.parameters.region
                let brand = this.$store.getters.parameters.brand
                let channel = this.$store.getters.parameters.channel
                let language = this.$store.getters.parameters.language
                let baseProduct = this.$store.getters.filterSettings.baseProduct
                let selectedProduct = this.$store.getters.filterSettings.selectedProduct
                let trm = this.productValues1004.trm
                this.$store.commit('setTermProduct1004', trm)
                this.$store.commit('setTerm', trm)
                let dep = this.productValues1004.dep
                this.$store.commit('setDepositProduct1004', dep)
                this.$store.commit('setDeposit', dep)
                let mil = '0'
                let min = this.$store.getters.filterSettings.min
                let max = this.$store.getters.filterSettings.max
                let filterUrl = baseUrl + '/filter/dc/market/' + market.toLowerCase() + '/brand/' + brand.toLowerCase() + '/channel/' + channel + '/language/' + language + '/trm/' + trm + '/mil/' + mil + '/dep/' + dep + '/min/' + min + '/max/' + max + '?baseproduct=' + baseProduct
                this.$store.commit('setFilterUrl', filterUrl)
                let bucketUrl = ''
                if (region != 0) {
                    bucketUrl = baseUrl + '/filter/bucket/market/' + market.toLowerCase() + ':' + region + '/brand/' + brand.toLowerCase() + '/channel/' + channel + '/language/' + language + '/trm/' + trm + '/mil/' + mil + '/dep/' + dep + '/min/' + min + '/max/' + max + '?productid=' + selectedProduct
                } else if (market == 'de') {
                    bucketUrl = baseUrl + '/filter/bucket/market/' + market.toLowerCase() + '/brand/' + brand.toLowerCase() + '/channel/' + channel + '/language/' + language + '/trm/' + trm + '/mil/' + mil + '/dep/' + dep + '/min/' + min + '/max/' + max + '?baseproduct=' + baseProduct
                } else {
                    bucketUrl = baseUrl + '/filter/bucket/market/' + market.toLowerCase() + '/brand/' + brand.toLowerCase() + '/channel/' + channel + '/language/' + language + '/trm/' + trm + '/mil/' + mil + '/dep/' + dep + '/min/' + min + '/max/' + max
                }
                this.$store.commit('setBucketUrl', bucketUrl)
                await this.$store.dispatch('fetchBucketStats', bucketUrl)
                this.$store.commit('setLoading', false)
            }
            ,
        }
        ,
        watch: {
            selectedProduct: {
                deep: true,
                handler() {
                    if (this.selectedProduct == '1001') {
                        this.$store.commit('setLoading', true)
                        this.$store.commit('setSelectedTab', 0)
                        this.updateOfferProduct1001()
                    }
                    if (this.selectedProduct == '1002') {
                        this.$store.commit('setLoading', true)
                        this.$store.commit('setSelectedTab', 1)
                        this.updateOfferProduct1002()
                    }
                    if (this.selectedProduct == '1004') {
                        this.$store.commit('setLoading', true)
                        this.$store.commit('setSelectedTab', 2)
                        this.updateOfferProduct1004()
                    }
                }
            }
            ,
            productValues1001: {
                deep: true,
                handler() {
                    this.$store.commit('setLoading', true)
                    this.updateOfferParameters1001()
                }
            }
            ,
            productValues1002: {
                deep: true,
                handler() {
                    this.$store.commit('setLoading', true)
                    this.updateOfferParameters1002()
                }
            }
            ,
            productValues1004: {
                deep: true,
                handler() {
                    this.$store.commit('setLoading', true)
                    this.updateOfferParameters1004()
                }
            }
        }
    }
</script>

<style scoped>
    .v-tab--active circle {
        /*stroke: #fff;*/
        stroke: #1c69d4;
    }

    .v-tab--active path {
        /*fill: #fff;*/
        fill: #1c69d4;
    }

    #productSpecificSelection {
        max-width: 1000px;
    }

    .v-tabs {
        margin-top: 15px;
        margin-bottom: 10px;
    }

    .v-card__text {
        padding-right: 0px;
        padding-left: 0px;
    }

    .v-tab {
        text-transform: unset;
        color: #262626 !important;
        font-size: 15px;
        background-color: #E6E6E6;
    }

    .info-icon {
        height: 18px;
        width: 18px;
        justify-content: center;
        stroke: none;
        vertical-align: center;
        margin-top: -7px;
        align-items: center;
        color: white;
    }

    .info-icon-mobile {
        height: 18px;
        width: 18px;
        justify-content: center;
        stroke: none;
        vertical-align: middle;
        margin-top: -7px;
        align-items: center;
    }

    .v-tooltip__content {
        color: #262626 !important;
        background-color: white !important;
        box-shadow: 0px 0px 5px lightgrey;
        opacity: 1 !important;
        max-width: 300px;
    }

    .p-last {
        margin-bottom: 10px;
    }

    .v-tab--active {
        /*background-color: #1C69D4;*/
        background-color: white;
        /*color: white !important;*/
        color: #1C69D4 !important;
        font-weight: bold;
    }

    #tabSelection {
        color: #1C69D4 !important;
    }

</style>
