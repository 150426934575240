<template>
    <div class="main" data-app="true">
        <div class="fallback padding-iframe">
            <h2>Version info:</h2>
            <vue-json-pretty :path="'res'" :data="fetchVersion" > </vue-json-pretty>
        </div>
    </div>
</template>

<script>
    import VueJsonPretty from 'vue-json-pretty';
    export default {
        name: 'InfoPage',
        components: {
            VueJsonPretty
        },
        computed: {
            fetchVersion() {
                return {
                   'artifact-id': 'sf-acs-ui-vehicle',
                   'version': process.env.VUE_APP_VERSION,
                   'current-api-version': '1',
                   'comment': 'Values updated base on environment variables'
               }
            }
        }
    }
</script>

<style scoped>
    /*body {
        font-family: BMWTypeWeb;
        font-size: 15px;
        letter-spacing: 0.22px;
        color: #262626;
    }*/

    .main {
        margin-top: 20px;
        width: 100%;
    }

    #default p {
        margin-bottom: 20px;
        /*max-width: 1000px;*/
        max-width: 980px;
    }

    #mobile p {
        margin-bottom: 25px;
    }

    .warning-box {
        background-color: #ffe6bb;
        min-height: 45px;
        max-width: 800px;
        padding: 10px 15px;
        word-break: break-word;
        margin-bottom: 25px;
        display: flex;
        align-items: center;
    }

    .warning-icon {
        height: 24px;
        width: 24px;
        fill: #ffad1f;
        justify-content: center;
        stroke: none;
        vertical-align: middle;
        margin-right: 15px;
        display: flex;
        align-items: center;
    }

    .warning-message {
        min-height: 24px;
        text-align: left;
        display: flex;
        align-items: center;
        margin-bottom: 0;
    }

    .installment {
        display: block;
        /*max-width: 446px;
        min-width: 446px;*/
        flex-basis: initial;
        flex-grow: unset;
        flex-shrink: unset;
        margin-right: 10px !important;
    }

    #provinceSelection {
        margin: 0 !important;
    }

    #mobile .installment {
        position: center;
        float: left;
    }

    /*.product {
        margin-right: 15px;
        margin-top: -10px;
    }*/

    .installmentSelection {
        margin-left: 0px;
    }

    .info-icon {
        width: 12px;
        height: auto;
    }

    .errormessage {
        background-color: #ffe6eb;
    }

    .disclaimer {
        font-size: 12px;
        letter-spacing: 0.16px;
        margin-top: 20px;
        margin-bottom: 20px;
        text-align: justify;
        max-width: 1000px;
    }

    #default .disclaimer {
        padding-left: 100px;
    }

    #default .innerText {
        /*max-width: 1750px;*/
        max-width: 1000px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .budgetParameter {
        min-width: 140px;
    }

    .sub-header {
        font-weight: bold;
        margin-top: 20px;
    }

    .fallback {
        margin-left: 10px;
    }

    .padding-iframe {
        padding-bottom: 700px;
    }

</style>
