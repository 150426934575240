<template>
    <div class="vehicleOffer">
        <div class="vehicleInformation">
            <v-layout row class="flex-row" v-if="offer.vehicleInformation.capacity">
                <v-flex md4 sm6 xs12 wrap>
                    <p class="headline">{{ labels.offerDetailPage.vehicleOffer.capacityLabel }}</p>
                </v-flex>
                <v-flex md8 sm10 xs12 wrap>
                    <p>{{ capacity | formatValue('integer', parameters.language, parameters.market) }} {{ labels.offerDetailPage.vehicleOffer.capacity }}</p>
                </v-flex>
            </v-layout>
            <v-layout row class="flex-row" v-if="offer.vehicleInformation.power">
                <v-flex md4 sm6 xs12 wrap>
                    <p class="headline">{{ labels.offerDetailPage.vehicleOffer.powerLabel }}</p>
                </v-flex>
                <v-flex md8 sm10 xs12 wrap>
                    <p>{{ engineKWMin}} {{ labels.offerDetailPage.vehicleOffer.kilowatt }} ({{ engineKWMin | calculatePS}} {{ labels.offerDetailPage.vehicleOffer.horsepower }})</p>
                </v-flex>
            </v-layout>
        </div>
        <div class="offerDetails" v-if="offer.financeProductDescription">
                <v-layout row>
                    <v-flex xl4 lg4 md4 sm12 xs12>
                        <div class="financeParametersHeadline">
                            <p class="headline">{{ offer.financeProductDescription.productName | replaceProductNames(labels.offerDetailPage.vehicleOffer.loanProductCondition, '3asy Ride', labels.offerDetailPage.vehicleOffer.leaseProductCondition, labels.offerDetailPage.vehicleOffer.leaseProductText) }}</p>
                        </div>
                    </v-flex>
                    <v-flex xl8 lg8 md8 sm12 xs12>
                        <table class="financeParameters" :class="$mq" v-if="offer.financeParameters">
                            <tr>
                                <td>
                                    <v-layout row class="parameterRow">
                                        <v-flex xl8 lg8 md8 sm8 xs12
                                                v-if="offer.financeParameters.Price_Leasing">
                                            <p class="parameterName" v-if="offer.financeParameters.Price_Leasing.parameterFlags.isDisplayPrint === 'true'">{{ offer.financeParameters.Price_Leasing.parameterDescription.parameterName }}</p>
                                        </v-flex>
                                        <v-flex xl4 lg4 md4 sm4 xs12
                                                v-if="offer.financeParameters.Price_Leasing">
                                            <p class="parameterValue" v-if="offer.financeParameters.Price_Leasing.parameterFlags.isDisplayPrint === 'true'">{{ offer.financeParameters.Price_Leasing.value| formatValue('currency', parameters.language, parameters.market) }}</p>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row class="parameterRow">
                                        <v-flex xl8 lg8 md8 sm8 xs12
                                                v-if="offer.financeParameters.Price">
                                            <p class="parameterName" v-if="offer.financeParameters.Price.parameterFlags.isDisplayPrint === 'true'">{{ offer.financeParameters.Price.parameterDescription.parameterName }}</p>
                                        </v-flex>
                                        <v-flex xl4 lg4 md4 sm4 xs12
                                                v-if="offer.financeParameters.Price">
                                            <p class="parameterValue" v-if="offer.financeParameters.Price.parameterFlags.isDisplayPrint === 'true'">{{ offer.financeParameters.Price.value| formatValue('currency', parameters.language, parameters.market) }}</p>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row class="parameterRow">
                                        <v-flex xl8 lg8 md8 sm8 xs12
                                                v-if="offer.financeParameters.DepositAmountLeasing">
                                            <p class="parameterName" v-if="offer.financeParameters.DepositAmountLeasing.parameterFlags.isDisplayPrint === 'true'">{{ offer.financeParameters.DepositAmountLeasing.parameterDescription.parameterName }}</p>
                                        </v-flex>
                                        <v-flex xl4 lg4 md4 sm4 xs12
                                                v-if="offer.financeParameters.DepositAmountLeasing">
                                            <p class="parameterValue" v-if="offer.financeParameters.DepositAmountLeasing.parameterFlags.isDisplayPrint === 'true'">{{ offer.financeParameters.DepositAmountLeasing.value| formatValue('currency', parameters.language, parameters.market) }}</p>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row class="parameterRow">
                                        <v-flex xl8 lg8 md8 sm8 xs12
                                                v-if="offer.financeParameters.DepositAmount_Zielfinanzierung">
                                            <p class="parameterName" v-if="offer.financeParameters.DepositAmount_Zielfinanzierung.parameterFlags.isDisplayPrint === 'true'">{{ offer.financeParameters.DepositAmount_Zielfinanzierung.parameterDescription.parameterName }}</p>
                                        </v-flex>
                                        <v-flex xl4 lg4 md4 sm4 xs12
                                                v-if="offer.financeParameters.DepositAmount_Zielfinanzierung">
                                            <p class="parameterValue" v-if="offer.financeParameters.DepositAmount_Zielfinanzierung.parameterFlags.isDisplayPrint === 'true'">{{ offer.financeParameters.DepositAmount_Zielfinanzierung.value| formatValue('currency', parameters.language, parameters.market) }}</p>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row class="parameterRow">
                                        <v-flex xl8 lg8 md8 sm8 xs12
                                                v-if="offer.financeParameters.Mileage">
                                            <p class="parameterName" v-if="offer.financeParameters.Mileage.parameterFlags.isDisplayPrint === 'true'">{{ offer.financeParameters.Mileage.parameterDescription.parameterName }}</p>
                                        </v-flex>
                                        <v-flex xl4 lg4 md4 sm4 xs12
                                                v-if="offer.financeParameters.Mileage">
                                            <p class="parameterValue" v-if="offer.financeParameters.Mileage.parameterFlags.isDisplayPrint === 'true'">{{ offer.financeParameters.Mileage.value| formatValue('mileage', parameters.language, parameters.market) }}</p>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row class="parameterRow">
                                        <v-flex xl8 lg8 md8 sm8 xs12
                                                v-if="offer.financeParameters.Term_Leasing">
                                            <p class="parameterName" v-if="offer.financeParameters.Term_Leasing.parameterFlags.isDisplayPrint === 'true'">{{ offer.financeParameters.Term_Leasing.parameterDescription.parameterName }}</p>
                                        </v-flex>
                                        <v-flex xl4 lg4 md4 sm4 xs12
                                                v-if="offer.financeParameters.Term_Leasing">
                                            <p class="parameterValue" v-if="offer.financeParameters.Term_Leasing.parameterFlags.isDisplayPrint === 'true'">{{ offer.financeParameters.Term_Leasing.value| formatValue('term', parameters.language, parameters.market) }}</p>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row class="parameterRow">
                                        <v-flex xl8 lg8 md8 sm8 xs12
                                                v-if="offer.financeParameters.Term">
                                            <p class="parameterName" v-if="offer.financeParameters.Term.parameterFlags.isDisplayPrint === 'true'">{{ offer.financeParameters.Term.parameterDescription.parameterName }}</p>
                                        </v-flex>
                                        <v-flex xl4 lg4 md4 sm4 xs12
                                                v-if="offer.financeParameters.Term">
                                            <p class="parameterValue" v-if="offer.financeParameters.Term.parameterFlags.isDisplayPrint === 'true'">{{ offer.financeParameters.Term.value| formatValue('term', parameters.language, parameters.market) }}</p>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row class="parameterRow">
                                        <v-flex xl8 lg8 md8 sm8 xs12
                                                v-if="offer.financeParameters.MonthlyPaymentWithoutServices">
                                            <p class="parameterName" v-if="offer.financeParameters.MonthlyPaymentWithoutServices.parameterFlags.isDisplayPrint === 'true'">{{ offer.financeParameters.MonthlyPaymentWithoutServices.parameterDescription.parameterName | replaceBrackets('{Term_Leasing}', offer.financeParameters.Term_Leasing.value)}}</p>
                                        </v-flex>
                                        <v-flex xl4 lg4 md4 sm4 xs12
                                                v-if="offer.financeParameters.MonthlyPaymentWithoutServices">
                                            <p class="parameterValue" v-if="offer.financeParameters.MonthlyPaymentWithoutServices.parameterFlags.isDisplayPrint === 'true'">{{ offer.financeParameters.MonthlyPaymentWithoutServices.value| formatValue('currency', parameters.language, parameters.market) }}</p>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row class="parameterRow">
                                        <v-flex xl8 lg8 md8 sm8 xs12
                                                v-if="offer.financeParameters.MonthlyPayment_Ziel">
                                            <p class="parameterName" v-if="offer.financeParameters.MonthlyPayment_Ziel.parameterFlags.isDisplayPrint === 'true'">{{ offer.financeParameters.MonthlyPayment_Ziel.parameterDescription.parameterName | replaceBrackets('{TermAdjustedZiel}', offer.financeParameters.TermAdjustedZiel.value) }}</p>
                                        </v-flex>
                                        <v-flex xl4 lg4 md4 sm4 xs12
                                                v-if="offer.financeParameters.MonthlyPayment_Ziel">
                                            <p class="parameterValue" v-if="offer.financeParameters.MonthlyPayment_Ziel.parameterFlags.isDisplayPrint === 'true'">{{ offer.financeParameters.MonthlyPayment_Ziel.value| formatValue('currency', parameters.language, parameters.market) }}</p>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row class="parameterRow">
                                        <v-flex xl8 lg8 md8 sm8 xs12
                                                v-if="offer.financeParameters.BallonAmount_ZielFinanzierung">
                                            <p class="parameterName" v-if="offer.financeParameters.BallonAmount_ZielFinanzierung.parameterFlags.isDisplayPrint === 'true'">{{ offer.financeParameters.BallonAmount_ZielFinanzierung.parameterDescription.parameterName | replaceBrackets('{Term}', offer.financeParameters.Term.value) }}</p>
                                        </v-flex>
                                        <v-flex xl4 lg4 md4 sm4 xs12
                                                v-if="offer.financeParameters.BallonAmount_ZielFinanzierung">
                                            <p class="parameterValue" v-if="offer.financeParameters.BallonAmount_ZielFinanzierung.parameterFlags.isDisplayPrint === 'true'">{{ offer.financeParameters.BallonAmount_ZielFinanzierung.value| formatValue('currency', parameters.language, parameters.market) }}</p>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row class="parameterRow">
                                        <v-flex xl8 lg8 md8 sm8 xs12
                                                v-if="offer.financeParameters.CreditAmount">
                                            <p class="parameterName" v-if="offer.financeParameters.CreditAmount.parameterFlags.isDisplayPrint === 'true'">{{ offer.financeParameters.CreditAmount.parameterDescription.parameterName }}</p>
                                        </v-flex>
                                        <v-flex xl4 lg4 md4 sm4 xs12
                                                v-if="offer.financeParameters.CreditAmount">
                                            <p class="parameterValue" v-if="offer.financeParameters.CreditAmount.parameterFlags.isDisplayPrint === 'true'">{{ offer.financeParameters.CreditAmount.value| formatValue('currency', parameters.language, parameters.market) }}</p>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row class="parameterRow">
                                        <v-flex xl8 lg8 md8 sm8 xs12
                                                v-if="offer.financeParameters.NominalInterestRate">
                                            <p class="parameterName" v-if="offer.financeParameters.NominalInterestRate.parameterFlags.isDisplayPrint === 'true' && offer.financeProductInformation.productBaseType === 'LOAN'">{{ offer.financeParameters.NominalInterestRate.parameterDescription.parameterName }}</p>
                                        </v-flex>
                                        <v-flex xl4 lg4 md4 sm4 xs12
                                                v-if="offer.financeParameters.NominalInterestRate">
                                            <p class="parameterValue" v-if="offer.financeParameters.NominalInterestRate.parameterFlags.isDisplayPrint === 'true' && offer.financeProductInformation.productBaseType === 'LOAN'">{{ offer.financeParameters.NominalInterestRate.value| formatValue('percentage', parameters.language, parameters.market, labels) }}</p>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row class="parameterRow">
                                        <v-flex xl8 lg8 md8 sm8 xs12
                                                v-if="offer.financeParameters.InterestRate">
                                            <p class="parameterName" v-if="offer.financeParameters.InterestRate.parameterFlags.isDisplayPrint === 'true' && offer.financeProductInformation.productBaseType === 'LOAN'">{{ offer.financeParameters.InterestRate.parameterDescription.parameterName }}</p>
                                        </v-flex>
                                        <v-flex xl4 lg4 md4 sm4 xs12
                                                v-if="offer.financeParameters.InterestRate">
                                            <p class="parameterValue" v-if="offer.financeParameters.InterestRate.parameterFlags.isDisplayPrint === 'true' && offer.financeProductInformation.productBaseType === 'LOAN'">{{ offer.financeParameters.InterestRate.value| formatValue('percentage', parameters.language, parameters.market, labels) }}</p>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row class="parameterRow">
                                        <v-flex xl8 lg8 md8 sm8 xs12
                                                v-if="offer.financeParameters.TotalPayment">
                                            <p class="parameterName" v-if="offer.financeParameters.TotalPayment.parameterFlags.isDisplayPrint === 'true'">{{ offer.financeParameters.TotalPayment.parameterDescription.parameterName }}</p>
                                        </v-flex>
                                        <v-flex xl4 lg4 md4 sm4 xs12
                                                v-if="offer.financeParameters.TotalPayment">
                                            <p class="parameterValue" v-if="offer.financeParameters.TotalPayment.parameterFlags.isDisplayPrint === 'true'">{{ offer.financeParameters.TotalPayment.value| formatValue('currency', parameters.language, parameters.market) }}</p>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row class="parameterRow">
                                        <v-flex xl8 lg8 md8 sm8 xs12
                                                v-if="offer.financeParameters.TotalCredit">
                                            <p class="parameterName" v-if="offer.financeParameters.TotalCredit.parameterFlags.isDisplayPrint === 'true'">{{ offer.financeParameters.TotalCredit.parameterDescription.parameterName }}</p>
                                        </v-flex>
                                        <v-flex xl4 lg4 md4 sm4 xs12
                                                v-if="offer.financeParameters.TotalCredit">
                                            <p class="parameterValue" v-if="offer.financeParameters.TotalCredit.parameterFlags.isDisplayPrint === 'true'">{{ offer.financeParameters.TotalCredit.value| formatValue('currency', parameters.language, parameters.market) }}</p>
                                        </v-flex>
                                    </v-layout>
                                </td>
                            </tr>
                        </table>
                    </v-flex>
                </v-layout>
        </div>
        <div id="finalSelection" class="finalSelection" :class="$mq" v-if="offer.financeParameters">
                <p class="h2inline">{{ labels.offerDetailPage.vehicleOffer.forExample }} <span class="h2inline">{{ offer.financePaymentInformation.basePayment | formatParameter(null, 'Currency', null, labels) }}</span>
                </p>
                <p class="summary"> {{ offer.vehicleInformation.modelName }} {{ labels.offerDetailPage.vehicleOffer.totalInstallmentMonth }} </p>
                <v-btn dark tile depressed color="#16171a" @click="sendOpenConfiguratorTrackingEvent" class="continue" :href="configuratorUrl">
                    <a>{{ labels.offerDetailPage.vehicleOffer.jumpToConfigurator }}</a>
                </v-btn>

        </div>
        <div id="disclaimer" v-if="offer.financeProductDescription">
            <p class="disclaimer" v-if="offer.financeProductDescription.disclaimerText" v-html="'*' + offer.financeProductDescription.disclaimerText"></p>
        </div>
    </div>
</template>

<script>
    import TrackingHandler from "@/helpers/TrackingHandler";

    export default {
        name: 'MotoOfferItem',
        components: {},
        props: {
            offer: {},
            capacity: '',
            engineKWMin: '',
            item: ''
        },
        data() {
            return {}
        },
        computed: {
            labels() {
                return this.$store.state.labels
            },
            parameters() {
                return this.$store.state.parameters
            },
            filterSettings() {
                return this.$store.state.filterSettings
            },
            configuratorUrl() {
                let baseUrl = 'https://konfigurator.bmw-motorrad.de'
                let language = this.$store.getters.parameters.language.toLowerCase()
                let market = this.$store.getters.parameters.market.toUpperCase()
                let vehicleId = this.offer.vehicleInformation.vehicleId
                let configuratorUrl = baseUrl + '/index_' + language + '_' + market + '.html#/configurator/' + vehicleId
                return configuratorUrl
            },
            baseType() {
                switch (this.offer.financeProductInformation.productBaseType) {
                    case 'LOAN':
                        return this.labels.offerDetailPage.vehicleOffer.LOAN
                    case 'LEASE':
                        return this.labels.offerDetailPage.vehicleOffer.LEASE
                }
            }

        }
        ,
        mounted() {

        }
        ,
        created() {
          TrackingHandler.sendSelectRangeEvent(Date.now(), this.$store, this.offer.vehicleInformation, undefined);
        }
        ,
        methods: {
            sendOpenConfiguratorTrackingEvent() {
                TrackingHandler.sendOpenConfiguratorEvent(Date.now(), this.$store, this.offer);
            },
        }
        ,
        filters: {
            replaceProductNames: function(value, loanProductCondition, loanProductText, leaseProductCondition, leaseProductText) {
              if (value.includes(loanProductCondition)) {
                  return loanProductText
              }
              if (value.includes(leaseProductCondition)) {
                  return leaseProductText
              }
              else return value
            },
            replaceFPTermWithValue: function (value, trm) {
                if (!value) {
                    return ''
                }
                if (!value.search('{Term}')) {
                    value = value.replace('{Term}', trm)
                    return value
                } else {
                    return value
                }
            }
            ,
            calculatePS: function (value) {
                return parseInt(value * 1.35962)
            },
            formatParameter: function (value, id, type, pattern, labels) {
                if (type === 'Integer') {
                    value = parseInt(value)
                    if (id === 'Mileage') {
                        return value + ' ' + labels.financeParameterFormat.mileage
                    }
                    if (id === 'Term') {
                        return value + ' ' + labels.financeParameterFormat.term
                    }
                    return value
                }
                if (type === 'Percentage') {
                    value = parseFloat(value * 100).toFixed(2)
                    value = value.replace('.', ',')
                    return value + ' %'
                }
                if (type === 'Currency') {
                    value = parseFloat(value).toFixed(2)
                    value = value.replace('.', ',')
                    let splitValue = value.split(',')
                    if (splitValue[0].length > 3) {
                        let thousands = splitValue[0].slice(0, -3)
                        let hundreds = splitValue[0].slice(-3)
                        return thousands + '.' + hundreds + ',' + splitValue[1] + ' ' + labels.financeParameterFormat.currencySymbol
                    }
                    return value + ' €'
                }
            }
            ,
        }
    }
</script>

<style scoped>
    .vehicleOffer {
        background-color: #f2f2f2;
        margin-bottom: 20px;
        font-family: BMWMotorrad;
    }

    .vehicleInformation {
        border-bottom: solid 1px #e6e6e6;
        margin-bottom: 15px;
        padding-bottom: 10px;
    }

    .offerDetails {
        margin-bottom: 15px;
        padding-bottom: 10px;
    }

    .offerDetails /deep/ .row {
        margin: 0px;
    }

    .flex-row {
        margin-left: 0px;
    }

    .parameterRow {
        margin-left: 0px;
        margin-right: 0px;
    }

    .headline {
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #16171a;
    }

    .offertitle {
        margin-bottom: 30px;
        padding-bottom: 30px;
        border-bottom: solid 2px #16171a;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #000000;
    }

    .informationLast {
        margin-top: 3px;
    }

    p {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #16171a;
    }

    button {
        font-size: 14px;
        letter-spacing: 0.20px;
        margin-bottom: 3px;
    }

    #default button, #mobile button {
        width: 315px;
    }

    .vehicleInformation .flex-row {
        margin-bottom: 5px;
    }

    .productName {
        margin-bottom: 10px;
    }

    .financeParameters {
        width: 100%;
    }

    .financeParametersHeadline {
        vertical-align: top !important;
        width: 375px;
        margin-bottom: 20px;
    }

    .parameterValue {
        text-align: right;
    }

    .financeParameters.xs .parameterName {
        width: 70%;
        margin-right: 10px;
        font-weight: bold;
    }

    .financeParameters.xs .parameterValue {
        vertical-align: bottom;
        text-align: left;
        margin-bottom: 20px;
    }

    #default button {
        margin-bottom: 10px;
        margin-top: 10px;
    }

    .finalSelection {
        display: block;
        margin-bottom: 40px;
    }

    .finalSelection.xs {
        margin-bottom: 15px;
    }

    .finalSelection.xs .h2inline {
        text-align: left;
    }

    .finalSelection.xs .summary {
        text-align: left;
        float: unset;
        margin-bottom: 15px;
    }

    .v-card__actions button {
        margin-bottom: 0px;
    }

    .summary {
        float: right;
        clear: right;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: #16171a;
    }

    .continue {
        float: right;
        clear: right;
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        padding: 15px 20px !important;
        height: 40px !important;
    }

    .finalSelection.xs .continue {
        float: none;
    }

    #finalSelection a {
        color: white;
        text-decoration-line: unset;
    }

    #disclaimer {
        clear: right;
    }

    .h2inline {
        font-size: 32px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.13;
        letter-spacing: normal;
        text-align: right;
        color: #16171a;
    }

    h2, h3, p, input {
        color: #262626;
    }

    h3 {
        margin-bottom: 15px;
    }

    .disclaimer {
        font-size: 10px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: normal;
        color: #16171a;
    }

    .v-btn:hover {
        background-color: #0062ff !important;
    }

</style>
