<template>
    <div data-app="true" v-if="labels.mainPage">
        <v-dialog v-model="dialog" eager transition="slide-x-reverse-transition">
            <template v-slot:activator="{ on }">
                <v-btn dark tile depressed color="#1C69D4" v-on="on">
                    {{ labels.mainPage.productSelection.configureProduct }}
                </v-btn>
            </template>

            <v-card tile class="selection">
                <mq-layout mq="sm+">
                    <v-btn id="closeDefault" color="primary" text @click="abortUpdate">
                        {{ labels.mainPage.adjustFinancePage.closePage }}
                    </v-btn>
                </mq-layout>
                <mq-layout mq="xs">
                    <v-btn id="closeMobile" color="primary" text @click="abortUpdate">
                        {{ labels.mainPage.adjustFinancePage.closePage }}
                    </v-btn>
                </mq-layout>
                <v-card-title>
                    <h2 class="configureProduct">{{ labels.mainPage.adjustFinancePage.adjustFinanceTitle }}</h2>
                </v-card-title>

                <v-card-text>
                    <div class="subheadline">
                        <h3>{{ labels.mainPage.adjustFinancePage.adjustFinanceSubTitle }}</h3>
                    </div>
                    <div v-if="labels.mainPage">
                        <v-layout>
                            <v-select :items="termValues"
                                      :label="labels.mainPage.adjustFinancePage.adjustFinanceOptions.adjustFinanceOptionTerm"
                                      outlined
                                      dense
                                      color="#1C69D4"
                                      class="financeParameter"
                                      v-model="filterParametersTemp.trm"
                            ></v-select>
                        </v-layout>
                        <v-layout>
                            <v-select :items="depositValues"
                                      :label="labels.mainPage.adjustFinancePage.adjustFinanceOptions.adjustFinanceOptionDeposit"
                                      outlined
                                      dense
                                      color="#1C69D4"
                                      class="financeParameter"
                                      v-model="filterParametersTemp.dep"
                            ></v-select>
                        </v-layout>
                        <v-layout>
                            <v-select :items="mileageValues"
                                      :label="labels.mainPage.adjustFinancePage.adjustFinanceOptions.adjustFinanceOptionMileage"
                                      outlined
                                      dense
                                      color="#1C69D4"
                                      class="financeParameter"
                                      v-model="filterParametersTemp.mil"
                            ></v-select>
                            <v-tooltip :open-on-hover="false" :open-on-click="true" bottom class="tooltip">
                                <template v-slot:activator="{ on, attrs }">
                                    <div class="info-icon" v-bind="attrs" v-on="on">
                                        <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" focusable="false"
                                             style="pointer-events: none; display: block; width: 100%; height: 100%;">
                                            <g fill="none" fill-rule="evenodd" viewBox="0 0 24 24">
                                                <circle cx="12" cy="12" r="11" stroke="#BBB" stroke-width="2"></circle>
                                                <path fill="#4D4D4D"
                                                      d="M11 16.09h1.818v-5.555H11v5.556zm0-7.272h1.818V7H11v1.818z"></path>
                                            </g>
                                        </svg>
                                    </div>
                                </template>
                                <span>{{ labels.mainPage.adjustFinancePage.adjustFinanceOptions.adjustFinanceOptionMileageText }}</span>
                            </v-tooltip>
                        </v-layout>
                    </div>
                </v-card-text>

                <div id="adjustFinance">
                    <v-btn dark tile depressed color="#1C69D4" @click="updateOffers()">
                        {{ labels.mainPage.adjustFinancePage.showResults }}
                    </v-btn>
                    <v-btn dark tile depressed color="#262626" @click="abortUpdate()">
                        {{ labels.mainPage.adjustFinancePage.cancel }}
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>
    </div>

</template>

<script>
    export default {
        name: 'AdjustFinancePage',
        components: {},
        props: {},
        data() {
            return {
                dialog: false,
            }
        },
        computed: {
            filterParameters() {
                if (this.parameters.brand == "bmw") {
                    return {
                        'trm': 36,
                        'dep': 5000,
                        'mil': 15000
                    }
                }
                else if (this.parameters.brand == "bmwBike") {
                    return {
                        'trm': 24,
                        'dep': 0,
                        'mil': 4000
                    }
                }
            },
            filterParametersTemp() {
                if (this.parameters.brand == "bmw") {
                    return {
                        'trm': 36,
                        'dep': 5000,
                        'mil': 15000
                    }
                }
                else if (this.parameters.brand == "bmwBike") {
                    return {
                        'trm': 24,
                        'dep': 0,
                        'mil': 4000
                    }
                }
            },
            labels() {
                return this.$store.state.labels
            },
            settings() {
                return this.$store.state.settings
            },
            parameters() {
                return this.$store.state.parameters
            },
            termValues() {
                return this.$store.state.settings.filter.term.values
            },
            depositValues() {
                return this.$store.state.settings.filter.deposit.values
            },
            mileageValues() {
                return this.$store.state.settings.filter.mileage.values
            },

        },
        created() {
        },
        methods: {
            async updateOffers() {
                let baseUrl = this.$store.getters.parameters.baseUrl
                let market = this.$store.getters.parameters.market
                let region = this.$store.getters.parameters.region
                let brand = this.$store.getters.parameters.brand
                let channel = this.$store.getters.parameters.channel
                let language = this.$store.getters.parameters.language
                let baseProduct = this.$store.getters.filterSettings.baseProduct
                let selectedProduct = this.$store.getters.filterSettings.selectedProduct
                let trm = this.filterParametersTemp.trm
                this.filterParameters.trm = trm
                this.$store.commit('setTerm', trm)
                let dep = this.filterParametersTemp.dep
                this.filterParameters.dep = dep
                this.$store.commit('setDeposit', dep)
                let mil = this.filterParametersTemp.mil
                this.filterParameters.mil = mil
                this.$store.commit('setMileage', mil)
                let min = this.$store.getters.filterSettings.min
                let max = this.$store.getters.filterSettings.max
                let filterUrl = baseUrl + '/filter/dc/market/' + market.toLowerCase() + '/brand/' + brand.toLowerCase() + '/channel/' + channel + '/language/' + language + '/trm/' + trm + '/mil/' + mil + '/dep/' + dep + '/min/' + min + '/max/' + max + '?baseproduct=' + baseProduct
                this.$store.commit('setFilterUrl', filterUrl)
                let bucketUrl = ''
                if (region != 0) {
                    bucketUrl = baseUrl + '/filter/bucket/market/' + market.toLowerCase() + ':' + region  + '/brand/' + brand.toLowerCase() + '/channel/' + channel + '/language/' + language + '/trm/' + trm + '/mil/' + mil + '/dep/' + dep + '/min/' + min + '/max/' + max + '?productid=' + selectedProduct
                }
                else if (market == 'de') {
                    bucketUrl = baseUrl + '/filter/bucket/market/' + market.toLowerCase() + '/brand/' + brand.toLowerCase() + '/channel/' + channel + '/language/' + language + '/trm/' + trm + '/mil/' + mil + '/dep/' + dep + '/min/' + min + '/max/' + max + '?baseproduct=' + baseProduct
                }
                else {
                    bucketUrl = baseUrl + '/filter/bucket/market/' + market.toLowerCase() + '/brand/' + brand.toLowerCase() + '/channel/' + channel + '/language/' + language + '/trm/' + trm + '/mil/' + mil + '/dep/' + dep + '/min/' + min + '/max/' + max
                }
                this.$store.commit('setBucketUrl', bucketUrl)
                await this.$store.dispatch('fetchBucketStats', bucketUrl)
                this.dialog = false
            },
            abortUpdate() {
                this.filterParametersTemp = this.filterParameters
                this.dialog = false
            },
            setFilterParameters() {
                this.filterParametersTemp.trm = this.$store.getters.filterSettings.trm
                this.filterParametersTemp.dep = this.$store.getters.filterSettings.dep
                this.filterParametersTemp.mil = this.$store.getters.filterSettings.mil
            },
            clearFilterParameters() {
                this.filterParametersTemp.trm = null
                this.filterParametersTemp.dep = null
                this.filterParametersTemp.mil = null
            }
        },
        watch: {
            dialog: function () {
                if (this.dialog == true) {
                    document.getElementById("mainHTML").style.overflowY = "hidden"
                    this.setFilterParameters()
                }
                if (this.dialog == false) {
                    document.getElementById("mainHTML").style.overflowY = ""
                    this.clearFilterParameters()
                }
            },
        }
    }
</script>

<style scoped>
    .selection {
        position: absolute;
        width: 768px;
        height: 100%;
        overflow: hidden;
        top: 0;
        right: 0;
    }

    #closeDefault {
        position: fixed;
        min-width: 0px;
        height: 45px;
        width: 75px;
        margin: 0;
        padding: 0;
        right: 45px;
        top: 12px;
        box-shadow: 0px 0px 5px lightgrey;
        border-radius: 1px;
        font-size: 18px;
        color: grey;
        background-color: white;
        z-index: 100;
    }

    #closeMobile {
        position: fixed;
        min-width: 0px;
        height: 40px;
        width: 60px;
        margin: 0;
        padding: 0;
        right: 20px;
        top: 20px;
        box-shadow: 0px 0px 5px lightgrey;
        border-radius: 1px;
        font-size: 16px;
        color: grey;
        background-color: white;
        z-index: 100;
    }

    #mobile .v-card {
        width: 100%;
    }

    h2 {
        margin-bottom: 30px;
        margin-left: 20px;
    }

    p, span {
        font-size: 12px;
        letter-spacing: 0.16px;
        margin-bottom: 3px;
    }

    button {
        font-size: 14px;
        letter-spacing: 0.20px;
        margin-bottom: 3px;
    }

    #default button {
        margin-bottom: 10px;
        margin-top: 10px;
    }

    .v-card__actions {
        height: 50px;
    }

    .v-card__text {
        padding-bottom: 15px !important;
    }

    .v-card__actions button {
        margin-bottom: 0px;
    }

    #close {
        float: right;
    }

    .financeParameter {
        display: block;
        width: 250px;
        height: 70px;
        flex-basis: initial;
        flex-grow: unset;
        flex-shrink: unset;
        margin-right: 20px !important;
        margin-left: 20px !important;
        background-color: #ffffff;
    }

    >>> legend {
        width: 60px !important;
    }

    #adjustFinance {
        margin-left: 45px;
        width: 678px;
        border-top: lightgray solid thin;
    }

    #adjustFinance button {
        margin-right: 10px;
    }

    .configureProduct {
        white-space: normal;
        word-break: break-word;
    }

    >>>label{
        background-color:white
    }

    .subheadline {
        padding: 0px 22px;
        margin-top: -30px;
    }

    .info-icon {
        height: 18px;
        width: 18px;
        justify-content: center;
        stroke: none;
        vertical-align: top;
        margin-right: 5px;
        margin-left: -10px;
        /*display: flex;*/
        align-items: center;
    }

    .v-tooltip__content {
        color: #262626 !important;
        background-color: white !important;
        box-shadow: 0px 0px 5px lightgrey;
        opacity: 1 !important;
        max-width: 300px;
    }


</style>
