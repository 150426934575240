<template>
    <div data-app="true" v-if="labels.engineSelectionPage">
        <v-dialog v-model="dialog" eager transition="slide-x-reverse-transition" id="engineSelectionPage">
            <template v-slot:activator="{ on }">
                <v-btn dark tile depressed color="#1C69D4" v-on="on">
                    {{ labels.mainPage.vehiclesList.chooseEngine }}
                </v-btn>
            </template>

            <v-card tile id="engineSelection">
                <div id="dialogContent">
                    <mq-layout mq="sm+">
                        <v-btn id="closeDefault" color="primary" text @click="dialog = false">
                            X
                        </v-btn>
                    </mq-layout>
                    <mq-layout mq="xs">
                        <v-btn id="closeMobile" color="primary" text @click="dialog = false">
                            X
                        </v-btn>
                    </mq-layout>
                    <v-card-title v-if="rangeDescription">
                        <h2 class="rangeDescription">{{ rangeDescription.toUpperCase() }}.</h2>
                    </v-card-title>
                    <v-card-text>
                        <img v-bind:src="modelImage" class="modelImage" @error="imageUrl='./imagenotfound.jpg'"/>
                        <h3>{{ labels.engineSelectionPage.engineSelectionSubTitle }}</h3>
                        <div id="filterSection">
                            <p class="headline">{{ labels.engineSelectionPage.fuelType }}</p>
                            <v-layout row class="selectionRow">
                                <v-checkbox class="selectionBox" :label="labels.engineSelectionPage.fuelTypes.petrol"
                                            :value="labels.engineSelectionPage.fuelTypes.petrol" color="#1C69D4"
                                            v-model="selectedFuelTypes"></v-checkbox>
                                <v-checkbox class="selectionBox" :label="labels.engineSelectionPage.fuelTypes.diesel"
                                            :value="labels.engineSelectionPage.fuelTypes.diesel" color="#1C69D4"
                                            v-model="selectedFuelTypes"></v-checkbox>
                                <v-checkbox class="selectionBox" :label="labels.engineSelectionPage.fuelTypes.hybrid"
                                            :value="labels.engineSelectionPage.fuelTypes.hybrid" color="#1C69D4"
                                            v-model="selectedFuelTypes"></v-checkbox>
                            </v-layout>
                            <p class="headline">{{ labels.engineSelectionPage.transmission }}</p>
                            <v-layout row class="selectionRow">
                                <v-checkbox class="selectionBox"
                                            :label="labels.engineSelectionPage.transmissionType.manual"
                                            value="manual" color="#1C69D4"
                                            v-model="selectedTransmissions"></v-checkbox>
                                <v-checkbox class="selectionBox"
                                            :label="labels.engineSelectionPage.transmissionType.automatic"
                                            value="automatic"
                                            color="#1C69D4" v-model="selectedTransmissions"></v-checkbox>
                            </v-layout>
                        </div>
                        <div id="vehicleSection">
                            <div class="transmissions">
                                <h2 class="inline">{{ enginesFound }} </h2>
                                <span v-show="enginesFound == 0">{{ labels.engineSelectionPage.enginesFoundPlural }}</span>
                                <span v-show="enginesFound == 1">{{ labels.engineSelectionPage.enginesFoundSingular }}</span>
                                <span v-show="enginesFound > 1">{{ labels.engineSelectionPage.enginesFoundPlural }}</span>
                            </div>
                            <div id="sectionPetrol"
                                 v-if="((noFilterSelected && (modelRangeOffersPetrol.length > 0) || transmissionTypeFilterSelected && (modelRangeOffersPetrol.length > 0) || (modelRangeOffersPetrol.length > 0 && selectedFuelTypes.includes('Benzin'))) && enginesFound > 0)">
                                <h3 class="offerSection"> {{ labels.engineSelectionPage.fuelTypes.petrol }} </h3>
                                <EngineSelectionItem :offer=offer v-for="(offer, index) in modelRangeOffersPetrol"
                                                     :key="index + '_' + offer.vehicleInformation.vehicleId"
                                                     @closeDialog="closeDialog"></EngineSelectionItem>
                            </div>
                            <div id="sectionDiesel"
                                 v-if="((noFilterSelected && (modelRangeOffersDiesel.length > 0) || transmissionTypeFilterSelected && (modelRangeOffersDiesel.length > 0) || (modelRangeOffersDiesel.length > 0 && selectedFuelTypes.includes('Diesel'))) && enginesFound > 0)">
                                <h3 class="offerSection"> {{ labels.engineSelectionPage.fuelTypes.diesel }} </h3>
                                <EngineSelectionItem :offer=offer v-for="(offer, index) in modelRangeOffersDiesel"
                                                     :key="index + '_' + offer.vehicleInformation.vehicleId"
                                                     @closeDialog="closeDialog"></EngineSelectionItem>
                            </div>
                            <div id="sectionHybrid"
                                 v-if="((noFilterSelected && (modelRangeOffersHybrid.length > 0) || transmissionTypeFilterSelected && (modelRangeOffersHybrid.length > 0) || (modelRangeOffersHybrid.length > 0 && selectedFuelTypes.includes('Plug-In-Hybrid'))) && enginesFound > 0)">
                                <h3 class="offerSection"> {{ labels.engineSelectionPage.fuelTypes.hybrid }} </h3>
                                <EngineSelectionItemHybrid :offer=offer v-for="(offer, index) in modelRangeOffersHybrid"
                                                           :key="index + '_' + offer.vehicleInformation.vehicleId"
                                                           @closeDialog="closeDialog"></EngineSelectionItemHybrid>
                            </div>
                        </div>
                    </v-card-text>
                </div>
            </v-card>

        </v-dialog>
    </div>

</template>

<script>
    import EngineSelectionItem from "./EngineSelectionItem";
    import EngineSelectionItemHybrid from "./EngineSelectionItemHybrid";

    export default {
        name: 'EngineSelectionPage',
        components: {
            EngineSelectionItem,
            EngineSelectionItemHybrid
        },
        props: {
            modelSeries: '',
            modelRange: '',
            rangeDescription: '',
            modelImages: {},
        },
        data() {
            return {
                dialog: false,
                selectedFuelTypes: [],
                selectedTransmissions: [],
            }
        },
        computed: {
            labels() {
                return this.$store.state.labels
            },
            modelImage() {
                if (this.$mq == 'mobile') {
                    return this.modelImages.mobile
                }
                else {
                    return this.modelImages.desktop
                }
            },
            modelRangeOffers() {
                let filteredOffers = this.$store.state.modelRangeOffers.filter((offer) => {
                    return offer.vehicleInformation.modelRange.includes(this.modelRange);
                })
                return filteredOffers;
            },
            noFilterSelected() {
                if (this.selectedFuelTypes.length == 0 && this.selectedTransmissions.length == 0) {
                    return true
                } else {
                    return false
                }
            },
            fuelTypeFilterSelected() {
                if (this.selectedFuelTypes.length > 0 && this.selectedTransmissions.length == 0) {
                    return true
                } else {
                    return false
                }
            },
            transmissionTypeFilterSelected() {
                if (this.selectedFuelTypes.length == 0 && this.selectedTransmissions.length > 0) {
                    return true
                } else {
                    return false
                }
            },
            allFiltersSelected() {
                if (this.selectedFuelTypes.length > 0 && this.selectedTransmissions.length > 0) {
                    return true
                } else {
                    return false
                }
            },
            filteredModelRangeOffers() {
                if (this.noFilterSelected) {
                    return this.modelRangeOffers
                }
                if (this.fuelTypeFilterSelected) {
                    if (this.selectedFuelTypes.includes('Plug-In-Hybrid')) {
                        let filteredOffers = this.modelRangeOffers.filter((offer) => {
                            return (this.selectedFuelTypes.includes(offer.vehicleInformation.fuel) || offer.vehicleInformation.hybridCode.includes('PHEV'))
                        })
                        return filteredOffers
                    } else {
                        let filteredOffers = this.modelRangeOffers.filter((offer) => {
                            return ((this.selectedFuelTypes.includes(offer.vehicleInformation.fuel) && offer.vehicleInformation.hybridCode.includes('NOHY')) || this.selectedFuelTypes.includes(offer.vehicleInformation.fuel) && offer.vehicleInformation.hybridCode.includes('HYBR'))
                        })
                        return filteredOffers
                    }

                }
                if (this.transmissionTypeFilterSelected) {
                    let filteredOffers = this.modelRangeOffers.filter((offer) => {
                        return this.selectedTransmissions.includes(offer.vehicleInformation.transmissionType)
                    })
                    return filteredOffers
                }
                if (this.allFiltersSelected) {
                    if (this.selectedFuelTypes.includes('Plug-In-Hybrid')) {
                        let filteredOffers = this.modelRangeOffers.filter((offer) => {
                            return ((this.selectedFuelTypes.includes(offer.vehicleInformation.fuel) && (this.selectedTransmissions.includes(offer.vehicleInformation.transmissionType))) || (offer.vehicleInformation.hybridCode.includes('HYBR') && (this.selectedTransmissions.includes(offer.vehicleInformation.transmissionType))) || (offer.vehicleInformation.hybridCode.includes('PHEV') && (this.selectedTransmissions.includes(offer.vehicleInformation.transmissionType))))
                        })
                        return filteredOffers
                    } else {
                        let filteredOffers = this.modelRangeOffers.filter((offer) => {
                            return (this.selectedFuelTypes.includes(offer.vehicleInformation.fuel) && offer.vehicleInformation.hybridCode.includes('NOHY') && this.selectedTransmissions.includes(offer.vehicleInformation.transmissionType))
                        })
                        return filteredOffers
                    }
                }
            },
            modelRangeOffersPetrol() {
                let filteredOffers = this.filteredModelRangeOffers.filter((offer) => {
                    return (offer.vehicleInformation.fuel.includes('Benzin') && offer.vehicleInformation.hybridCode.includes('NOHY') || offer.vehicleInformation.fuel.includes('Benzin') && offer.vehicleInformation.hybridCode.includes('HYBR'));
                })
                return filteredOffers;
            },
            modelRangeVehiclesPetrol() {
                const modelRangeVehicles = [];
                const vehicles = new Map();
                for (const offer of this.modelRangeOffersPetrol) {
                    if (!vehicles.has(offer.vehicleInformation.vehicleId)) {
                        vehicles.set(offer.vehicleInformation.vehicleId, true);
                        modelRangeVehicles.push({
                            id: offer.vehicleInformation.vehicleId,
                            data: offer
                        });
                    }
                }
                return modelRangeVehicles;
            },
            modelRangeOffersDiesel() {
                let filteredOffers = this.filteredModelRangeOffers.filter((offer) => {
                    return (offer.vehicleInformation.fuel.includes('Diesel') && offer.vehicleInformation.hybridCode.includes('NOHY') || offer.vehicleInformation.fuel.includes('Diesel') && offer.vehicleInformation.hybridCode.includes('HYBR'));
                })
                return filteredOffers;
            },
            modelRangeVehiclesDiesel() {
                const modelRangeVehicles = [];
                const vehicles = new Map();
                for (const offer of this.modelRangeOffersDiesel) {
                    if (!vehicles.has(offer.vehicleInformation.vehicleId)) {
                        vehicles.set(offer.vehicleInformation.vehicleId, true);
                        modelRangeVehicles.push({
                            id: offer.vehicleInformation.vehicleId,
                            data: offer
                        });
                    }
                }
                return modelRangeVehicles;
            },
            modelRangeOffersHybrid() {
                let filteredOffers = this.filteredModelRangeOffers.filter((offer) => {
                    return offer.vehicleInformation.hybridCode.includes('PHEV');
                })
                return filteredOffers;
            },
            modelRangeVehiclesHybrid() {
                const modelRangeVehicles = [];
                const vehicles = new Map();
                for (const offer of this.modelRangeOffersHybrid) {
                    if (!vehicles.has(offer.vehicleInformation.vehicleId)) {
                        vehicles.set(offer.vehicleInformation.vehicleId, true);
                        modelRangeVehicles.push({
                            id: offer.vehicleInformation.vehicleId,
                            data: offer
                        });
                    }
                }
                return modelRangeVehicles;
            },
            enginesFound() {
                return this.modelRangeVehiclesPetrol.length + this.modelRangeVehiclesDiesel.length + this.modelRangeVehiclesHybrid.length
            },

        },
        mounted() {

        },
        methods: {
            infoIconBtnClick() {
                console.log('Icon clicked: Info Icon')
            },
            closeDialog() {
                this.dialog = false
            },
            getModelRangeOffers() {
                let baseUrl = this.$store.getters.parameters.baseUrl
                let market = this.$store.getters.parameters.market
                let region = this.$store.getters.parameters.region
                let brand = this.$store.getters.parameters.brand
                let channel = this.$store.getters.parameters.channel
                let language = this.$store.getters.parameters.language
                let modelRange = this.modelRange
                let modelSeries = this.modelSeries
                let baseProduct = this.$store.getters.filterSettings.baseProduct
                let selectedProduct = this.$store.getters.filterSettings.selectedProduct
                this.$store.commit('setSelectedProduct', selectedProduct)
                let trm = this.$store.getters.filterSettings.trm
                let dep = this.$store.getters.filterSettings.dep
                let mil = this.$store.getters.filterSettings.mil
                let min = this.$store.getters.filterSettings.min
                let max = this.$store.getters.filterSettings.max
                let modelFilterUrl = ''
                if (region != 0) {
                    modelFilterUrl = baseUrl + '/filter/dc/market/' + market.toLowerCase() + ':' + region   + '/brand/' + brand + '/channel/' + channel + '/language/' + language + '/trm/' + trm + '/mil/' + mil + '/dep/' + dep + '/min/' + min + '/max/' + max + '?baseproduct=' + baseProduct + '&modelseries=' + modelSeries + '&modelrange=' + modelRange + '&productid=' + selectedProduct
                }
                else if (market == 'de') {
                    modelFilterUrl = baseUrl + '/filter/dc/market/' + market.toLowerCase() + '/brand/' + brand + '/channel/' + channel + '/language/' + language + '/trm/' + trm + '/mil/' + mil + '/dep/' + dep + '/min/' + min + '/max/' + max + '?baseproduct=' + baseProduct + '&modelseries=' + modelSeries + '&modelrange=' + modelRange
                }
                else {
                    modelFilterUrl = baseUrl + '/filter/dc/market/' + market.toLowerCase() + '/brand/' + brand + '/channel/' + channel + '/language/' + language + '/trm/' + trm + '/mil/' + mil + '/dep/' + dep + '/min/' + min + '/max/' + max + '?modelseries=' + modelSeries + '&modelrange=' + modelRange
                }
                this.$store.dispatch('fetchModelRangeOffers', modelFilterUrl)
            }
        },
        watch: {
            dialog: function () {
                if (this.dialog == true) {
                    document.getElementById("mainHTML").style.overflowY = "hidden"
                    this.getModelRangeOffers()
                }
                if (this.dialog == false) {
                    this.selectedFuelTypes = []
                    this.selectedTransmissions = []
                    document.getElementById("engineSelection").scrollTop = 0
                    document.getElementById("mainHTML").style.overflowY = ""
                }
            },
        }
    }
</script>

<style scoped>
    .v-card {
        position: absolute;
        width: 768px;
        height: 100%;
        overflow: scroll;
        top: 0;
        right: 0;
        /*right: 13%;*/
    }

    #engineSelection {
        background-color: #F9F9F9;
    }

    #dialogContent {
        width: 97%;
        margin-left: 1.5%;
    }

    #closeDefault {
        position: fixed;
        min-width: 0px;
        height: 45px;
        width: 45px;
        margin: 0;
        padding: 0;
        right: 45px;
        top: 12px;
        box-shadow: 0px 0px 5px lightgrey;
        border-radius: 1px;
        font-size: 18px;
        color: grey;
        background-color: white;
        z-index: 100;
    }

    #closeMobile {
        position: fixed;
        min-width: 0px;
        height: 40px;
        width: 40px;
        margin: 0;
        padding: 0;
        right: 20px;
        top: 20px;
        box-shadow: 0px 0px 5px lightgrey;
        border-radius: 1px;
        font-size: 16px;
        color: grey;
        background-color: white;
        z-index: 100;
    }

    #mobile .v-card {
        width: 100%;
    }

    img {
        height: 160px;
    }

    h2, h3, span, v-label {
        color: #262626;
    }

    h3 {
        white-space: normal;
        margin-bottom: 40px;
        margin-top: 10px;
    }

    .offerSection {
        margin-bottom: 15px;
        font-weight: bold;
    }

    p, span {
        font-size: 12px;
        letter-spacing: 0.16px;
        margin-bottom: 3px;
    }

    button {
        font-size: 14px;
        letter-spacing: 0.20px;
        margin-bottom: 3px;
    }

    #default button {
        margin-bottom: 10px;
        margin-top: 10px;
    }

    .v-card__actions {
        height: 50px;
    }

    .v-card__actions button {
        margin-bottom: 0px;
    }

    .modelImage {
        width: 100%;
        height: auto;
    }

    .selectionBox {
        margin-right: 15px;
        word-wrap: break-spaces;
        width: 190px;
    }

    .selectionRow {
        margin-left: 0px;
        margin-top: -10px;
    }

    .headline {
        font-weight: bold;
        color: #8E8E8E;
        margin-top: 0px;
        margin-bottom: -5px;
    }

    .transmissions {
        margin-bottom: 20px;
    }

    .transmissions span {
        font-size: 15px;
        letter-spacing: 0.22px;
    }

    #filterSection {
        background-color: white;
        box-shadow: 0 0 5px lightgrey;
        margin-bottom: 25px;
        padding: 20px 20px;
    }

    .rangeDescription {
        white-space: normal;
        word-break: break-word;
        margin-top: 3%;
    }

</style>
