<template>
    <div data-app="true" v-if="labels.engineSelectionPage">
        <div id="engineSelectionPageCost">
            <div id="navBack">
                <router-link tag="button"
                             :to="{ name: 'MainPage', params: {provSelection: this.parameters.region, rateMin: this.filterSettings.min, rateMax: this.filterSettings.max, initialTab: this.filterSettings.selectedTab, trm1001: this.filterSettings.trm1001, dep1001: this.filterSettings.dep1001, mil1001: this.filterSettings.mil1001, trm1002: this.filterSettings.trm1002, dep1002: this.filterSettings.dep1002, trm1004: this.filterSettings.trm1004, dep1004: this.filterSettings.dep1004} }"
                             @click.native="clearSessionStorage">
                    <v-icon>keyboard_arrow_left</v-icon>
                    {{ changeSelection }}
                </router-link>
            </div>
            <div id="engineSelection">
                <div id="dialogContent">
                    <div id="vehicleMain">
                        <div class="vehicleData">
                            <p v-if="storedRangeDescription" class="rangeDescription">{{
                                storedRangeDescription.toUpperCase()
                                }}.</p>
                        </div>
                        <div class="vehicleImage">
                            <img v-bind:src="modelImage" class="modelImage" alt="model image"
                                 @error="imageUrl='./imagenotfound.jpg'"/>
                        </div>
                    </div>
                    <div>
                        <h3>{{ labels.engineSelectionPage.engineSelectionSubTitle }}</h3>
                        <div id="vehicleSection">
                            <div class="transmissions">
                                <span id="enginesFound">
                                    <h2 class="inline" v-show="loading == false">{{ enginesFound }} </h2>
                                    <v-progress-circular v-show="loading == true" class="progress"
                                                         :size="30"
                                                         :width="3"
                                                         color="#1c69d4"
                                                         indeterminate
                                    ></v-progress-circular>
                                </span>
                                <span v-show="enginesFound == 0">{{ labels.engineSelectionPage.enginesFoundPlural }}</span>
                                <span v-show="enginesFound == 1">{{ labels.engineSelectionPage.enginesFoundSingular }}</span>
                                <span v-show="enginesFound > 1">{{ labels.engineSelectionPage.enginesFoundPlural }}</span>
                            </div>
                            <div v-for="vehicle in modelRangeOffers" v-show="loading == false">
                                <!--<div class="vehicle-wrapper">
                                    <h3 v-if="vehicle.length > 0">{{ vehicle[0].vehicleInformation.modelName }}</h3>
                                <EngineSelectionItemCost :offer="vehicle[0]"></EngineSelectionItemCost>
                                </div>-->
                                <EngineSelectionItemCost :offer=offer v-for="(offer, index) in vehicle"
                                                         :key="index + '_' + offer.vehicleInformation.vehicleId"
                                                         @closeDialog="closeDialog"></EngineSelectionItemCost>
                            </div>
                        </div>
                    </div>
                </div>
                <!--<div id="navTop" v-show="loading == false">
                    <v-btn @click.native="scrollTop"
                           text>
                        <v-icon>keyboard_arrow_up</v-icon>
                        Back to top
                    </v-btn>
                </div>-->
                <!-- <v-btn id="fab"
                        v-scroll="onScroll"
                        v-show="fab"
                        fab
                        dark
                        fixed
                        bottom
                        right
                        color="primary"
                        @click="toTop"
                 >
                     <v-icon>keyboard_arrow_up</v-icon>
                 </v-btn>-->
            </div>

        </div>
    </div>

</template>

<script>
    import EngineSelectionItemCost from "./EngineSelectionItemCost";
    import TrackingHandler from "../helpers/TrackingHandler";

    export default {
        name: 'EngineSelectionPageCost',
        components: {
            EngineSelectionItemCost,
        },
        props: {
            modelSeries: '',
            modelRange: '',
            rangeDescription: '',
            enginesFound: '',
            modelImageMobile: '',
            modelImageDesktop: ''
        },
        data() {
            return {
                dialog: false,
                open: false,
                fab: false,
            }
        },
        computed: {
            changeSelection() {
                if (this.parameters.language == 'en') {
                    return 'Change selection'
                }
                if (this.parameters.language == 'fr') {
                    return 'Modifier la selection'
                }
            },
            labels() {
                if (this.$store.state.labels) {
                    return this.$store.state.labels
                } else if (self == top) {
                    return JSON.parse(sessionStorage.getItem('labels'))
                } else {
                    return null
                }
            },
            parameters() {
                return this.$store.state.parameters
            },
            filterSettings() {
                return this.$store.state.filterSettings
            },
            loading() {
                return this.$store.getters.filterSettings.loading
            },
            routeInformation() {
                return this.$store.getters.parameters.routeInformation
            },
            storedModelRange() {
                if (this.modelRange) {
                    return this.modelRange
                } else if (self == top) {
                    return sessionStorage.getItem('modelRange')
                } else {
                    return null
                }
            },
            storedRangeDescription() {
                if (this.rangeDescription) {
                    return this.rangeDescription
                } else if (self == top) {
                    return sessionStorage.getItem('rangeDescription')
                } else {
                    return null
                }
            },
            storedEnginesFound() {
                if (this.enginesfound) {
                    return this.enginesfound
                } else if (self == top) {
                    return sessionStorage.getItem('enginesfound')
                } else {
                    return null
                }
            },
            modelImage() {
                if (this.$mq == 'mobile') {
                    if (this.modelImageMobile) {
                        return this.modelImageMobile
                    } else if (self == top) {
                        return sessionStorage.getItem('modelImageMobile')
                    } else {
                        return null
                    }
                } else {
                    if (this.modelImageDesktop) {
                        return this.modelImageDesktop
                    } else if (self == top) {
                        return sessionStorage.getItem('modelImageDesktop')
                    } else {
                        return null
                    }
                }
            },
            modelRangeOffers() {
                if (this.$store.state.modelRangeOffers) {
                    return this.$store.state.modelRangeOffers
                }
            },
            storedModelRangeOffers() {
                if (this.modelRangeOffers.length > 0) {
                    return this.modelRangeOffers
                } else if (self == top) {
                    return JSON.parse(sessionStorage.getItem('modelRangeOffers'))
                } else {
                    return null
                }
            },
            bucketStats() {
                if (this.$store.state.bucketStats) {
                    return this.$store.state.bucketStats
                } else {
                    return null
                }
            }
        },
        created() {
            this.$store.commit('setLoading', true)
            this.setRouteInformation()
            this.getModelRangeOffers()
            /*window.onbeforeunload = function (e) {
                    e = e || window.event;
                    //old browsers
                    if (e) {e.returnValue = 'Changes you made may not be saved';}
                    //safari, chrome(chrome ignores text)
                    return 'Changes you made may not be saved';
            }*/

            TrackingHandler.sendSelectRangeEvent(Date.now(), this.$store,
                {
                    modelRange: this.modelRange,
                    rangeDescription: this.rangeDescription
                },
                this.bucketStats.financeProducts
            );
        },
        methods: {
            setRouteInformation() {
                if (this.$store.getters.parameters.routeInformation) {
                    // do nothing
                } else {
                    this.$store.commit('setModelRange', this.modelRange)
                    this.$store.commit('setModelSeries', this.modelSeries)
                    this.$store.commit('setRangeDescription', this.rangeDescription)
                    this.$store.commit('setModelImageMobile', this.modelImageMobile)
                    this.$store.commit('setModelImageDesktop', this.modelImageDesktop)
                }
            },
            /*isSupported() {
                try {
                    const testKey = 'storageSupported'
                    sessionStorage.setItem(testKey, testKey)
                    sessionStorage.removeItem(testKey)
                } catch (e) {
                    return false
                }
            },*/
            storeSession() {
                if (self == top) {
                    sessionStorage.setItem('sessionStorage', this.parameters.sessionStorage)
                    sessionStorage.setItem('modelRange', this.modelRange)
                    sessionStorage.setItem('modelSeries', this.modelSeries)
                    sessionStorage.setItem('rangeDescription', this.rangeDescription)
                    sessionStorage.setItem('enginesFound', this.enginesFound)
                    sessionStorage.setItem('modelImageDesktop', this.modelImageDesktop)
                    sessionStorage.setItem('modelImageMobile', this.modelImageMobile)
                    sessionStorage.setItem('labels', JSON.stringify(this.labels))
                    sessionStorage.setItem('modelRangeOffers', JSON.stringify(this.modelRangeOffers))
                    sessionStorage.setItem('selectedProduct', this.$store.getters.filterSettings.selectedProduct)
                    sessionStorage.setItem('financeProducts', JSON.stringify(this.bucketStats.financeProducts))
                } else {
                    console.log('Session storage not supported')
                }
            },
            clearSessionStorage() {
                if (self == top) {
                    sessionStorage.clear()
                } else {
                    // do nothing
                }
            },
            onScroll(e) {
                if (typeof window === 'undefined') return
                const topSelf = window.pageYOffset || e.target.scrollTop || 0
                const topParent = parent.window.pageYOffset || e.target.scrollTop || 0
                this.fab = topSelf > 20 || topParent > 20
            },
            /*toTop() {
                this.$vuetify.goTo(0)
            },
            scrollTop() {
                window.parent.scroll(0, 0)
            },*/
            infoIconBtnClick() {
                console.log('Icon clicked: Info Icon')
            },
            closeDialog() {
                this.dialog = false
            },
            async getModelRangeOffers() {
                if (this.modelRange) {
                    let baseUrl = this.$store.getters.parameters.baseUrl
                    let market = this.$store.getters.parameters.market
                    let region = this.$store.getters.parameters.region
                    let brand = this.$store.getters.parameters.brand
                    let channel = this.$store.getters.parameters.channel
                    let language = this.$store.getters.parameters.language
                    let modelRange = this.modelRange
                    let modelSeries = this.modelSeries
                    let baseProduct = this.$store.getters.filterSettings.baseProduct
                    let selectedProduct = this.$store.getters.filterSettings.selectedProduct
                    this.$store.commit('setSelectedProduct', selectedProduct)
                    let trm = ''
                    let dep = ''
                    let mil = ''
                    if (this.$store.getters.filterSettings.selectedProduct == '1001') {
                        trm = this.$store.getters.filterSettings.trm1001
                        dep = this.$store.getters.filterSettings.dep1001
                        mil = this.$store.getters.filterSettings.mil1001
                    }
                    if (this.$store.getters.filterSettings.selectedProduct == '1002') {
                        trm = this.$store.getters.filterSettings.trm1002
                        dep = this.$store.getters.filterSettings.dep1002
                        mil = 0
                    }
                    if (this.$store.getters.filterSettings.selectedProduct == '1004') {
                        trm = this.$store.getters.filterSettings.trm1004
                        dep = this.$store.getters.filterSettings.dep1004
                        mil = 0
                    }
                    let min = this.$store.getters.filterSettings.min
                    let max = this.$store.getters.filterSettings.max
                    let modelFilterUrl = ''
                    if (region != 0) {
                        modelFilterUrl = baseUrl + '/filter/dc/market/' + market.toLowerCase() + ':' + region + '/brand/' + brand + '/channel/' + channel + '/language/' + language + '/trm/' + trm + '/mil/' + mil + '/dep/' + dep + '/min/' + min + '/max/' + max + '?modelseries=' + modelSeries + '&modelrange=' + modelRange + '&productid=' + selectedProduct
                    } else if (market == 'de') {
                        modelFilterUrl = baseUrl + '/filter/dc/market/' + market.toLowerCase() + '/brand/' + brand + '/channel/' + channel + '/language/' + language + '/trm/' + trm + '/mil/' + mil + '/dep/' + dep + '/min/' + min + '/max/' + max + '?modelseries=' + modelSeries + '&modelrange=' + modelRange
                    } else {
                        modelFilterUrl = baseUrl + '/filter/dc/market/' + market.toLowerCase() + '/brand/' + brand + '/channel/' + channel + '/language/' + language + '/trm/' + trm + '/mil/' + mil + '/dep/' + dep + '/min/' + min + '/max/' + max + '?modelseries=' + modelSeries + '&modelrange=' + modelRange
                    }
                    await this.$store.dispatch('fetchModelRangeOffers', modelFilterUrl)
                    this.storeSession()
                    this.$store.commit('setLoading', false)
                } else {
                    // do nothing
                }
            }
        },
        watch: {
            dialog: function () {
                if (this.dialog == true) {
                    document.getElementById("mainHTML").style.overflowY = "hidden"
                    this.getModelRangeOffers()
                }
                if (this.dialog == false) {
                    this.selectedFuelTypes = []
                    this.selectedTransmissions = []
                    // document.getElementById("engineSelection").scrollTop = 0
                    document.getElementById("mainHTML").style.overflowY = ""
                }
                if (this.open == true) {
                    let element = document.getElementById("engineSelection");
                    // element.scrollIntoView();
                }
            },
        }
    }
</script>

<style scoped>
    .v-card {
        position: absolute;
        width: 768px;
        height: 100%;
        overflow: scroll;
        top: 0;
        right: 0;
        /*right: 13%;*/
    }

    #mobile .v-card {
        width: 100%;
        height: 100%;
        overflow: scroll;
    }

    #engineSelection {
        /*background-color: #F9F9F9;*/
    }

    #dialogContent {
        width: 97%;
        margin-left: 1.5%;
    }

    #closeDefault {
        position: fixed;
        min-width: 0px;
        height: 45px;
        width: 75px;
        margin: 0;
        padding: 0;
        right: 45px;
        top: 12px;
        box-shadow: 0px 0px 5px lightgrey;
        border-radius: 1px;
        font-size: 18px;
        color: grey;
        background-color: white;
        z-index: 100;
    }

    #closeMobile {
        position: fixed;
        min-width: 0px;
        height: 40px;
        width: 60px;
        margin: 0;
        padding: 0;
        right: 20px;
        top: 20px;
        box-shadow: 0px 0px 5px lightgrey;
        border-radius: 1px;
        font-size: 16px;
        color: grey;
        background-color: white;
        z-index: 100;
    }

    img {
        height: 160px;
    }

    h2, h3, span, v-label {
        color: #262626;
    }

    h3 {
        white-space: normal;
        margin-bottom: 40px;
        margin-top: 10px;
    }

    .offerSection {
        margin-bottom: 15px;
        font-weight: bold;
    }

    p, span {
        font-size: 12px;
        letter-spacing: 0.16px;
        margin-bottom: 3px;
    }

    button {
        font-size: 14px;
        letter-spacing: 0.20px;
        margin-bottom: 3px;
    }

    #default button {
        margin-bottom: 10px;
        margin-top: 10px;
    }

    .v-card__actions {
        height: 50px;
    }

    .v-card__actions button {
        margin-bottom: 0px;
    }

    .modelImage {
        width: 60%;
        height: auto;
        /*float: left;*/
    }

    #mobile .modelImage {
        width: 100%;
    }

    .vehicleData {
        width: 100%;
        display: block;
        /*float: right;*/
        font-size: 25px;
        /*font-weight: bold;*/
        /*word-wrap: break-spaces;*/
        margin-bottom: 15px;
    }

    .vehicleImage {
        /*background-color: #E6E6E6;*/
    }

    p {
        font-size: unset;
    }

    .selectionBox {
        margin-right: 15px;
        word-wrap: break-spaces;
        width: 190px;
    }

    .selectionRow {
        margin-left: 0px;
        margin-top: -10px;
    }

    .headline {
        font-weight: bold;
        color: #8E8E8E;
        margin-top: 0px;
        margin-bottom: -5px;
    }

    .transmissions {
        margin-bottom: 20px;
    }

    .transmissions span {
        font-size: 15px;
        letter-spacing: 0.22px;
    }

    #filterSection {
        background-color: white;
        box-shadow: 0 0 5px lightgrey;
        margin-bottom: 25px;
        padding: 20px 20px;
    }

    .rangeDescription {
        white-space: normal;
        word-break: break-word;
        margin-top: 3%;
    }

    .progress {
        margin-right: 10px;
    }

    /*#fab {
        background-color: #1C69D4;
        padding-left: 15px;
        padding-right: 15px;
        width: unset;
    }*/

    #navBack {
        text-decoration: none;
        color: #919191;
        font-size: 16px;
        border-bottom: 1px solid #E6E6E6;
        margin-top: 10px;
        margin-bottom: 25px;
    }

    #navBack button {
        padding-left: unset;
        padding-right: unset;
        padding-bottom: 10px;
        width: unset;
        height: unset;
    }

    #navTop {
        margin: auto;
        width: 50px;
    }

    #navTop button {
        color: #919191;
        font-size: 14px;
    }

    .vehicle-wrapper {
        background-color: white;
        box-shadow: 0 0 5px lightgrey;
        padding: 20px 20px;
        margin-bottom: 20px;
    }

    .vehicle-wrapper h3 {
        margin-bottom: 10px;
    }
</style>
