import { render, staticRenderFns } from "./MainPage.vue?vue&type=template&id=5916d006&scoped=true&"
import script from "./MainPage.vue?vue&type=script&lang=js&"
export * from "./MainPage.vue?vue&type=script&lang=js&"
import style0 from "./MainPage.vue?vue&type=style&index=0&id=5916d006&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5916d006",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VFlex,VLayout,VSelect,VSkeletonLoader})
