<template>
    <div class="main" data-app="true" v-if="labels.offerDetailPage">
        <div id="navBack" @click="navBack">
            <v-icon>keyboard_arrow_left</v-icon>
            {{ labels.offerDetailPage.changeSelection }}
        </div>
        <div class="header" :class="$mq">
            <v-layout row>
                <v-flex md6 sm6 xs12>
                    <img v-bind:src="modelImage" class="modelImage" alt="model image"
                         @error="imageUrl='./imagenotfound.jpg'"/>
                </v-flex>
                <v-flex md6 sm6 xs12>
                    <div class="vehicleData" :class="{'vehicleData-s': breakpointS(rangeDescription), 'vehicleData-m': breakpointM(rangeDescription), 'vehicleData-l': breakpointL(rangeDescription) }">
                        <p class="segment">{{segment}}</p>
                        <h1 v-if="rangeDescription" class="rangeDescription">{{
                            rangeDescription.toUpperCase()}}</h1>
                    </div>
                </v-flex>
            </v-layout>
        </div>
        <div class="productconfig">
            <h2>{{ labels.offerDetailPage.offerConfiguration.offerConfiguration }}</h2>
            <p class="last">{{ labels.offerDetailPage.offerConfiguration.changeOffer }}</p>
            <v-layout row class="filterparameters-row">
                <v-flex xl10 lg10 md10 sm10 xs12>
                    <v-layout row>
                        <v-flex xl4 lg4 md4 sm4 xs12 v-if="labels.mainPage.additionalFilters.term" class="filterparameters"
                                :class="$mq">
                            <v-select :items="termValues"
                                      item-text="label"
                                      item-value="value"
                                      :label="labels.mainPage.additionalFilters.term"
                                      outlined
                                      dense
                                      background-color="#ffffff"
                                      class="financeParameter"
                                      append-icon="$expand"
                                      v-model="filterParametersTemp.trm"
                            ></v-select>
                        </v-flex>
                        <v-flex xl4 lg4 md4 sm4 xs12 v-if="labels.mainPage.additionalFilters.deposit" class="filterparameters"
                                :class="$mq">
                            <v-select :items="depositValues"
                                      item-text="label"
                                      item-value="value"
                                      :label="labels.mainPage.additionalFilters.deposit"
                                      outlined
                                      dense
                                      color="#1C69D4"
                                      background-color="#ffffff"
                                      class="financeParameter"
                                      append-icon="$expand"
                                      v-model="filterParametersTemp.dep"
                            ></v-select>
                        </v-flex>
                        <v-flex xl4 lg4 md4 sm4 xs12 v-if="labels.mainPage.additionalFilters.mileage" class="filterparameters"
                                :class="$mq">
                            <v-select :items="mileageValues"
                                      item-text="label"
                                      item-value="value"
                                      :label="labels.mainPage.additionalFilters.mileage"
                                      outlined
                                      dense
                                      color="#1C69D4"
                                      background-color="#ffffff"
                                      class="financeParameter"
                                      append-icon="$expand"
                                      v-model="filterParametersTemp.mil"
                            ></v-select>
                        </v-flex>
                    </v-layout>
                </v-flex>
                <v-spacer></v-spacer>
                <v-flex xl1 lg1 md1 sm1 xs12>
                    <div class="offersfound">
                        <img v-show="loading == true" src="/spinner.svg" class="spinner" alt="spinner image"/>
                        <h6 v-show="offersFound == 0 && loading == false">{{ offersFound }} {{
                            labels.offerDetailPage.offerConfiguration.offersFoundPlural }}</h6>
                        <h6 v-show="offersFound == 1 && loading == false">{{ offersFound }} {{
                            labels.offerDetailPage.offerConfiguration.offersFoundSingular }}</h6>
                        <h6 v-show="offersFound > 1 && loading == false">{{ offersFound }} {{
                            labels.offerDetailPage.offerConfiguration.offersFoundPlural }}</h6>
                    </div>
                </v-flex>
            </v-layout>
        </div>
        <div class="offersection" v-show="loading == false">
            <v-expansion-panels accordion flat v-model="panel">
                <v-expansion-panel v-for="(offer, index) in offers" v-bind:key="offer.id">
                    <v-expansion-panel-header>
                        <span class="expansion-header">
                            <span>{{ labels.mainPage.vehiclesList.offersFoundSingular }} {{ index + 1 }}: {{ baseType(offer) }}</span></span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <MotoOfferItem :offer=offer :key="index" :capacity="capacity" :engineKWMin="engineKWMin"
                                       :item="index + 1"></MotoOfferItem>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </div>
        <v-dialog v-model="dialog" eager transition="slide-x-reverse-transition">
            <v-card tile class="filtersettings"
                    :class="$mq">
                <div class="closeButton">
                    <v-icon @click="abortUpdate">close</v-icon>
                </div>
                <v-card-title>
                    <h2>{{ labels.offerDetailPage.parameterDialog.filtersettings }}</h2>
                </v-card-title>

                <v-card-text>
                    <div class="subheadline">
                        <v-layout row class="filter-row">
                            <v-flex xl2 lg2 md3 sm3 xs12>
                                <p class="font-weight-bold">{{ labels.offerDetailPage.parameterDialog.filtersSet }}</p>
                            </v-flex>
                            <v-flex xl8 lg8 md7 sm9 xs12>
                                <v-layout row class="filter-labels">
                                    <v-flex xl3 lg3 md3 sm4 xs4>{{ labels.mainPage.additionalFilters.term }}</v-flex>
                                    <v-flex xl3 lg3 md3 sm4 xs4>{{ labels.mainPage.additionalFilters.deposit }}</v-flex>
                                    <v-flex xl3 lg3 md3 sm4 xs4>{{ labels.mainPage.additionalFilters.mileage }}</v-flex>
                                </v-layout>
                                <v-layout row v-if="filterSettings" class="filter-params">
                                    <v-flex xl3 lg3 md3 sm4 xs4>{{ filterParametersTemp.trm }} {{ labels.financeParameterFormat.term }}</v-flex>
                                    <v-flex xl3 lg3 md3 sm4 xs4>{{ filterParametersTemp.dep }} {{ labels.financeParameterFormat.currencySymbol }}</v-flex>
                                    <v-flex xl3 lg3 md3 sm4 xs4>{{ filterParametersTemp.mil }} {{ labels.financeParameterFormat.mileage }}</v-flex>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                        <p>{{ labels.offerDetailPage.parameterDialog.useFilterSettings }}</p>
                    </div>
                    <div class="params-selection">
                        <v-layout row>
                            <v-flex xl6 lg6 md6 sm6 xs12>
                                <v-btn tile depressed class="button-light" @click="setFilters">
                                    {{ labels.offerDetailPage.parameterDialog.useFiltersYes }}
                                </v-btn>
                            </v-flex>
                            <v-flex xl6 lg6 md6 sm6 xs12>
                                <v-btn tile dark depressed class="button-dark" @click="abortFilters">
                                    {{ labels.offerDetailPage.parameterDialog.useFiltersNo }}
                                </v-btn>
                            </v-flex>
                        </v-layout>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import MotoOfferItem from "./MotoOfferItem";
    import TrackingHandler from "../helpers/TrackingHandler";

    export default {
        name: 'MotoOfferDetails',
        components: {
            MotoOfferItem,
        },
        props: {
            series: '',
            vehicleId: '',
            modelRange: '',
            rangeDescription: '',
            modelImageMobile: '',
            modelImageDesktop: '',
            trm: '',
            dep: '',
            mil: '',
            motoRateMin: '',
            motoRateMax: '',
            capacity: '',
            engineKWMin: '',
        },
        data() {
            return {
                dialog: false,
                pageReloaded: false,
                panel: 0,
                initialScroll: true,
                filterParameters: {
                    'trm': this.trm,
                    'dep': this.dep,
                    'mil': this.mil
                },
                filterParametersTemp: {
                    'trm': this.trm,
                    'dep': this.dep,
                    'mil': this.mil
                },
            }
        },
        computed: {
            segment() {
                switch (this.series) {
                    case 'S':
                        return this.labels.mainPage.segmentDescription.SP.toUpperCase()
                    case 'L':
                        return this.labels.mainPage.segmentDescription.LT.toUpperCase()
                    case 'N':
                        return this.labels.mainPage.segmentDescription.NA.toUpperCase()
                    case 'H':
                        return this.labels.mainPage.segmentDescription.HE.toUpperCase()
                    case 'A':
                        return this.labels.mainPage.segmentDescription.AD.toUpperCase()
                    case 'M':
                        return this.labels.mainPage.segmentDescription.MS.toUpperCase()
                    default:
                        return ''
                }
            },
            offersFound() {
                if (this.offers) {
                    return this.offers.length
                } else {
                    return 0
                }
            },
            labels() {
                return this.$store.state.labels
            },
            parameters() {
                return this.$store.state.parameters
            },
            filterSettings() {
                return this.$store.state.filterSettings
            },
            loading() {
                return this.$store.getters.filterSettings.loading
            },
            modelImage() {
                return this.modelImageDesktop
            },
            termValuesPlain() {
                if (this.$store.state.settings.filter) {
                    return this.$store.state.settings.filter.term.values
                }
            },
            depositValuesPlain() {
                if (this.$store.state.settings.filter) {
                    return this.$store.state.settings.filter.deposit.values
                }
            },
            mileageValuesPlain() {
                if (this.$store.state.settings.filter) {
                    return this.$store.state.settings.filter.mileage.values
                }
            },
            termValues() {
                if (this.termValuesPlain) {
                    let termValues = []
                    let terms = this.termValuesPlain
                    for (const term of terms
                        ) {
                        termValues.push({
                            'label': this.formatValue(term, 'term', this.parameters.language, this.parameters.market),
                            'value': term
                        })
                    }
                    return termValues
                }
            },
            depositValues() {
                if (this.depositValuesPlain) {
                    let depositValues = []
                    let deposits = this.depositValuesPlain
                    for (const deposit of deposits
                        ) {
                        if (deposit == 0) {
                            let deposit10 = this.formatValue(10, 'currencyInteger', this.parameters.language, this.parameters.market)
                            let depositStripped = deposit10.replace('10', '0')
                            depositValues.push({
                                'label': depositStripped,
                                'value': deposit
                            })
                        } else {
                            depositValues.push({
                                'label': this.formatValue(deposit, 'currencyInteger', this.parameters.language, this.parameters.market),
                                'value': deposit
                            })
                        }
                    }
                    return depositValues
                }
            },
            mileageValues() {
                if (this.mileageValuesPlain) {
                    let mileageValues = []
                    let mileages = this.mileageValuesPlain
                    for (const mileage of mileages
                        ) {
                        mileageValues.push({
                            'label': this.formatValue(mileage, 'mileage', this.parameters.language, this.parameters.market),
                            'value': mileage
                        })
                    }
                    return mileageValues
                }
            },
            offers() {
                let offers = Object.values(this.$store.state.modelRangeOffers)[0]
                return offers
            },

        },
        created() {
            this.initalScroll = true
            this.scrollToTop()
            this.$store.commit('setLoading', true)
            this.getModelRangeOffers()
            this.scrollToTop()
            /*TrackingHandler.sendSelectRangeEvent(Date.now(), this.$store,
                {
                    modelRange: this.modelRange,
                    rangeDescription: this.rangeDescription
                },
                this.bucketStats.financeProducts
            );*/
        },
        mounted() {
            window.addEventListener('unload', this.loadMainPage)
        },
        methods: {
            async getModelRangeOffers() {
                if (this.vehicleId) {
                    let baseUrl = this.$store.getters.parameters.baseUrl
                    let market = this.$store.getters.parameters.market
                    let brand = this.$store.getters.parameters.brand
                    let channel = this.$store.getters.parameters.channel
                    let language = this.$store.getters.parameters.language
                    let modelRange = this.modelRange
                    let vehicleId = this.vehicleId
                    let trm = this.filterParametersTemp.trm
                    let dep = this.filterParametersTemp.dep
                    let mil = this.filterParametersTemp.mil
                    let min = this.$store.getters.filterSettings.min
                    let max = this.$store.getters.filterSettings.max
                    let modelFilterUrl = baseUrl + '/filter/dc/market/' + market.toLowerCase() + '/brand/' + brand.toLowerCase() + '/channel/' + channel + '/language/' + language + '/trm/' + trm + '/mil/' + mil + '/dep/' + dep + '/min/' + min + '/max/' + max + '?modelrange=' + modelRange + '&vehicleid=' + vehicleId
                    await this.$store.dispatch('fetchModelRangeOffers', modelFilterUrl)
                    if (this.initialScroll == true) {
                        this.scrollToTop()
                        this.initialScroll = false
                    }
                    this.$store.commit('setLoading', false)
                    this.panel = 0
                } else {
                    // do nothing
                }
            },
            baseType(offer) {
                switch (offer.financeProductInformation.productBaseType) {
                    case 'LOAN':
                        return 'Finanzierung'
                    case 'LEASE':
                        return 'Leasing'
                }
            },
            breakpointS(description) {
                if (description.length <= 10) {
                    return true
                } else return false
            },
            breakpointM(description) {
                if (description.length <= 20 && description.length > 10) {
                    return true
                } else return false
            },
            breakpointL(description) {
                if (description.length > 20) {
                    return true
                } else return false
            },
            abortUpdate() {
                this.filterParametersTemp = this.filterParameters
                this.dialog = false
            },
            navBack() {
                if (this.filterParametersTemp.trm == this.filterParameters.trm && this.filterParametersTemp.dep == this.filterParameters.dep && this.filterParametersTemp.mil == this.filterParameters.mil) {
                    this.$router.push({
                        name: 'MotoMainPage',
                        params: {
                            motoRateMin: this.filterSettings.initialRange[0],
                            motoRateMax: this.filterSettings.initialRange[1],
                            motoRateRange: [this.filterSettings.min, this.filterSettings.max],
                            segments: this.filterSettings.segments,
                            trm: this.filterParameters.trm,
                            dep: this.filterParameters.dep,
                            mil: this.filterParameters.mil
                        }
                    })
                } else {
                    this.dialog = true
                }
            },
            setFilters() {
                this.filterParameters.trm = this.filterParametersTemp.trm
                this.$store.commit('setTerm', this.filterParametersTemp.trm)
                this.filterParameters.dep = this.filterParametersTemp.dep
                this.$store.commit('setDeposit', this.filterParametersTemp.dep)
                this.filterParameters.mil = this.filterParametersTemp.mil
                this.$store.commit('setMileage', this.filterParametersTemp.mil)
                this.$router.push({
                    name: 'MotoMainPage',
                    params: {
                        motoRateMin: this.filterSettings.initialRange[0],
                        motoRateMax: this.filterSettings.initialRange[1],
                        motoRateRange: [this.filterSettings.min, this.filterSettings.max],
                        segments: this.filterSettings.segments,
                        trm: this.filterParametersTemp.trm,
                        dep: this.filterParametersTemp.dep,
                        mil: this.filterParametersTemp.mil,
                        isReturn: true
                    }
                })
            },
            abortFilters() {
                this.filterParametersTemp.trm = this.$store.getters.filterSettings.trm
                this.filterParametersTemp.dep = this.$store.getters.filterSettings.dep
                this.filterParametersTemp.mil = this.$store.getters.filterSettings.mil
                this.$router.push({
                    name: 'MotoMainPage',
                    params: {
                        motoRateMin: this.filterSettings.initialRange[0],
                        motoRateMax: this.filterSettings.initialRange[1],
                        motoRateRange: [this.filterSettings.min, this.filterSettings.max],
                        segments: this.filterSettings.segments,
                        trm: this.filterParameters.trm,
                        dep: this.filterParameters.dep,
                        mil: this.filterParameters.mil
                    }
                })
            },
            scrollToTop() {
                if ('parentIFrame' in window) {
                    parentIFrame.scrollTo(0,0);
                }
            },
            formatValue: function (value, type, language, market) {
                if (!value) {
                    return ''
                }
                let locale = language + '-' + market.toUpperCase()
                let currency = (function () {
                    switch (locale) {
                        case 'de-DE':
                            return 'EUR'
                        case 'en-CA':
                            return 'CAD'
                        case 'fr-CA':
                            return 'CAD'
                    }
                })('EUR')
                if (type == 'currency') {
                    return new Intl.NumberFormat(locale, {style: 'currency', currency: currency}).format(value)
                }
                if (type == 'currencyInteger') {
                    return new Intl.NumberFormat(locale, {
                        style: 'currency',
                        currency: currency,
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                    }).format(value)
                }
                if (type == 'mileage') {
                    try {
                        return new Intl.NumberFormat(locale, {
                            style: 'unit',
                            unit: 'kilometer',
                        }).format(value)
                    } catch (e) {
                        console.log('Error: ' + e)
                        if (market == 'de' && language == 'de') {
                            return value + ' ' + this.labels.financeParameterFormat.mileage
                        } else {
                            return value
                        }
                    }

                }
                if (type == 'term') {
                    try {
                        return new Intl.NumberFormat(locale, {
                            style: 'unit',
                            unit: 'month',
                        }).format(value)
                    } catch (e) {
                        console.log('Error: ' + e)
                        if (market == 'de' && language == 'de') {
                            return value + ' ' + this.labels.financeParameterFormat.term
                        } else {
                            return value
                        }
                    }
                }
            },
        },
        watch: {
            filterParametersTemp: {
                deep: true,
                handler() {
                    this.$store.commit('setLoading', true)
                    this.getModelRangeOffers()
                }
            },
        }
    }
</script>

<style scoped>
    .main {
        margin-top: 20px;
        max-width: 1600px;
        margin: auto;
        font-family: BMWMotorrad;
    }

    #app #mobile {

    }

    #navBack {
        text-decoration: none;
        color: #919191;
        font-size: 16px;
        border-bottom: 1px solid #E6E6E6;
        margin-top: 55px;
        margin-bottom: 60px;
        margin-left: 20px;
        padding-bottom: 40px;
        cursor: pointer;
    }

    #navBack:hover i {
        color: black;
    }

    #navBack:hover {
        color: black;
    }

    #navBack i {
        margin-top: -5px;
    }

    a {
        text-decoration: none;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #919191;
    }

    .modelImage {
        width: 100%;
        height: auto;
    }

    .segment {
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.17;
        letter-spacing: normal;
        color: #000000;
        margin-bottom: 10px;
    }

    .header {
        max-width: 1150px;
        margin: auto;
    }

    .header.md .segment, .header.lg .segment {
        margin-bottom: 5px;
    }

    .header.sm .segment {
        margin-bottom: 0px;
    }

    .header.xs .segment {
        font-size: 16px;
        margin-bottom: 5px;
    }

    h1 {
        font-size: 80px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        color: #000000;
    }

    .header.xs h1 {
        font-size: 28px;
    }

    .header.sm h1 {
        font-size: 40px;
    }

    .header.md h1 {
        font-size: 60px;
    }

    .productconfig {
        max-width: 1165px;
        margin: 20px auto 0px;
        padding: 0px 20px;
    }

    #mobile .productconfig {
        margin-bottom: 50px;
    }

    h2 {
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.17;
        letter-spacing: normal;
        color: #16171a;
    }

    p {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #16171a;
    }

    .last {
        margin-bottom: 20px;
    }

    .filterparameters {
        margin: 0 auto;
        max-width: 30%;
    }

    .filterparameters:first-child {
        margin-left: 0px;
    }

    .filterparameters.xs {
        max-width: 100%;
        margin-bottom: 20px;
    }

    .offersfound {
        min-width: 95px;
        height: 26px;
        background-color: #16171a;
        float: right;
        padding: 3px 12px 3px 11px;
        margin-top: 10px;
        border-radius: 5px;
    }

    #mobile .offersfound {
        margin-top: 15px;
    }

    #mobile .financeParameter /deep/ .v-input__slot {
        min-height: 50px !important;
        margin-bottom: -10px;
    }

    h6 {
        font-size: 13px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
    }

    .offersection {
        max-width: 1165px;
        margin: 40px auto 40px;
    }

    .v-expansion-panels {
        margin-top: -30px;
    }

    .v-expansion-panel-header {
        font-family: BMWMotorrad;
        font-size: 16px !important;
        font-weight: normal !important;
        font-stretch: normal !important;
        font-style: normal !important;
        line-height: 1.5 !important;
        letter-spacing: normal !important;
    }

    .v-expansion-panel-header--active {
        background-color: #f2f2f2;
        width: 100% !important;
        border-bottom: solid 2px #16171a;
        font-size: 16px !important;
        font-weight: bold !important;
        font-stretch: normal !important;
        font-style: normal !important;
        line-height: 1.5 !important;
        letter-spacing: normal !important;
        color: #000000;
        padding-left: 13px !important;
        padding-right: 24px !important;
        margin-top: 27px !important;
        margin-bottom: 30px !important;
        padding-bottom: 27px !important;
    }

    .v-expansion-panel--active {
        background-color: #f2f2f2 !important;
        border-bottom-style: none;
    }

    .v-expansion-panel--active:first-child {
        margin-top: 40px !important;
    }

    #mobile .v-expansion-panel--active:first-child {
        margin-top: 0px !important;
    }

    .v-expansion-panel-header {
        width: 98% !important;
        margin-left: 11px;
        padding-left: 13px !important;
        padding-right: 24px !important;
        margin-top: 27px;
        margin-bottom: 27px;
    }

    .v-expansion-panel::before {
        box-shadow: none;
    }

    .v-expansion-panel:not(:first-child)::after {
        border: none;
    }

    .row {
        margin: 0px !important;
    }

    .productconfig /deep/ fieldset {
        border: solid 2px #16171a !important;
        border-radius: 0;
    }

    .vehicleData {
        padding: 0px 20px;
    }

    .vehicleData-s {
        margin-top: 100px;
    }

    .vehicleData-m {
        margin-top: 50px;
    }

    .vehicleData-l {
        margin-top: 10px;
    }

    .spinner {
        width: 30px;
        height: 30px;
        margin-left: -45px;
        margin-top: -5px;
    }

    .button-light {
        width: 95% !important;
        height: 40px !important;
        border: 2px solid black !important;
        background-color: white !important;
        margin-bottom: 10px;
        display: flex;
        font-size: 16px !important;
        font-weight: bold !important;
    }

    .button-light:hover {
        background-color: #0062ff !important;
        color: white;
        border: none !important;
    }

    .button-dark {
        width: 95% !important;
        height: 40px !important;
        display: flex;
        font-size: 16px !important;
        font-weight: bold !important;
    }

    .button-dark:hover {
        background-color: #0062ff !important;
    }

    .closeButton {
        width: 100%;
        margin-bottom: 50px;
        margin-top: -30px;
        margin-right: -30px;
    }

    .filtersettings {
        position: absolute;
        top: 10%;
        left: 0;
        right: 0;
        margin: 0 auto;
    }

    .filtersettings.xs {
        top: 5%;
    }

    .filtersettings.sm .closeButton {
        margin-top: -20px;
    }

    .filtersettings.xs .closeButton {
        margin-top: 0px;
        margin-right: 0px;
    }

    .filtersettings.xs .button-light {
        margin: 0px auto 10px;
    }

    .filtersettings.xs .button-dark {
        margin: 0px auto;
    }

    .closeButton .v-icon {
        float: right;
        font-weight: bold;
        color: black;
    }

    .v-card__title {
        border-bottom: 2px solid #16171a;
        padding: 0px !important;
        margin-bottom: 40px;
    }

    .v-card__text {
        padding: 0px !important;
    }

    .font-weight-bold {
        font-weight: bold;
        padding-right: 10px;
    }

    .filtersettings.xs .font-weight-bold {
        margin-bottom: 10px;
    }

    .filter-row {
        align-items: center;
        margin-bottom: 20px !important;
    }

    .filter-labels {
        font-size: 10px;
    }

    .filter-params {
        font-size: 16px;
        color: black;
    }

    .params-selection {
        margin-top: 40px;
    }

    .params-selection /deep/ a {
        font-weight: bold;
    }

    .filtersettings.xl {
        width: 1380px;
        height: 495px;
        padding: 120px;
    }

    .filtersettings.lg {
        width: 1115px;
        height: 458px;
        padding: 95px;
    }

    .filtersettings.md {
        width: 885px;
        height: 428px;
        padding: 80px;
    }

    .filtersettings.sm {
        width: 653px;
        min-height: 425px;
        padding: 60px;
    }

    .filtersettings.xs {
        max-width: 365px;
        min-width: 320px;;
        height: 470px;
        padding: 20px;
    }

    .v-select-list {
        border-radius: 0px !important;
    }

    .expansion-header:hover {
        font-weight: bold;
        color: black;
    }

    .params-selection .v-btn {
        min-width: 210px;
    }

    .financeParameter /deep/ .v-icon {
        color: black;
    }


</style>
